import {
  GETVIR_CARD_ACTIVATIONDATA,
  SEND_EMP_CARD_ACTIVE_OTP,
  VERIFY_EMP_CARD_OTP,
} from "../../../helper";
import { getAPIcall, postAPIcall } from "Services/apiController";

export const getVirCardActivationData = async (data) => {
  return await getAPIcall(GETVIR_CARD_ACTIVATIONDATA + data.toString());
};

export const sendEmpCardActivationOtp = async (value) => {
  return await postAPIcall(SEND_EMP_CARD_ACTIVE_OTP, {
    vcode: value.vcode,
    dialInCode: value.dialInCode.toString(),
    mobileNumber: value.mobileNumber.toString(),
    auth_token: value.auth_token.toString(),
    isVirtual: value.isVirtual,
  });
};

export const VerifyEmpCardActivationOtp = async (otp, values) => {
  let data = {
    requestId: values.requestId,
    vcode: values.vcode,
    mobileNumber: values.mobileNumber,
    otp: otp,
    publicToken: "", //values.publicToken,
    auth_token: values.auth_token,
    isVirtual: true,
    isMobileVerified: false,
  };
  return await postAPIcall(VERIFY_EMP_CARD_OTP, data);
};
