import classnames from "classnames";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../../assets/img/logo/logo-sidebar.png";
class SidebarHeader extends Component {
  render() {
    let {
      menuShadow,
    } = this.props;
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/walletmain" className="navbar-brand">
              <div>
                <img src={logo} alt="" />
              </div>
              {/* <h2 className="brand-text mb-0">Vuexy</h2> */}
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
