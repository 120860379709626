import {
  UltimateBenificialOwnerDetailsService,
  // DocumentUploadUBO,
} from "../../../Services/docSubmitServices";
import { UBO_API_SOF } from "../../../Services";
// const moment = require("moment");

export const add_ultimateOwnerBenificialdetails = (payload) => {
  return {
    type: "ADD_ULTIMATE_BENIFICIAL_OWNER_DETAILS",
    payload,
  };
};
export const init_UBO = () => {
  return {
    type: "INIT_UBO",
  };
};

export const setIsUBORejected = (isRejected, isnewData) => {
  let payload = {
    isRejected: isRejected,
    isnewData: isnewData,
  };
  return {
    type: "SET_UBO_REJECTED",
    payload,
  };
};

const ultimateOwnerBenificialdetails_Success = (Ownerdetails) => {
  return {
    type: "ULTIMATE_BENIFICIAL_OWNER_DETAILS_SUCCESS",
    payload: Ownerdetails,
  };
};

const ultimateOwnerBenificialdetails_Failure = (error) => {
  return {
    type: "ULTIMATE_BENIFICIAL_OWNER_DETAILS_FAILURE",
    payload: error,
  };
};

export const fetch_UBO_Doc = (payload) => {
  return {
    type: "FETCH_UBO_DOC",
    payload,
  };
};
export const UltimateBenificialOwnerDetailsAction = (data) => {
  return (dispatch) => {
    // dispatch(fetch_ShareHolderDetail_Person(data));
    return new Promise(function (resolve, reject) {
      UltimateBenificialOwnerDetailsService(data)
        .then((response) => {
          dispatch(ultimateOwnerBenificialdetails_Success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(ultimateOwnerBenificialdetails_Failure(error));
          reject(error);
        });
    });
  };
};

export const uploadUBODocument = (data) => {
  return () => {
    return new Promise(function (resolve, reject) {
      UBO_API_SOF(data)
        .then((response) => {
          if (response?.data) {
            // dispatch(documentdata_Success(response?.data));
            resolve(response?.data);
          } else {
            // dispatch(documentdata_Failure("error"));
            resolve(false);

          }
        })
        .catch((error) => {
          console.log("catch", error)
          // dispatch(documentdata_Failure(error));
          reject(error);
        });
    });
  };
};