const initialState = {
  loading: false,
  updatestate: false,
  id: "",
  isRejected: false,
  addnewData: false,
  businessInfoId: null,
  name: "",
  incorporationNumber: "",
  incorporationDate: "",
  countryId: null,
  sharePercentage: "",
  linkToStateInformationSystemOrRegister: "",
  addressTypeId: null,
  country: null,
  city: "",
  postalCode: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  isSameAsRegAddress: true,
  DocumentData: "",
  countryIdIndex: null,
  countryIndex: null,
  correspondenceCountryIndex: null,
};

const ShareHolderDetailCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHAREHOLDER_DETAIL_COMPANY_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SHAREHOLDER_COMPANY_REJECT":
      return {
        ...state,
        isRejected: action.payload.isRejected,
        addnewData: action.payload.addnewData,
      };
    case "SHAREHOLDER_DETAIL_COMPANY_SUCCESS":
      return {
        ...state,
        loading: false,
        countryId: action.payload.countryId,
        error: "",
      };
    case "SHAREHOLDER_DETAIL_COMPANY_FAILURE":
      return {
        ...state,
        loading: false,
        countryId: "",
        error: action.payload,
      };
    case "FETCH_SHAREHOLDER_DETAIL_COMPANY":
      return {
        ...state,
        id: action.payload.id,
        updatestate: action.payload.updatestate,
        businessInfoId: action.payload.businessInfoId,
        name: action.payload.name,
        incorporationNumber: action.payload.incorporationNumber,
        incorporationDate: action.payload.incorporationDate,
        countryId: action.payload.countryId,
        sharePercentage: action.payload.sharePercentage,
        linkToStateInformationSystemOrRegister:
          action.payload.linkToStateInformationSystemOrRegister,
        addressTypeId: action.payload.addressTypeId,
        country: action.payload.country,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        state: action.payload.state,
        isSameAsRegAddress: action.payload.isSameAsRegAddress,
        uploadedDocuments: action.payload.uploadedDocuments,
        documentsTobeUpload: action.payload.documentsTobeUpload,
        countryIdIndex: action.payload.countryIdIndex,
        countryIndex: action.payload.countryIndex,
        correspondenceCountryIndex: action.payload.correspondenceCountryIndex,
      };
    case "documentdata_Success":
      return {
        ...state,
        resDocumentUpload: action.payload,
      };
    case "documentdata_Failure":
      return {
        ...state,
        error: action.payload,
      };
    case "INIT_SHAREHOLDER_COMPANY":
      return initialState;
    default:
      return state;
  }
};

export default ShareHolderDetailCompanyReducer;
