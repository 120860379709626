export const statuVar = {
  REJECT_VAL: 4,
  APPROVE_VAL: 3,
  PENDING_VAL: 2,
  SAVED_VAL: 1,
  NOFILL_VAL: 0,
};

export const DocRow_INFO = {
  main_subheader:
    "The details below are required to complete your application.",
  PERSONAL_DETAILS: {
    name: "PERSONAL_DETAILS",
    heading: "Personal Details",
    Subheading: "Please enter personal details.",
    approved_text: "Approved",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
  LEGAL_ENTITY_DETAILS: {
    name: "LEGAL_ENTITY_DETAILS",
    heading: "Legal Entity Details of Business",
    Subheading: "Please enter legal entity details of business.",
    approved_text: "Approved",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
  SHO_DETAILS: {
    name: "SHO_DETAILS",
    heading: "Shareholder Details",
    Subheading: "Please enter shareholder details.",
    approved_text: "Approved",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
  UBO_DETAILS: {
    name: "UBO_DETAILS",
    heading: "Ultimate Beneficial Owner(s) Details",
    Subheading: "Please enter Ultimate Beneficial Ownership details.",
    approved_text: "Approved",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
  EXE_DETAILS: {
    name: "EXE_DETAILS",
    heading: "Executives/Directors/Representatives Details",
    Subheading: "Please enter Executives/Directors/Representatives details.",
    approved_text: "Approved",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
  BUSINESS_INFO: {
    name: "BUSINESS_INFO",
    heading: "Business Information",
    Subheading: "Please enter information about the business.",
    approved_text: "Approved",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
  MEMBERSHIP_PLAN: {
    name: "MEMBERSHIP_PLAN",
    heading: "Membership Plan",
    Subheading: "Please choose Membership Plan.",
    approved_text: "Selected",
    pending_text: "Approval Pending",
    saved_text: "Saved",
  },
};
