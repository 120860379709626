import {
  API_DOCUMENT_UPLOAD,
  MultipartPostAfterLogin,
  API_DOCUMENT_DELETE,
  DOWNLOADFILEBUSINESS,
  getlocalstorage,
} from "../../../../helper";
import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import { deleteAPIcall, getAPIcallWithOption } from "Services/apiController";

export const API_documentUpload = async (data) => {
  const form = new FormData();
  form.append("UID", data.UID);
  form.append("IsIndividual", data.IsIndividual);
  form.append("DocumentType", data.DocumentType);
  form.append("DocumentData", data.DocumentData);
  form.append("DocumentMasterId", data.DocumentMasterId);
  form.append("IsPOI", data.IsPOI);
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const API_DELETE_DOC = async (data1) => {
  return await deleteAPIcall(API_DOCUMENT_DELETE, data1);
};

export const DownloadBusinessFileService = async (name) => {
  return await getAPIcallWithOption(
    DOWNLOADFILEBUSINESS +
      getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID) +
      "&documentName=" +
      name,
      null,
    { responseType: "arraybuffer" }
  );
};
