import {
  GET_VAULT_RETAILS,
  ADD_VAULT_RETAIL,
  VB_TO_V_FUND_TRANSFER,
} from "../../../../helper";
import { getAPIcall, postAPIcall } from "Services/apiController";

export const geVaultRetails = async () => {
  return await getAPIcall(GET_VAULT_RETAILS);
};

export const VaultRetailSearch = async (searchTerm) => {
  return await getAPIcall(ADD_VAULT_RETAIL + searchTerm);
};

export const sendMoneyVBtoV = async (state, user) => {
  let data = {
    amount: state.amount,
    currency: state.currency.split("@/@")[1],
    publicToken: state.currency.split("@/@")[0],
    userID: user.userID,
    notes: state.Note,
  };
  return await postAPIcall(VB_TO_V_FUND_TRANSFER, data);
};
