import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import {
  API_GET_EXECUTIVE_BY_ID,
  API_GET_EXECUTIVE_LIST,
  API_SAVEEXECUTIVE,
  API_DELETE_EXECUTIVE_BY_ID,
  API_UPDATE_EXECUTIVE,
  API_GET_EXECUTIVE_WITH_DOC,
  getlocalstorage,
} from "../../../../helper";
import { deleteAPIcall, getAPIcall, postAPIcall } from "Services/apiController";

export const getExecutivebyId = async (id) => {
  return await getAPIcall(API_GET_EXECUTIVE_BY_ID + id);
};

export const getExecutivebyIdwithDoc = async (id) => {
  return await getAPIcall(API_GET_EXECUTIVE_WITH_DOC + id);
};

export const deleteExecutivebyId = async (id) => {
  return await deleteAPIcall(API_DELETE_EXECUTIVE_BY_ID + id);
};

export const getExecutiveList = async (
  businessInfoId = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID)
) => {
  return await postAPIcall(API_GET_EXECUTIVE_LIST + businessInfoId);
};

export const saveExecutive = async (values) => {
  let data1 = {
    id: values.id,
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    otherPositionId: values.otherPositionId,
    citizenshipCountryId: values.citizenshipCountryId,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    city: values.city,
    state: values.state,
    addressCountryId: values.countryId,
    postalCode: values.postalCode,
    isUscitizen: values.isUscitizen === "one" ? true : false,
    isPoliticallyExposed: values.isPoliticallyExposed === "one" ? true : false,
    politicallyExposedCategoryId: values.politicallyExposedCategoryId,
    PoliticallyExposedText: values.PoliticallyExposedText,
    isExecutive: true,
    createdBy: values.createdBy,
    // createdDate: values.createdDate,
  };
  return values.updatestate === false
    ? await postAPIcall(API_SAVEEXECUTIVE, data1)
    : await postAPIcall(API_UPDATE_EXECUTIVE, data1);
};

export const updateExecutive = async (values) => {
  let data1 = {
    id: values.id,
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    otherPositionId: values.otherPositionId,
    citizenshipCountryId: values.citizenshipCountryId,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    city: values.city,
    state: values.state,
    countryId: values.countryId,
    postalCode: values.postalCode,
    isUscitizen: values.isUscitizen,
    isPoliticallyExposed: values.isPoliticallyExposed,
    politicallyExposedCategoryId: values.politicallyExposedCategoryId,
    isExecutive: values.isExecutive,
    createdBy: values.createdBy,
    createdDate: new Date(),
  };

  return await postAPIcall(API_UPDATE_EXECUTIVE, data1);
};
