// import { uuidGenerator } from "../../helper";
import { KEYS_FOR_LOCALSTORAGE } from "../../configs/pageConst";
import { getlocalstorage, setlocalstorage } from "../../helper";
import { refreshTokenAPI } from "../../Services/auth_API";
export class RefreshTokenClass {
  static Tokeninfo = "";
  static LogoutInfo = '';
  static isActive = true;
  static isSetted = false;
  static sessionTime = getlocalstorage(KEYS_FOR_LOCALSTORAGE.EXPIRE_TIME);

  static stoprefreshToken = () => {
    clearInterval(this.Tokeninfo);
  };

  static stopLogoutToken = () => {
    clearInterval(this.LogoutInfo);
  }

  static getSessionTime = () => {
    return this.sessionTime
  }

  static startLogoutTokenCall = () => {
    this.LogoutInfo = setTimeout(()=>{
      this.callrefreshToken()
    },(this.sessionTime + 30) * 1000)
  }

  static setSessionTime = (val) => {
    this.sessionTime = val
    return this.sessionTime
  }
  static callrefreshToken = async () => {
    try {
      const resp = await refreshTokenAPI();    
      setlocalstorage(KEYS_FOR_LOCALSTORAGE.EXPIRE_TIME , resp.data.expires_in)
      setlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN, resp.data.access_token);
      setlocalstorage(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN, resp.data.refresh_token);
      this.sessionTime = resp.data.expires_in;
    } catch (err) {
      clearInterval(this.TokenId);
    }
  };

  static startToken = async() => {
    if(this.isSetted === false){
      this.sessionTime = await getlocalstorage(KEYS_FOR_LOCALSTORAGE.EXPIRE_TIME);
      clearInterval(this.Tokeninfo)
      this.Tokeninfo = setInterval(() => {
        if (
          getlocalstorage(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN) === undefined ||
          getlocalstorage(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN) === null ||
          getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN) === undefined ||
          getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN) === null
        ) {
          clearInterval(this.Tokeninfo);
        } else {
          if(this.isActive){
            this.callrefreshToken();
          }
        }
      },  (this.sessionTime-30) * 1000);  
      this.isSetted = true
    }
    return this.Tokeninfo;
  };
}

