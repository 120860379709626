// import { actions } from "react-table";
// import { date } from "yup";

const initialState = {
  updatestate: false,
  loading: false,
  error: "",
  statusCode: "",
  isRejected: false,
  isnewData: false,
  id: "",
  prouboid: "",
  idcopy: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  citizenshipCountryIndex: null,
  citizenshipCountryId: null,
  postalCode: "",
  city: "",
  addressLine1: "",
  addressLine2: "",
  preRegistrationId: "",
  positionId: 0,
  individualTypeId: null,
  businessInfoId: "",
  ownershipPercentage: null,
  isUscitizen: "zero",
  isPoliticallyExposed: "zero",

  politicallyExposedCategoryId: null,
  politicallyExposedCategoryIndex: null,
  isPoliticallyExposedSelected: false,
  politicallyExposedText: "",
  isOtherSelected: false,
  positionName: "",
  sourceOfWealth: "",
  isUbo: true,
  proisUbotostate: false,
  isShareHolder: false,
  isPreRegPerson: false,
  iamUBOstate: false,
  isExecutive: "zero",
  otherPositionId: null,
  otherpositionindex: null,
  isExecutiveSelected: false,
  taxViewModel: { taxCountryId: null, tin: "", taxCountryindex: null },
  addressCountryId: null,
  dialInCode: "",
  emailAddress: "",
  state: "",
  phoneNo: "",
  addressCountryIndex: null,
  dialInCodeindex: null,
  countryId: "",

  proofidentityfront: "",
  isLocalproofidentityfront: false,
  proofidentityback: "",
  isLocalproofidentityback: false,
  POA: "",
  isLocalPOA: false,
  POSFUploadedDocuments: [],
  POSFDocumentsTobeUpload: [],
  DateOfIssue: "",
  DateOfExpiry: "",
  IssuingCountryId: null,
  IssuingCountryIndex: null,
  DocumentType: null,
  typeindex: null,
  DocumentNumber: "",
  warningMsg: [],
  isUBOApiCall: true,
  isUBODocApiCall: true,
};

const UltimateBenificialOwnerDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "ULTIMATE_BENIFICIAL_OWNER_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        Firstname: action.payload.Firstname,

        error: "",
      };
    case "ULTIMATE_BENIFICIAL_OWNER_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        Firstname: "",

        error: action.payload,
      };
    case "ADD_ULTIMATE_BENIFICIAL_OWNER_DETAILS":
      return {
        ...state,
        loading: false,
        isUBOApiCall: action.payload.isUBOApiCall,
        updatestate: action.payload.updatestate,
        id: action.payload.id,
        prouboid: action.payload.prouboid,
        idcopy: action.payload.idcopy,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        citizenshipCountryIndex: action.payload.citizenshipCountryIndex,
        postalCode: action.payload.postalCode,
        city: action.payload.city,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        preRegistrationId: action.payload.preRegistrationId,
        positionId: action.payload.positionId,
        individualTypeId: action.payload.individualTypeId,
        businessInfoId: action.payload.businessInfoId,
        ownershipPercentage: action.payload.ownershipPercentage,
        isUscitizen: action.payload.isUscitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        politicallyExposedCategoryIndex:
          action.payload.politicallyExposedCategoryIndex,
        politicallyExposedText: action.payload.politicallyExposedText,
        isOtherSelected: action.payload.isOtherSelected,
        isPoliticallyExposedSelected:
          action.payload.isPoliticallyExposedSelected,
        positionName: action.payload.positionName,
        sourceOfWealth: action.payload.sourceOfWealth,
        isUbo: action.payload.isUbo,
        isShareHolder: action.payload.isShareHolder,
        isPreRegPerson: action.payload.isPreRegPerson,
        iamUBOstate: action.payload.iamUBOstate,
        isExecutive: action.payload.isExecutive,
        otherPositionId: action.payload.otherPositionId,
        otherpositionindex: action.payload.otherpositionindex,
        isExecutiveSelected: action.payload.isExecutiveSelected,
        taxViewModel: {
          taxCountryId: action.payload.taxViewModel.taxCountryId,
          tin: action.payload.taxViewModel.tin,
          taxCountryindex: action.payload.taxViewModel.taxCountryindex,
        },
        addressCountryId: action.payload.addressCountryId,
        addressCountryIndex: action.payload.addressCountryIndex,
        dialInCode: action.payload.dialInCode,
        dialInCodeindex: action.payload.dialInCodeindex,
        emailAddress: action.payload.emailAddress,
        state: action.payload.state,
        phoneNo: action.payload.phoneNo,
        proisUbotostate: action.payload.proisUbotostate,
      };
    case "SET_UBO_REJECTED":
      return {
        ...state,
        isRejected: action.payload.isRejected,
        isnewData: action.payload.isnewData,
      };
    case "FETCH_UBO_DOC":
      return {
        ...state,
        isUBODocApiCall: action.payload.isUBODocApiCall,
        proofidentityfront: action.payload.proofidentityfront,
        isLocalproofidentityfront: action.payload.isLocalproofidentityfront,
        proofidentityback: action.payload.proofidentityback,
        isLocalproofidentityback: action.payload.isLocalproofidentityback,
        POA: action.payload.POA,
        isLocalPOA: action.payload.isLocalPOA,
        POSFUploadedDocuments: action.payload.POSFUploadedDocuments,
        POSFDocumentsTobeUpload: action.payload.POSFDocumentsTobeUpload,
        IssuingCountryId: action.payload.IssuingCountryId,
        IssuingCountryIndex: action.payload.IssuingCountryIndex,
        DateOfIssue: action.payload.DateOfIssue,
        DateOfExpiry: action.payload.DateOfExpiry,
        DocumentType: action.payload.DocumentType,
        typeindex: action.payload.typeindex,
        DocumentNumber: action.payload.DocumentNumber,
        warningMsg: action.payload.warningMsg,
      };
    case "INIT_UBO":
      return initialState;
    default:
      return state;
  }
};

export default UltimateBenificialOwnerDetailsReducer;
