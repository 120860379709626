export const DocumentTypeData = {
  POA: {
    Type: "POA",
    Heading: "Proof of Address",
    IsIndividual: true,
    DocumentType: "POA_",
    DocumentMasterId: 1,
    IsPOI: false,
  },

  POIF: {
    Type: "POI",
    Heading: "Proof of Identity Front",
    IsIndividual: true,
    DocumentType: "POI-F_",
    DocumentMasterId: 2,
    IsPOI: true,
  },

  POIB: {
    Type: "POI",
    Heading: "Proof of Identity Back",
    IsIndividual: true,
    DocumentType: "POI-B_",
    DocumentMasterId: 3,
    IsPOI: true,
  },

  SELFIE: {
    DocumentMasterId: 4,
  },

  SOF: {
    Type: "SOF",
    Heading: "Proof of Source of Funds/Wealth",
    IsIndividual: true,
    DocumentType: "SOF_",
    DocumentMasterId: 5,
    IsPOI: false,
  },

  POWA: {
    Type: "POWA",
    Heading: "Power of Attorney",
    DocumentType: "POWA_",
    DocumentMasterId: 6,
  },

  COI: {
    Type: "COI",
    Heading: "Certificate of Incorporation",
    IsIndividual: false,
    DocumentType: "COI_",
    DocumentMasterId: 7,
    IsPOI: false,
  },

  AOA: {
    Type: "AOA",
    Heading: "Articles of Association",
    IsIndividual: false,
    DocumentType: "AOA_",
    DocumentMasterId: 8,
    IsPOI: false,
  },

  ROSH: {
    Type: "ROSH",
    Heading: "Registry of Shareholders",
    IsIndividual: false,
    DocumentType: "ROSH_",
    DocumentMasterId: 9,
    IsPOI: false,
  },

  OWS: {
    Type: "OWS",
    Heading: "Ownership Structure",
    IsIndividual: false,
    DocumentType: "OWS_",
    DocumentMasterId: 10,
    IsPOI: false,
  },

  FIA: {
    Type: "FIA",
    Heading: "Financial Accounts",
    IsIndividual: false,
    DocumentType: "FIA_",
    DocumentMasterId: 11,
    IsPOI: false,
  },

  BAKST: {
    Type: "BAKST",
    Heading: "Bank Statements (12 Months)",
    IsIndividual: false,
    DocumentType: "BAKST_",
    DocumentMasterId: 12,
    IsPOI: false,
  },

  BUSPL: {
    Type: "BUSPL",
    Heading: "Business Plan",
    IsIndividual: false,
    DocumentType: "BUSPL_",
    DocumentMasterId: 13,
    IsPOI: false,
  },

  BUSLI: {
    Type: "BUSLI",
    Heading: "Business License",
    IsIndividual: false,
    DocumentType: "BUSLI_",
    DocumentMasterId: 14,
    IsPOI: false,
  },

  AMLP: {
    Type: "AMLP",
    Heading: "AML Policies",
    IsIndividual: false,
    DocumentType: "AMLP_",
    DocumentMasterId: 15,
    IsPOI: false,
  },
};

