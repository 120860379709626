const initialState = {
  loading: false,
  error: "",
  isMarketingMsgsAllowed: true,
  isGroupMarketingMsgsAllowed: false,
  isGroupMarketingCallsAllowed: false,
  resGDPRDetails: "",
};

const GDPRDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GDPR_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GDPR_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        resGDPRDetails: action.payload,
        error: "",
      };
    case "GDPR_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "ADD_GDPR_DATA":
      return {
        ...state,
        loading: false,
        isMarketingMsgsAllowed: action.payload.isMarketingMsgsAllowed,
        isGroupMarketingMsgsAllowed: action.payload.isGroupMarketingMsgsAllowed,
        isGroupMarketingCallsAllowed:
          action.payload.isGroupMarketingCallsAllowed,
      };
    case "INIT_SIGNUP_GDPR":
      return initialState;
    default:
      return state;
  }
};

export default GDPRDetailsReducer;
