import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import {
  API_DELETE_UBO,
  API_DOCUMENT_UPLOAD,
  API_GETSHAREHOLDER_UBO_BYID,
  API_SHAREHOLDER_DETAILS_PERSONID,
  DocumentTypeData,
  getlocalstorage,
  GETUBOLIST,
  MultipartPostAfterLogin,
} from "../../../helper";
import { deleteAPIcall, getAPIcall } from "Services/apiController";

const moment = require("moment");
const { POA, POIB, POIF } = DocumentTypeData;
export const UBO_API_POI_FRONT_Doc = async (UID, data) => {
  const form = new FormData();
  form.append("UID", UID);
  form.append("IsIndividual", POIF.IsIndividual); ///true
  form.append("DocumentType", `${POIF.DocumentType}${moment().format("x")}`); //POI-F_
  form.append("POIRequest.DocumentType", `${data.DocumentType}`);
  form.append("POIRequest.DocumentNumber", data.DocumentNumber);
  form.append("DocumentData", data.proofidentityfront);
  form.append("DocumentMasterId", POIF.DocumentMasterId); //2
  form.append("POIRequest.IssuingCountryId", data.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", data.DateOfIssue);
  form.append("POIRequest.DateOfExpiry", data.DateOfExpiry);
  form.append("IsPOI", POIF.IsPOI); //true
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const UBO_API_POI_BACK_Doc = async (UID, data) => {
  const form = new FormData();
  form.append("UID", UID);
  form.append("IsIndividual", POIB.IsIndividual); //true
  form.append("DocumentType", `${POIB.DocumentType}${moment().format("x")}`); //POI-B_
  form.append("POIRequest.DocumentType", `${data.DocumentType}`);
  form.append("POIRequest.DocumentNumber", data.DocumentNumber);
  form.append("DocumentData", data.proofidentityback);
  form.append("DocumentMasterId", POIB.DocumentMasterId); //3
  form.append("POIRequest.IssuingCountryId", data.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", data.DateOfIssue);
  form.append("POIRequest.DateOfExpiry", data.DateOfExpiry);
  form.append("IsPOI", POIB.IsPOI); //true
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const UBO_API_POA_Doc = async (UID, data) => {
  const form = new FormData();
  form.append("UID", UID);
  form.append("IsIndividual", POA.IsIndividual); //true
  form.append("DocumentType", `${POA.DocumentType}${moment().format("x")}`); //POA_
  form.append("DocumentData", data.POA);
  form.append("DocumentMasterId", POA.DocumentMasterId); //1
  form.append("IsPOI", POA.IsPOI); //false
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const UBO_API_SOF = async (data) => {
  const form = new FormData();
  form.append("UID", data.UID);
  form.append("IsIndividual", data.IsIndividual);
  form.append("DocumentType", data.DocumentType);
  form.append("DocumentData", data.DocumentData);
  form.append("DocumentMasterId", data.DocumentMasterId);
  form.append("IsPOI", data.IsPOI);
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const deleteUBObyId = async (id) => {
  return await deleteAPIcall(API_DELETE_UBO + id);
};

export const getShareHolderUboWithDoc = async (Id = "") => {
  return await getAPIcall(API_GETSHAREHOLDER_UBO_BYID + Id);
};

export const getUBOList = async () => {
  return await getAPIcall(GETUBOLIST + getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID));
};

export const getUBObyID = async (Id = "") => {
  return await getAPIcall(API_SHAREHOLDER_DETAILS_PERSONID + Id);
};
