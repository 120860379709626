import axios from "axios";
import { KEYS_FOR_LOCALSTORAGE } from "../configs/pageConst";
import {
  API_SHAREHOLDER_DETAILS_PERSON_AND_UBO,
  // API_BUSINESSSOCIALINFO,
  API_UPDATE_SHAREHOLDER_OR_UBO_BYID,
  API_UPDATE_SHAREHOLDER_COMPANYID,
  API_LEGALBUSINESSDETAILS,
  API_STATUSAPPLICATION,
  API_UPDATETAX,
  API_SHAREHOLDER_DETAILS_COMPANY,
  API_DOCUMENT_UPLOAD,
  UpdateStatus,
  getlocalstorage,
} from "../helper";
import { getAPIcall, postAPIcall } from "./apiController";

export const UpdateUserStatus = async (data) => {
  return await getAPIcall(UpdateStatus, { businessInfoId: data });
};

export const applicationstatus = async () => {
  return await getAPIcall(API_STATUSAPPLICATION);
};

export const legalBusinessEntity = async (values) => {
  var payload = [
    {
      businessInfoId: values.businessInfoId,
      addressTypeId: 0,
      country: values.registrationCountry,
      city: values.registrationCity,
      postalCode: values.registrationPostalCode,
      addressLine1: values.registrationAddressLineOne,
      addressLine2: values.registrationAddressLineTwo,
      state: values.registrationState,
      isSameAsRegAddress: false,
    },

    {
      businessInfoId: values.businessInfoId,
      addressTypeId: 1,
      country: values.correspondenceCountry,
      city: values.correspondenceCity,
      postalCode: values.correspondencePostalCode,
      addressLine1: values.correspondenceAddressLineOne,
      addressLine2: values.correspondenceAddressLineTwo,
      state: values.correspondenceState,
      isSameAsRegAddress: values.correspondencesameasregistration,
    },

    {
      businessInfoId: values.businessInfoId,
      addressTypeId: 2,
      country: values.operatingCountry,
      city: values.operatingCity,
      postalCode: values.operatingPostalCode,
      addressLine1: values.operatingAddressLineOne,
      addressLine2: values.operatingAddressLineTwo,
      state: values.operatingState,
      isSameAsRegAddress: values.operatingsameasregistration,
    },
  ];
  let data1 = {
    businessInfoId: values.businessInfoId,
    companyName: values.companyName,
    legalEntityForm: values.legalEntityForm,
    countryId: values.countryId,
    companyIncorportionDate: values.companyIncorportionDate[0],
    companyRegNumber: values.companyRegNumber,
    businessSectorMasterId: values.businessSectorMasterId,
    linkToStateInformationSystemOrRegister:
      values.linkToStateInformationSystemOrRegister,
    dialInCode: values.dialInCode.toString(),
    emailAddress: values.emailAddress,
    mobileNumber: values.mobileNumber,
    website: values.website,
    contactPerson: values.contactPerson,
    taxes: values.taxes,
    businessAddresses: payload,
  };
  return await postAPIcall(API_LEGALBUSINESSDETAILS, data1);
};

export const taxUpdate = async (values) => {
  return await postAPIcall(API_UPDATETAX, values);
};

export const ShareHolderDetailCompanyService = async (values) => {
  let data = {
    id: values.id,
    businessInfoId: values.businessInfoId,
    name: values.CompanyName,
    incorporationNumber: values.incorporationNumber,
    incorporationDate: values.incorporationDate,
    countryId: values.countryId,
    sharePercentage: parseFloat(values.sharePercentage),
    linkToStateInformationSystemOrRegister:
      values.linkToStateInformationSystemOrRegister,
    addresses: [
      {
        businessInfoId: values.businessInfoId,
        addressTypeId: 1,
        countryId: values.country,
        city: values.city,
        postalCode: values.postalCode,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        state: values.state,
        isSameAsRegAddress: values.isSameAsRegAddress,
      },
      {
        businessInfoId: values.businessInfoId,
        addressTypeId: 2,
        countryId: values.correspondenceCountry,
        city: values.correspondenceCity,
        postalCode: values.correspondencePostalCode,
        addressLine1: values.correspondenceAddressLineOne,
        addressLine2: values.correspondenceAddressLineTwo,
        state: values.correspondenceState,
        isSameAsRegAddress: values.isSameAsRegAddress,
      },
    ],
  };

  return values.updatestate === false
    ? await postAPIcall(API_SHAREHOLDER_DETAILS_COMPANY, data) //await postshreholdercompany(data)
    : await postAPIcall(API_UPDATE_SHAREHOLDER_COMPANYID, data); //await updateShareholderCompany(data);
};

export const DocumentUpload = async (data) => {
  const form = new FormData();
  form.append("UID", data.UID);
  form.append("IsIndividual", data.IsIndividual);
  form.append("DocumentType", data.DocumentType);
  form.append("DocumentData", data.DocumentData);
  form.append("DocumentMasterId", data.DocumentMasterId);
  form.append("IsPOI", data.IsPOI);

  return await axios.post(API_DOCUMENT_UPLOAD, form, {
    headers: {
      Authorization: `Bearer ${getlocalstorage(
        KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
      )}`,
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
    },
  });
};

export const ShareHolderDetailPersonService = async (values) => {
  let urllink =
    values.updatestate === true
      ? values.proid === values.idcopy
        ? values.isPreRegPerson === true
          ? API_UPDATE_SHAREHOLDER_OR_UBO_BYID
          : API_SHAREHOLDER_DETAILS_PERSON_AND_UBO
        : API_UPDATE_SHAREHOLDER_OR_UBO_BYID
      : values.isPreRegPerson === true
      ? API_UPDATE_SHAREHOLDER_OR_UBO_BYID
      : API_SHAREHOLDER_DETAILS_PERSON_AND_UBO;

  let idtosend =
    values.updatestate === true
      ? values.isPreRegPerson === true
        ? values.idcopy
        : values.id
      : values.id;

  let data = {
    id: idtosend,
    isPreRegPerson: values.isPreRegPerson,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    citizenshipCountryId: values.citizenshipCountryId,
    postalCode: values.postalCode,
    city: values.city,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    preRegistrationId: "",
    positionId: "4",
    individualTypeId: values.otherPositionId,
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    ownershipPercentage: parseFloat(values.ownershipPercentage),
    isUscitizen: values.isUscitizen === "one" ? true : false,
    isPoliticallyExposed: values.isPoliticallyExposed === "one" ? true : false,
    politicallyExposedCategoryId: values.politicallyExposedCategoryId,

    politicallyExposedText: values.politicallyExposedText,
    sourceOfWealth: values.sourceOfWealth,
    isUbo: values.isUbo === "one" ? true : false,
    isShareHolder: true,
    isExecutive: values.isExecutive === "one" ? true : false,
    otherPositionId: values.otherPositionId,
    taxViewModel: {
      taxCountryId: values.taxViewModel.taxCountryId,
      tin: values.taxViewModel.tin,
    },
    dialInCode: values.dialInCode.toString(),
    phoneNo: values.phoneNo,
    addressCountryId: values.addressCountryId,

    emailAddress: values.emailAddress,
    state: values.state,
  };

  return await axios.post(urllink, data, {
    headers: {
      "content-type": "application/json", // override instance defaults
      Authorization: `Bearer ${getlocalstorage(
        KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
      )}`,
    },
  });
};

export const DocumentUploadPerson = async (data) => {
  if (data.isPOI === true) {
    const form = new FormData();
    form.append("UID", data.UID);
    form.append("IsIndividual", data.IsIndividual);
    form.append("DocumentType", data.DocumentType);
    form.append("POIRequest.DocumentNumber", data.DocumentNumber);
    form.append("DocumentData", data.DocumentData);
    form.append("DocumentMasterId", data.DocumentMasterId);
    form.append("POIRequest.IssuingCountryId", data.IssuingCountryId);
    form.append("POIRequest.DateOfIssue", data.DateOfIssue);
    form.append("POIRequest.DateOfExpiry", data.DateOfExpiry);
    form.append("IsPOI", data.isPOI);

    return await axios.post(API_DOCUMENT_UPLOAD, form, {
      headers: {
        Authorization: `Bearer ${getlocalstorage(
          KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
        )}`,
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });
  } else {
    const form = new FormData();
    form.append("UID", data.UID);
    form.append("IsIndividual", data.IsIndividual);
    form.append("DocumentType", data.DocumentType);
    form.append("DocumentData", data.DocumentData);
    form.append("DocumentMasterId", data.DocumentMasterId);
    form.append("IsPOI", data.isPOI);

    return await axios.post(API_DOCUMENT_UPLOAD, form, {
      headers: {
        Authorization: `Bearer ${getlocalstorage(
          KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
        )}`,
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });
  }
};

export const UltimateBenificialOwnerDetailsService = async (values) => {
  let urllink =
    values.updatestate === true
      ? values.prouboid === values.idcopy
        ? values.isPreRegPerson === true
          ? API_UPDATE_SHAREHOLDER_OR_UBO_BYID
          : API_SHAREHOLDER_DETAILS_PERSON_AND_UBO
        : API_UPDATE_SHAREHOLDER_OR_UBO_BYID
      : values.isPreRegPerson === true
      ? API_UPDATE_SHAREHOLDER_OR_UBO_BYID
      : API_SHAREHOLDER_DETAILS_PERSON_AND_UBO;

  let idtosend =
    values.updatestate === true
      ? values.isPreRegPerson === true
        ? values.idcopy
        : values.id
      : values.id;
  let data = {
    id: idtosend,
    isPreRegPerson: values.isPreRegPerson,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    citizenshipCountryId: values.citizenshipCountryId,
    postalCode: values.postalCode,
    city: values.city,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    preRegistrationId: JSON.parse(
      getlocalstorage(KEYS_FOR_LOCALSTORAGE.PROFILE)
    ).preRegistrationId,
    positionId: values.positionId,
    individualTypeId: parseInt(
      getlocalstorage(KEYS_FOR_LOCALSTORAGE.INDIVIDUALINFOID)
    ),
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    ownershipPercentage: parseFloat(values.ownershipPercentage),
    isUscitizen: values.isUscitizen === "one" ? true : false,
    isPoliticallyExposed: values.isPoliticallyExposed === "one" ? true : false,
    politicallyExposedCategoryId:
      values.politicallyExposedCategoryId == null
        ? 0
        : values.politicallyExposedCategoryId,
    politicallyExposedText: values.politicallyExposedText,
    sourceOfWealth: values.sourceOfWealth,
    isUbo: true,
    isShareHolder: values.isShareHolder === "one" ? true : false,
    isExecutive: values.isExecutive === "one" ? true : false,
    otherPositionId: values.otherPositionId,
    taxViewModel: {
      taxCountryId: values.taxViewModel.taxCountryId,
      tin: values.taxViewModel.tin,
    },
    dialInCode: values.dialInCode.toString(),
    phoneNo: values.phoneNo,
    addressCountryId: values.addressCountryId,

    emailAddress: values.emailAddress,
    state: values.state,
  };

  return await postAPIcall(urllink, data);
};

export const DocumentUploadUBO = async (data) => {
  if (data.isPOI === true) {
    const form = new FormData();
    form.append("UID", data.UID);
    form.append("IsIndividual", data.IsIndividual);
    form.append("DocumentType", data.DocumentType);
    form.append("POIRequest.DocumentNumber", data.DocumentNumber);
    form.append("DocumentData", data.DocumentData);
    form.append("DocumentMasterId", data.DocumentMasterId);
    form.append("POIRequest.IssuingCountryId", data.IssuingCountryId);
    form.append("POIRequest.DateOfIssue", data.DateOfIssue);
    form.append("POIRequest.DateOfExpiry", data.DateOfExpiry);
    form.append("IsPOI", data.isPOI);

    return await axios.post(API_DOCUMENT_UPLOAD, form, {
      headers: {
        Authorization: `Bearer ${getlocalstorage(
          KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
        )}`,
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });
  } else {
    const form = new FormData();
    form.append("UID", data.UID);
    form.append("IsIndividual", data.IsIndividual);
    form.append("DocumentType", data.DocumentType);
    form.append("DocumentData", data.DocumentData);
    form.append("DocumentMasterId", data.DocumentMasterId);
    form.append("IsPOI", data.isPOI);

    return await axios.post(API_DOCUMENT_UPLOAD, form, {
      headers: {
        Authorization: `Bearer ${getlocalstorage(
          KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
        )}`,
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      },
    });
  }
};
