// import { personalDetails } from "../../../Services/registrationServices";

export const addPersonalData = (payload) => {
  return {
    type: "ADD_PERSONAL_DATA",
    payload,
  };
};

export const initPersonalData = () => {
  return {
    type: "INIT_SIGNUP_PERSONAL_DETAILS",
  };
};

// const personalDetailseRequest = () => {
//   return {
//     type: "PERSONAL_DETAILS_REQUEST",
//   };
// };

// const personalDetailSuccess = (personalDtailStatus) => {
//   return {
//     type: "PERSONAL_DETAILS_SUCCESS",
//     payload: personalDtailStatus,
//   };
// };

// const personalDetailsFailure = (error) => {
//   return {
//     type: "PERSONAL_DETAILS_FAILURE",
//     payload: error,
//   };
// };

// export const addpersonalDetails = (userObj) => {
//   return (dispatch) => {
//     return new Promise(function (resolve, reject) {
//       dispatch(personalDetailseRequest());
//       personalDetails(userObj)
//         .then((response) => {
//           dispatch(personalDetailSuccess(response.data));
//           resolve(response);
//         })
//         .catch((error) => {
//           const errorMsg = error.Message;
//           dispatch(personalDetailsFailure(errorMsg));
//           reject(error);
//         });
//     });
//   };
// };

// export const addpersonalDetails = (userObj) => {
//   return (dispatch) => {
//     dispatch(addPersonalData(userObj));
//     dispatch(personalDetailseRequest())
//       .then((response) => {
//         dispatch(personalDetailSuccess(response.data));
//       })
//       .catch((error) => {
//         const errorMsg = error.Message;
//         dispatch(personalDetailsFailure(errorMsg));
//       });
//   };
// };
