import { getBusinessAddinfo, saveBusinessInfo } from "../../../Services";

export const fetch_Business_Info = (payload) => {
  return {
    type: "FETCH_BUSINESS_INFO",
    payload,
  };
};

export const fetch_BusinessInfoAdd = (payload) => {
  return {
    type: "BUSINESS_INFO_ADD_DATA",
    payload,
  };
};

export const init_Business = () => {
  return {
    type: "INIT_BUSINESS",
  };
};

// const get_Business_Info_Request = () => {
//   return {
//     type: "GET_BUSINESS_INFO_REQUEST",
//   };
// };

const get_Business_Info_Success = (infoData) => {
  return {
    type: "GET_BUSINESS_INFO_SUCCESS",
    payload: infoData,
  };
};
const get_Business_Info_Failure = (error) => {
  return {
    type: "GET_BUSINESS_INFO_FAILURE",
    payload: error,
  };
};

const post_Business_Info_Request = () => {
  return {
    type: "POST_BUSINESS_INFO_REQUEST",
  };
};

const post_Business_Info_Success = (infoData) => {
  return {
    type: "POST_BUSINESS_INFO_SUCCESS",
    payload: infoData,
  };
};
const post_Business_Info_Failure = (error) => {
  return {
    type: "POST_BUSINESS_INFO_FAILURE",
    payload: error,
  };
};

export const getBusinessInfo = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(fetch_BusinessInfoAdd());
      getBusinessAddinfo()
        .then((response) => {
          dispatch(get_Business_Info_Success(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(get_Business_Info_Failure(error));
          reject(error);
        });
    });
  };
};

export const postBusinessInfo = (data) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(post_Business_Info_Request());
      saveBusinessInfo(data)
        .then((response) => {
          dispatch(post_Business_Info_Success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(post_Business_Info_Failure(error));
          reject(error);
        });
    });
  };
};
