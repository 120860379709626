import axios from "axios";
import { KEYS_FOR_LOCALSTORAGE } from "../configs/pageConst";
import { getlocalstorage } from '../helper'

// export const bearerToken_headerConfig = {
//   headers: {
//     Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
//   },
// };

// export const multipart_headerConfig = {
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// };
/**
 *
 * @param {*} API
 * @param {*} form
 * @returns
 */
export const MultipartPostBeforeLogin = async (API, form) => {
  return await axios.post(API, form, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
    },
  });
};
/**
 *
 * @param {*} API
 * @param {*} form
 * @returns
 */
export const MultipartPostAfterLogin = async (API, form) => {
  return await axios.post(API, form, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
      Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
    },
  });
};
