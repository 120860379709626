export const FILE = {
  sizeinMB: 10,
  limit: 10,
  // accept: 'image/jpeg, image/png,application/pdf',
  accept: {
    'image/jpeg': [],
    'image/png': [],
    'application/pdf': [],
  },
  msg: 'JPG, PNG, PDF',
};

export const BULK_UPLOAD_FILE = {
  sizeinMB: 5,
  limit: 1,
  // accept: 'application/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  accept: {
    'application/csv': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  },
  msg: 'Excel',
};

export const APPLICATION_STATUS = {
  NOT_SUBMITTED: 0, // User will redirect to "/complete-your-application" route
  SAVED: 1, // User will redirect to "/complete-your-application" route
  PENDING: 2, // User will redirect to "/complete-your-application" route
  APPROVED: 3, // User will redirect to "/walletmain" route
  REJECTED: 4, // User will redirect to "/complete-your-application" route
  SUSPENDED_MANUAL: 11, // User will redirect to "/walletmain" route
  ACTIVE: 12, // User will redirect to "/walletmain" route
  SUSPENDED_AUTO: 13, // User will redirect to "/walletmain" route
  USER_REJECTED: 14, // User will not able to login
  CLOSED: 15, // User will not able to login
  SUSPENDED_SUBSC_EXP: 16, // User will redirect to "/walletmain" route
};

export const KEYS_FOR_SESSIONSTORAGE = {
  CURRENCY_LIST: 'currencyListApi',
  OTHER_POSITION_LIST: 'otherPositionList',
  POLITICAL_EXPO_MERIT_LITST: 'politicallyExposedMasterList',
  LIMITGPLIST:'LIMITGPLIST',
  MCGLIST:'MCGLIST',
  LEGAL_ENT_MASTER_LIST:'LEGAL-ENT-MASTER-LIST'

};

export const KEYS_FOR_LOCALSTORAGE = {
  INDIVIDUALINFOID: 'individualInfoId',
  REFRESH_TOKEN: 'refresh_token',
  ACCESS_TOKEN: 'access_token',
  PROFILE: 'profile',
  BUSINESSINFOID: 'businessInfoId',
  BALANCECURRENCY: 'balancecurrency',
  BUSINESSTYPEJSON: 'businessTypeJson',
  REDIRECTURI: 'redirectUri',
  COUNTRIES: 'countries',
  TOKEN: 'token',
  COUNTRYOPTION: 'countryoption',
  DIALCODECOUNTRYOPTION: 'dialcodecountryoption',
  CURRENCY: 'currency',
  MVC: 'mvc',
  RESUID: 'resUID',
  EXPIRE_TIME: 'expire_time',
  // INDIVIDUALINFOID:'_A',
  // REFRESH_TOKEN:'_B',
  // ACCESS_TOKEN:'_C',
  // PROFILE:'_D',
  // BUSINESSINFOID:'_E',
  // BALANCECURRENCY:'_F',
  // BUSINESSTYPEJSON:'_G',
  // REDIRECTURI:'_H',
  // COUNTRIES:'_I',
  // TOKEN:'_J',
  // COUNTRYOPTION:'_K',
  // DIALCODECOUNTRYOPTION:'_L',
  // CURRENCY:'_M',
  // MVC:'_N',
  // RESUID:'_O'
};
