import {
  API_DELETE_SHAREHOLDER_BY_ID,
  API_DOCUMENT_UPLOAD,
  API_SHAREHOLDER_DETAILS_PERSONID,
  DocumentTypeData,
  MultipartPostAfterLogin,
} from "../../../../helper";
import { deleteAPIcall, getAPIcall } from "Services/apiController";

const moment = require("moment");

const { SOF, POA, POIB, POIF } = DocumentTypeData;

export const ShareHolder_API_POI_FRONT = async (UID, data) => {
  const form = new FormData();

  form.append("UID", UID);
  form.append("IsIndividual", POIF.IsIndividual);
  form.append("DocumentType", `${POIF.DocumentType}${moment().format("x")}`); //POI-F_
  form.append("POIRequest.DocumentType", `${data.DocumentType}`);
  form.append("POIRequest.DocumentNumber", data.DocumentNumber);
  form.append("DocumentData", data.proofidentityfront);
  form.append("DocumentMasterId", POIF.DocumentMasterId); //2
  form.append("POIRequest.IssuingCountryId", data.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", data.DateOfIssue);
  form.append("POIRequest.DateOfExpiry", data.DateOfExpiry);
  form.append("IsPOI", POIF.IsPOI);
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const ShareHolder_API_POI_BACK = async (UID, data) => {
  const form = new FormData();

  form.append("UID", UID);
  form.append("IsIndividual", POIB.IsIndividual); // true;
  form.append("DocumentType", `${POIB.DocumentType}${moment().format("x")}`); //POI-B_
  form.append("POIRequest.DocumentType", `${data.DocumentType}`);
  form.append("POIRequest.DocumentNumber", data.DocumentNumber);
  form.append("DocumentData", data.proofidentityback);
  form.append("DocumentMasterId", POIB.DocumentMasterId);
  form.append("POIRequest.IssuingCountryId", data.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", data.DateOfIssue);
  form.append("POIRequest.DateOfExpiry", data.DateOfExpiry);
  form.append("IsPOI", POIB.IsPOI);
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const ShareHolder_API_POA = async (UID, data) => {
  const form = new FormData();
  form.append("UID", UID);
  form.append("IsIndividual", POA.IsIndividual); //true
  form.append("DocumentType", `${POA.DocumentType}${moment().format("x")}`); //POA_
  form.append("DocumentData", data.POA);
  form.append("DocumentMasterId", POA.DocumentMasterId);
  form.append("IsPOI", POA.IsPOI);
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const ShareHolder_API_SOF = async (UID, data) => {
  const form = new FormData();
  form.append("UID", UID);
  form.append("IsIndividual", SOF.IsIndividual);
  form.append("DocumentType", `${SOF.DocumentType}${moment().format("x")}`);
  form.append("DocumentData", data.POSF);
  form.append("DocumentMasterId", SOF.DocumentMasterId);
  form.append("IsPOI", SOF.IsPOI);
  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const deleteShareholderId = async (id, isCompany) => {
  return await deleteAPIcall(
    API_DELETE_SHAREHOLDER_BY_ID + id + "&isCompany=" + isCompany
  );
};

export const getShareholderByID = async (id = "") => {
  return await getAPIcall(API_SHAREHOLDER_DETAILS_PERSONID + id);
};
