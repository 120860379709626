import { getCurrencyElement } from "../../../helper";

const initialState = {
    publicToken: "",
    beneficiaryCount: 0,
    beneficiaryList: [],
    loading: false,
    beneficiaryEmail: "",
    beneficiaryName: "",
    userID: null,
    currency: "",
    amount: "",
    selectedCurrency: "",
    startDate: new Date(),
    endDate: "",
    frequencyType: "",
    note: "",
    processingDay: 0,
    updateautopayment: false,
    transactionBeneficiaryName: "",
    transactionBeneficiaryEmail: "",
    transactions: [],
};

export const AutopaymentVtoRReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BENIFICIARY_LIST_REQ":
            return {
                ...state,
                loading: true,
            };

        case "GET_BENIFICIARY_LIST_SUCCESS":
            return {
                ...state,
                loading: false,
                beneficiaryCount: action.payload.beneficiaryCount,
                beneficiaryList: action.payload.beneficiaryList,
            };

        case "GET_BENIFICIARY_LIST_FAIL":
            return {
                ...state,
                loading: false,
                beneficiaryCount: 0,
                beneficiaryList: [],
            };

        case "ADD_SEARCHED_BENFICARYS_INVRETAIL":
            return {
                ...state,
                beneficiaryName: action.payload.beneficiaryName,
                beneficiaryEmail: action.payload.email,
                userID: action.payload.userID,
            }

        case "EDIT_VAULT_RETAIL_INSTRUCTION":
            return {
                ...state,
                updateautopayment: true,
                beneficiaryName: action.payload.beneficiaryName,
                beneficiaryEmail: action.payload.beneficiaryEmail,
                currency: action.payload.curCode,
                amount: action.payload.amount,
                startDate: new Date(action.payload.nextDueDate),
                endDate: action.payload.endDate === null ? "" : new Date(action.payload.endDate),
                selectedCurrency: getCurrencyElement(action.payload.curCode),
                frequencyType: action.payload.frequencyType.toString(),
                note: action.payload.notes ?? '',
                processingDay: parseInt(action.payload.processingDay),
                userID: action.payload.vB2VRBeneficiaryId,
                autoPaymentVB2VRInstructionsId: action.payload.id,
                publicToken: action.payload.payerPublicToken
            }

        case "ADD_VR_BENIFICIARY_FORM_TABLE":
            return {
                ...state,
                updateautopayment: false,
                userID: action.payload?.beneficiaryId ?? null,
                beneficiaryName: action.payload.beneficiaryName,
                beneficiaryEmail: action.payload.beneficiaryEmail,
            }

        case "VR_ADD_AUTOPAYMENT_TRANSACTION_LIST":
            return {
                ...state,
                transactions: action.payload?.data ?? [],
                transactionBeneficiaryName: action.payload?.name,
                transactionBeneficiaryEmail: action.payload?.email,
            }

        case "INIT_VRETAILAUTOPAYMENT":
            return initialState;

        default:
            return state;
    }
}
export default AutopaymentVtoRReducer;