import { postAPIcall } from "Services/apiController";
import {
  API_GET_RATES,
  API_CREATECONVERSION,
} from "../../../helper";

export const getRate_api = async (value) => {
  let data = {
    buyCurrency: value.isBank
      ? value.buyCurrency
      : value.buyCurrency.split("@/@")[1],
    sellCurrency: value.isBank
      ? value.sellCurrency
      : value.sellCurrency.split("@/@")[1],
    amount: parseFloat(value.amount),
  };
  return await postAPIcall(API_GET_RATES, data);
};

export const getRateAPI = async (value) => {
  return await postAPIcall(API_GET_RATES, value);
};
export const createConversion_api = async (value) => {
  let data = {
    buyCurrency: value.buyCurrency.split("@/@")[1],
    sellCurrency: value.sellCurrency.split("@/@")[1],
    BuyCurrencyToken: value.buyCurrency.split("@/@")[0],
    SellCurrencyToken: value.sellCurrency.split("@/@")[0],
    amount: value.amount,
    exchangeRate: value.exchangeRate,
    exchangeAmount: value.exchangeAmount,
    trade: value.trade,
    notes: value.Note
  };
  return await postAPIcall(API_CREATECONVERSION, data);
};
