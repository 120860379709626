import { geVaultRetails } from "../../../Services";

//SETING REDUX INITIAL STATE

export const init_vaultretail_autoPayment = () => {
    return {
        type: "INIT_VRETAILAUTOPAYMENT",
    };
};

//GETING VR BENIFICIARYS FROM VREATIL SEARCH API
export const add_vaultretail_benificary = (benficary_data) => {
    return {
        type: "ADD_SEARCHED_BENFICARYS_INVRETAIL",
        payload: benficary_data
    }
}

export const get_retailautobenficiary_request = () => {
    return {
        type: "GET_BENIFICIARY_LIST_REQ",
    };
};

export const get_retailautobenficiary_sucess = (data) => {
    return {
        type: "GET_BENIFICIARY_LIST_SUCCESS",
        payload: data,
    };
};

export const get_retailautobenficiary_fail = (err) => {
    return {
        type: "GET_BENIFICIARY_LIST_FAIL",
        payload: err,
    };
};

//UPDATE VR INSTRUCTION

export const updatevault_retail_autopayment_ = (payload) => {
    return {
        type: "EDIT_VAULT_RETAIL_INSTRUCTION",
        payload,
    };
};

//ADDING BENIFICIARYS FOR VR AUTOPYAMNET

export const autoadd_vaultreatilbenificiary_formtable = (payload) => {
    return {
        type: "ADD_VR_BENIFICIARY_FORM_TABLE",
        payload,
    }
}

//GETING TRANSACTION LIST OF VR BENIFICIARYS

export const vault_retail_autopayment_transactionlist = (payload) => {
    return {
        type: "VR_ADD_AUTOPAYMENT_TRANSACTION_LIST",
        payload,
    }
}

//GETING VAULT RETAIL BENIFICIARY LIST 

export const GetVaultRetailBenificary = () => {
    return (dispatch) => {
        return new Promise(function (resolve) {
            dispatch(get_retailautobenficiary_request());
            geVaultRetails().then((response) => {
                dispatch(get_retailautobenficiary_sucess(response.data === undefined ? "" : response.data))
                resolve(response.data);
            })
        })
    }
};