const initialState = {
  loading: true,
  EmpId: "",
};

const EmpTransReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EMP_TRANSACTION_ID":
      return {
        ...state,
        EmpId: action.payload,
      };

    case "CLEAR_EMPTRANS_REDUCER":
      return initialState;

    default:
      return state;
  }
};

export default EmpTransReducer;
