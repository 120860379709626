import { KEYS_FOR_LOCALSTORAGE } from "configs/pageConst";
import { getlocalstorage } from "helper";
import { getOnlyPlainDate } from "Services";

export const initialState = {
    loading: false,
    beneficiaryName: "",
    beneficiaryEmail: null,
    formData: [],
    beneficiaryId: null,
    CountryIndex: "",
    countryName: "",
    currencySymbol: "",
    SelectedCountry: "",
    selectedCurrency: "",
    beneficiaryCount: 0,
    beneficiaryList: [],
    apbeneficiaryCount: 0,
    apbeneficiaryList: [],
    transactions: [],
    transactionCount: 0,
    transactionBeneficiaryName: "",
    transactionBeneficiaryEmail: "",
    updateautopayment: false,
    transferMode: "",
    endDate: "",
    startDate: new Date(),
    frequencyType: "",
    currency: "",
    processingDay: 0,
    amount: "",
    EntityType: "",
    iso: "",
    isUpdateAutopay: false,
    transactionBeneficiaryAccNo: "",
    transactionBeneficiaryIBAN: ""
}

export const autoPayVtoBankReduser = (state = initialState, action) => {
    switch (action.type) {
        case "VBTOBANK_AUTOPAY_BENIFICIARY_LIST_REQ":
            return {
                ...state,
                loading: true,
            };

        case "VBTOBANK_AUTOPAY_BENIFICIARY_LIST_SUCESS":
            return {
                ...state,
                loading: false,
                apbeneficiaryCount: action.payload.btAutoPaymentInstructionCount,
                apbeneficiaryList: action.payload?.btAutoPaymentInstructionList ?? [],
                apbeneficiaryfailList: action.payload?.failedBTAutoPaymentInstructionCount ?? []
            };

        case "VBTOBANK_AUTOPAY_BENIFICIARY_LIST_FAIL":
            return {
                ...state,
                loading: false,
                apbeneficiaryCount: 0,
                apbeneficiaryList: [],
            };


        case "VBTOBANK_UPADTE_BENIFICIARY_DETAILS":
            return {
                ...state,
                // isUpdateAutopay: true,
                businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
                beneficiaryId: action.payload.beneficiaryId,
                beneficiaryName: action.payload.beneficiaryName,
                currency: action.payload.curCode,
                amount: parseFloat(action.payload.amount),
                //   fee: action.payload.FeesAmt.toString(),
                //   volfee: action.payload.VolFee.toString(),
                //   exchangeRate: action.payload.exchangeRate.toString(),
                //   exchangeAmount: action.payload.exchangeAmount.toString(),
                totalAmount: action.payload.totalTransferAmt,
                reason: action.payload.Reason,
                paymentType: action.payload.PaymentType,
                paymentChargeType: action.payload.RadioSelect,
                reference: action.payload.reference,
                reasonCode: action.payload.reasonCode,
                specifyReason: action.payload.SpecifyReason,
                frequencyType: parseInt(action.payload.frequencyType),
                processingDay: action.payload.processingDay,
                startDate: action.payload.processingDay,
                endDate: action.payload.endDate === "" ? null : getOnlyPlainDate(action.payload.endDate),
            }

        case "VBTOBANK_GET_TRANSACTION_LIST_DETAILS":
            return {
                ...state,
                transactions: action.payload?.data ?? [],
                transactionCount: action.payload.btAutoPaymentTransactionCount,
                transactionBeneficiaryName: action.payload?.name,
                transactionBeneficiaryEmail: action.payload?.email,
                transactionBeneficiaryIBAN: action.payload?.ibanNo,
                transactionBeneficiaryAccNo: action.payload?.accountNo,
            }

        case "VBTOBANK_INIT":
            return initialState;

        default: return state;
    }
};
export default autoPayVtoBankReduser;