const initialState = {
  loading: true,
  nameOnCard: "",
  maskedPan: "",
  expDate: "",
  walletToken: "",
  employeeId: "",
  businessId: "",
  isCardVirtual: false,
  publicToken: "",
  token: "",
  name: "",
  dialInCode: "",
  isMobileVerified: "",
  mobileNumber: "",
  otp: "",
  requestId: "",
  // AllCards:[]
};

const CardsReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "CARDS_LIST_REQUEST":
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case "CARDS_LIST_SUCCESS":
    //   return {
    //     ...state,
    //     loading: false,
    //     emailStatus: action.payload,
    //     error: "",
    //   };
    // case "CARDS_LIST_FAILURE":
    //   return {
    //     ...state,
    //     loading: false,
    //     emailStatus: "",
    //     error: action.payload,
    //   };
    case "EMPLOYEE_CARDS_ACTION":
      return {
        ...state,
        // Emp_name: action.payload.Emp_name,
        // CardMask: action.payload.CardMask,
        // ExpDate: action.payload.ExpDate,

        nameOnCard: action.payload.nameOnCard,
        maskedPan: action.payload.maskedPan,
        expDate: action.payload.expDate,
        walletToken: action.payload.walletToken,
        employeeId: action.payload.employeeId,
        businessId: action.payload.businessId,
        isCardVirtual: action.payload.isCardVirtual,
        // AllCards: action.payload.AllCards,
      };

    case "ACTIVATE_PHYSICAL_CARD_ACTION":
      return {
        ...state,
        publicToken: action.payload.publicToken,
        token: action.payload.token,
        name: action.payload.name,
        dialInCode: action.payload.dialInCode,
        isMobileVerified: action.payload.isMobileVerified,
        mobileNumber: action.payload.mobileNumber,
        otp: action.payload.otp,
        requestId: action.payload.requestId,
      };

    case "VERIFY_CARD_ACTIVATION_OTP_ACTION":
      return {
        ...state,
        publicToken: action.payload.publicToken,
        token: action.payload.token,
        name: action.payload.name,
        dialInCode: action.payload.dialInCode,
        isMobileVerified: action.payload.isMobileVerified,
        mobileNumber: action.payload.mobileNumber,
        otp: action.payload.otp,
        requestId: action.payload.requestId,
        statusCode: action.payload.statusCode,
        message: action.payload.message,
      };
    case "CLEAR_REDUX_ACTION":
      return initialState;

    default:
      return state;
  }
};

export default CardsReducer;
