export const addBusinessData = (payload) => {
  return {
    type: "Add_Bussiness_Data",
    payload,
  };
};

export const initBusinessData = () => {
  return {
    type: "INIT_SIGNUP_BUSINESS",
  };
};
