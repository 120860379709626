import {
  API_GETSHAREHOLDER_COMPANY_BYID,
  API_SHAREHOLDER_DETAILS_COMPANYID,
  DOWNLOADFILECOMPANY,
} from "../../../../helper";
import { getAPIcall, getAPIcallWithOption } from "Services/apiController";

export const getShareHolderCompanyById = async (Id = "") => {
  return await getAPIcall(API_GETSHAREHOLDER_COMPANY_BYID + Id) 
};

export const getShareholderCompanyDetals = async (Id = "") => {
  return await getAPIcall(API_SHAREHOLDER_DETAILS_COMPANYID + "companyId=" + Id)
};

export const DownloadCompanyFileService = async (name, CompanyID) => {
  return await getAPIcallWithOption(DOWNLOADFILECOMPANY + CompanyID + "&documentName=" + name,null,{ responseType: "arraybuffer" })
};
