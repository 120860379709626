// import { ACTIONS } from "react-joyride";
// import { actions } from "react-table";

const initialState = {
  loading: false,
  error: "",

  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  postalCode: "",
  city: "",
  addressLine1: "",
  addressLine2: "",
  resPersonalDetails: "",
  positionId: null,
  positionindex: null,
  postionName: null,
  citizenshipCountryId: null,
  citizenshipcountryindex: null,
  addressCountryId: null,
  countryIndex: null,
  personalCode: "sampledata",
  State: "",
  // pleaseSelect: "",
  politicallyExposedCategoryId: null,
  politicallyExposedCategoryIndex: null,
  isOtherSelected: false,
  PoliticallyExposedText: "",
  IsUSCitizen: "zero",
  isPoliticallyExposed: "zero",
  IsAttachment: false,
  DocumentType: "",
  DocumentData: "",
  isLocalDocumentData: false,
  DocumentMasterId: null,
  date2: null,
  iso: "",
  name: "",
  isoOne: "",
  nameOne: "",
  isoTwo: "",
  nameTwo: "",
  namePolly: "",
  inputBoxHidden: "",
  isoThree: "",
  nameThree: "",
  UserCurrentStatus: "hhask",
};

const personalDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "PERSONAL_DETAILS_REQUEST":
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case "PERSONAL_DETAILS_SUCCESS":
    //   return {
    //     ...state,
    //     loading: false,
    //     resPersonalDetails: action.payload,
    //     error: "",
    //   };
    // case "PERSONAL_DETAILS_FAILURE":
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    case "ADD_PERSONAL_DATA":
      return {
        ...state,
        loading: false,

        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        postalCode: action.payload.postalCode,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        positionId: action.payload.positionId,
        positionindex: action.payload.positionindex,
        postionName: action.payload.postionName,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        citizenshipcountryindex: action.payload.citizenshipcountryindex,
        personalCode: action.payload.personalCode,
        city: action.payload.city,
        State: action.payload.State,
        countryIndex: action.payload.countryIndex,
        addressCountryId: action.payload.addressCountryId,
        IsAttachment: action.payload.IsAttachment,
        DocumentType: action.payload.DocumentType,
        DocumentData: action.payload.DocumentData,
        isLocalDocumentData: action.payload.isLocalDocumentData,
        DocumentMasterId: action.payload.DocumentMasterId,
        IsUSCitizen: action.payload.IsUSCitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        politicallyExposedCategoryIndex:
          action.payload.politicallyExposedCategoryIndex,
        isOtherSelected: action.payload.isOtherSelected,
        PoliticallyExposedText: action.payload.PoliticallyExposedText,
        iso: action.payload.iso,
        name: action.payload.name,
        isoOne: action.payload.isoOne,
        nameOne: action.payload.nameOne,
        isoTwo: action.payload.isoTwo,
        nameTwo: action.payload.nameTwo,
        namePolly: action.payload.namePolly,
        date2: action.payload.date2,
        inputBoxHidden: action.payload.inputBoxHidden,
      };
    case "INIT_SIGNUP_PERSONAL_DETAILS":
      return initialState;
    case "On_Dato_Current":
      return {
        ...state,
        UserCurrentStatus: "gsagsa",
      };
    default:
      return state;
  }
};

export default personalDetailsReducer;
