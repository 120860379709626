// import { isInteger } from "formik";

const initialState = {
  businessInfoId: "",
  name: "",
  incorporationNumber: "",
  incorporationDate: "",
  countryId: "",
  sharePercentage: "",
  linkToStateInformationSystemOrRegister: "",
  addresses: {
    businessInfoId: "",
    addressTypeId: 1,
    country: "",
    city: "",
    postalCode: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    isSameAsRegAddress: "",
  },
};

const businessOwnerDetailsDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DOCSUBMIT_BUSINESSOWNERDETAILS_LIST_REQUEST":
      return {
        ...state,
        loading: true,
        businessInfoId: action.payload.businessInfoId,
        name: action.payload.businessInfoId,
        incorporationNumber: action.payload.businessInfoId,
        incorporationDate: action.payload.businessInfoId,
        countryId: action.payload.businessInfoId,
        sharePercentage: action.payload.businessInfoId,
        linkToStateInformationSystemOrRegister: action.payload.businessInfoId,
        addresses: {
          businessInfoId: action.payload.addresses.businessInfoId,
          addressTypeId: action.payload.addresses.addressTypeId,
          country: action.payload.addresses.country,
          city: action.payload.addresses.city,
          postalCode: action.payload.addresses.postalCode,
          addressLine1: action.payload.addresses.addressLine1,
          addressLine2: action.payload.addresses.addressLine2,
          state: action.payload.addresses.state,
          isSameAsRegAddress: action.payload.addresses.isSameAsRegAddress,
        },
      };
    case "DOCSUBMIT_BUSINESSOWNERDETAILS_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        directorList: action.payload.directorList,
        UltimatebenificiaryOwnerList:
          action.payload.UltimatebenificiaryOwnerList,
        error: "",
      };
    case "DOCSUBMIT_BUSINESSOWNERDETAILS_LIST_FAILURE":
      return {
        ...state,
        sameasRedAddr: false,
        loading: false,
        directorList: [],
        UltimatebenificiaryOwnerList: [],
        error: action.payload,
      };
    case "DOCSUBMIT_BUSINESSOWNERDETAILS_DOC_LIST":
      return {
        ...state,
        loading: false,
        directorList: action.payload.directorList,
        UltimatebenificiaryOwnerList:
          action.payload.UltimatebenificiaryOwnerList,
      };
    default:
      return state;
  }
};

export default businessOwnerDetailsDocReducer;
