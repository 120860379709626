import axios from "axios";
import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import {
  API_DOCUMENT_UPLOAD,
  API_GET_PERSONALDETAIL_BUSINESS_AGGRIGATOR,
  ONDATOINIT,
  API_PERSONAL_DETAILS,
  DocumentTypeData,
  getlocalstorage,
} from "../../../../helper";
import { getAPIcall, postAPIcall } from "Services/apiController";

const moment = require("moment");

const { POA } = DocumentTypeData;

export const personalDetailsService = async (data) => {
  data = {
    individualId: data.individualId,
  };
  return await postAPIcall(API_PERSONAL_DETAILS, data);
};

export const PersondetailsDocUpload = async (data) => {
  const form = new FormData();
  form.append("UID", data.individualId);
  form.append("IsIndividual", POA.IsIndividual); //true
  form.append("DocumentType", `${POA.DocumentType}${moment().format("x")}`); //POA_
  form.append("DocumentData", data.POA);
  form.append("DocumentMasterId", POA.DocumentMasterId); //1
  form.append("IsPOI", POA.IsPOI); //false

  return await axios.post(API_DOCUMENT_UPLOAD, form, {
    headers: {
      Authorization: `Bearer ${getlocalstorage(
        KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
      )}`,
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
    },
  });
};

export const get_Personal_details_BusinessAggrigator = async (
  individualId = getlocalstorage(KEYS_FOR_LOCALSTORAGE.INDIVIDUALINFOID)
) => {
  return await getAPIcall(
    API_GET_PERSONALDETAIL_BUSINESS_AGGRIGATOR + individualId
  );
};

export const onDatoService = async (data) => {
  return await postAPIcall(ONDATOINIT, data);
};
