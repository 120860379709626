import classnames from "classnames";
import React, { PureComponent } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { connect } from "react-redux";
import Customizer from "../components/@vuexy/customizer/Customizer";
import { BlockUI } from "../components/CustomComponents/General/BlockUI/BlockUI";
import { KEYS_FOR_LOCALSTORAGE } from "../configs/pageConst";
import { clearlocalstorage, getlocalstorage } from "../helper";

import {
  changeFooterType,
  changeMenuColor,
  changeMode,
  changeNavbarColor,
  changeNavbarType,
  collapseSidebar,
  hideScrollToTop,
  getUserApplicationStatus,
  setUserProfileinls,
} from "../redux/actions/customizer/index";
import { RefreshTokenClass } from "../utility/RefreshToken/RefreshToken";
import Navbar from "./components/DocSubmitnavbar/Navbar";
import Footer from "./components/footer/Footer";
import "./DocSubmitLayout.css";
class DocSubmitLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    sidebarState: true,
    layout: this.props.app.customizer.theme,
    collapsedContent: true,
    sidebarHidden: true,
    currentLang: "en",
    appOverlay: false,
    customizer: false,
    currRoute: this.props.location.pathname,
    block: true,
    sessionTime: getlocalstorage(KEYS_FOR_LOCALSTORAGE.EXPIRE_TIME),
  };
  collapsedPaths = [];
  mounted = false;
  tokenIdInfo = "";
  updateWidth = () => {
    if (this.mounted) {
      this.setState(() => ({
        width: window.innerWidth,
      }));
    }
  };

  handleCustomizer = (bool) => {
    this.setState({
      customizer: bool,
    });
  };

  async componentDidMount() {
    if (window.performance) {
      if (
        performance.navigation.type == 1 &&
        RefreshTokenClass.Tokeninfo === "" &&
        RefreshTokenClass.isActive === true &&
        RefreshTokenClass.isSetted === false
      ) {
        RefreshTokenClass.callrefreshToken();
        RefreshTokenClass.startToken();
      }
    }
    this.setState({ block: false });
    this.tokenIdInfo = RefreshTokenClass.startToken();
    (async () => {
      const isAuthenticated = getlocalstorage(
        KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN
      );
      const isProfileExist = getlocalstorage(KEYS_FOR_LOCALSTORAGE.PROFILE);
      if (isAuthenticated && isProfileExist === null) {
        clearlocalstorage();
      }
    })();

    this.mounted = true;
    let {
      location: { pathname },
      app: {
        customizer: { theme, direction },
      },
    } = this.props;

    if (this.mounted) {
      if (window !== "undefined") {
        window.addEventListener("resize", this.updateWidth, false);
      }
      if (this.collapsedPaths.includes(pathname)) {
        this.props.collapseSidebar(true);
      }

      let layout = theme;
      let dir = direction;
      if (dir === "rtl")
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      return layout === "dark"
        ? document.body.classList.add("dark-layout")
        : layout === "semi-dark"
        ? document.body.classList.add("semi-dark-layout")
        : null;
    }
  }

  componentDidUpdate(prevProps) {
    let {
      location: { pathname },
      app: {
        customizer: { theme, sidebarCollapsed },
      },
    } = this.props;

    let layout = theme;
    if (this.mounted) {
      if (layout === "dark") {
        document.body.classList.remove("semi-dark-layout");
        document.body.classList.add("dark-layout");
      }
      if (layout === "semi-dark") {
        document.body.classList.remove("dark-layout");
        document.body.classList.add("semi-dark-layout");
      }
      if (layout !== "dark" && layout !== "semi-dark") {
        document.body.classList.remove("dark-layout", "semi-dark-layout");
      }

      if (
        prevProps.app.customizer.sidebarCollapsed !==
        this.props.app.customizer.sidebarCollapsed
      ) {
        this.setState({
          collapsedContent: sidebarCollapsed,
          sidebarState: sidebarCollapsed,
        });
      }
      if (
        prevProps.app.customizer.sidebarCollapsed ===
          this.props.app.customizer.sidebarCollapsed &&
        pathname !== prevProps.location.pathname &&
        this.collapsedPaths.includes(pathname)
      ) {
        this.props.collapseSidebar(true);
      }
      if (
        prevProps.app.customizer.sidebarCollapsed ===
          this.props.app.customizer.sidebarCollapsed &&
        pathname !== prevProps.location.pathname &&
        !this.collapsedPaths.includes(pathname)
      ) {
        this.props.collapseSidebar(false);
      }
    }
  }

  handleCollapsedMenuPaths = (item) => {
    let collapsedPaths = this.collapsedPaths;
    if (!collapsedPaths.includes(item)) {
      collapsedPaths.push(item);
      this.collapsedPaths = collapsedPaths;
    }
  };

  toggleSidebarMenu = () => {
    this.setState({
      sidebarState: !this.state.sidebarState,
      collapsedContent: !this.state.collapsedContent,
    });
  };

  sidebarMenuHover = (val) => {
    this.setState({
      sidebarState: val,
    });
  };

  handleSidebarVisibility = () => {
    if (this.mounted) {
      if (window !== undefined) {
        window.addEventListener("resize", () => {
          if (this.state.sidebarHidden) {
            this.setState({
              sidebarHidden: true,
            });
          }
        });
      }
      this.setState({
        sidebarHidden: true,
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    if (window !== "undefined") {
      window.removeEventListener("resize", this.updateWidth);  
    }
  }

  handleCurrentLanguage = (lang) => {
    this.setState({
      currentLang: lang,
    });
  };

  handleAppOverlay = (value) => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true,
      });
    } else if (value.length < 0 || value === "") {
      this.setState({
        appOverlay: false,
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false,
    });
  };
  render() {
    let appProps = this.props.app.customizer;
    let menuThemeArr = [
      "primary",
      "success",
      "danger",
      "info",
      "warning",
      "dark",
    ];

    let navbarProps = {
      toggleSidebarMenu: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarVisibility: this.handleSidebarVisibility,
      currentLang: this.state.currentLang,
      changeCurrentLang: this.handleCurrentLanguage,
      handleAppOverlay: this.handleAppOverlay,
      appOverlayState: this.state.appOverlay,
      navbarColor: appProps.navbarColor,
      navbarType: appProps.navbarType,
    };

    let footerProps = {
      footerType: appProps.footerType,
      hideScrollToTop: appProps.hideScrollToTop,
    };

    let customizerProps = {
      customizerState: this.state.customizer,
      handleCustomizer: this.handleCustomizer,
      changeMode: this.props.changeMode,
      changeNavbar: this.props.changeNavbarColor,
      changeNavbarType: this.props.changeNavbarType,
      changeFooterType: this.props.changeFooterType,
      changeMenuTheme: this.props.changeMenuColor,
      collapseSidebar: this.props.collapseSidebar,
      hideScrollToTop: this.props.hideScrollToTop,
      activeMode: appProps.theme,
      activeNavbar: appProps.navbarColor,
      navbarType: appProps.navbarType,
      footerType: appProps.footerType,
      menuTheme: appProps.menuTheme,
      scrollToTop: appProps.hideScrollToTop,
      sidebarState: appProps.sidebarCollapsed,
    };
    return (
      <>
        <IdleTimerProvider
          events={[
            "scroll",
            "click",
            "input",
            "focusin",
            "focusout",
            "blur",
            "drag",
            "drop",
            "wheel",
            "toggle",
          ]}
          timeout={this.state.sessionTime * 1000}
          debounce={1000}
          onActive={() => {
            RefreshTokenClass.stopLogoutToken();
            RefreshTokenClass.stoprefreshToken();
            RefreshTokenClass.callrefreshToken();
            RefreshTokenClass.isActive = true;
            RefreshTokenClass.isSetted = false;
            RefreshTokenClass.startToken();
          }}
          eventsThrottle={1000}
          onIdle={() => {
            RefreshTokenClass.callrefreshToken();
            RefreshTokenClass.stoprefreshToken();
            RefreshTokenClass.isActive = false;
            RefreshTokenClass.isSetted = false;
            RefreshTokenClass.startLogoutTokenCall();
          }}
        >
          <div
            style={{ overflowX: "hidden" }}
            className={classnames(
              `wrapper horizontal-layout theme-${appProps.menuTheme}`,
              {
                "menu-collapsed":
                  this.state.collapsedContent === true &&
                  this.state.width >= 1200,
                "fixed-footer": appProps.footerType === "sticky",
                "navbar-static": appProps.navbarType === "static",
                "navbar-sticky": appProps.navbarType === "sticky",
                "navbar-floating": appProps.navbarType === "floating",
                "navbar-hidden": appProps.navbarType === "hidden",
                // "navbar-floating": appProps.navbarType === "docupload",
                "theme-primary": !menuThemeArr.includes(appProps.menuTheme),
              }
            )}
          >
            {/* <Sidebar {...sidebarProps} /> */}
            <div onClick={this.handleAppOverlayClick}>
              <Navbar {...navbarProps} />
              {/* <InitialAPICall> */}
              <div className="content-wrapper content-wrapper-bg-color">
                {!this.state.block ? (
                  this.props.children
                ) : (
                  <BlockUI blocking={true} />
                )}
              </div>
              {/* </InitialAPICall> */}
            </div>

            <Footer {...footerProps} />
            {appProps.disableCustomizer !== true ? (
              <Customizer {...customizerProps} />
            ) : null}
            <div
              className="sidenav-overlay"
              onClick={this.handleSidebarVisibility}
            />
          </div>
        </IdleTimerProvider>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.customizer,
    isApplicationCompleted: state.dashboard.profile.isApplicationCompleted,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     callCountryAPI: () => {
//       return dispatch(callCountryAPI());
//     },nitSignup : para => dispatch => dispatch(initSignup(para))
//   };
// };

export default connect(mapStateToProps, {
  changeMode,
  collapseSidebar,
  changeNavbarColor,
  changeNavbarType,
  changeFooterType,
  changeMenuColor,
  hideScrollToTop,
  getUserApplicationStatus,
  setUserProfileinls,
})(DocSubmitLayout);
