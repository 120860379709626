import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import { API_COMPANYLIST, API_PERSONLIST, getlocalstorage } from "../../../../helper";
import { getAPIcall } from "Services/apiController";

export const getShareHolderCompanyList = async () => {
  return await getAPIcall(API_COMPANYLIST + getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID))
};

export const getShareHolderPersonList = async () => {
  return await getAPIcall(API_PERSONLIST + getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID))
};
