import { getAPIcall } from "Services/apiController";
import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import {
  BULKV2VTRANSFER,
  BULKV2V_BUSINESS_NAME,
  MultipartPostAfterLogin,
  getlocalstorage,
} from "../../../helper";

/**
 *API of  bulk payment
 * @param {*} data
 * @returns axios.post of BULKV2VTRANSFER
 */
export const bulkpayment_api = async (data) => {
  const form = new FormData();
  form.append("DocumentData", data.file[0]);

  return await MultipartPostAfterLogin(BULKV2VTRANSFER, form);
};
/**
 * API of Get Business Name
 * @param {*} values
 * @returns axios.get of BULKV2V_BUSINESS_NAME
 */
export const GetBusinessNameAPI = async () => {
  let data = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID).replaceAll(
    '"',
    ""
  );
  return await getAPIcall(BULKV2V_BUSINESS_NAME + data);
};
