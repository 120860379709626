// import {  } from "../../Services/registrationServices";

export const addGDPRData = (userObj) => {
  return (dispatch) => {
    dispatch(GDPRData(userObj));

    return true;
  };
};

export const GDPRData = (payload) => {
  return {
    type: "ADD_GDPR_DATA",
    payload,
  };
};

export const initGDPR = () => {
  return {
    type: "INIT_SIGNUP_GDPR",
  };
};
// export const GDPRDetailseRequest = () => {
//   return {
//     type: "GDPR_DETAILS_REQUEST",
//   };
// };

// export const GDPRDetailSuccess = (GDPRDtailStatus) => {
//   return {
//     type: "GDPR_DETAILS_SUCCESS",
//     payload: GDPRDtailStatus,
//   };
// };

// export const GDPRDetailsFailure = (error) => {
//   return {
//     type: "GDPR_DETAILS_FAILURE",
//     payload: error,
//   };
// };
