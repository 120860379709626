import { getAPIcall } from "Services/apiController";
import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import {
  API_GETCURRENCIES,
  API_MAX_CURR_COUNT,
  getlocalstorage,
  setlocalstorage,
} from "../../../helper";

export const GetCurrenciesAPI = async () => {
  let data = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID).replaceAll(
    '"',
    ""
  );
  setlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID, data);
  return await getAPIcall(API_GETCURRENCIES + "?businessId=" + data);
};

export const getMaxCurrencyCount = async () => {
  return await getAPIcall(API_MAX_CURR_COUNT);
};
