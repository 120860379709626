// import { date } from "yup";

const initialState = {
  loading: false,
  updatestate: false,
  isShareholderPersonAPICall: true,
  isPreRegPerson: false,
  iamShareHolderstate: false,
  isRejected: false,
  isnewData: false,
  id: "",
  proid: "",
  idcopy: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: null,
  citizenshipCountryId: null,
  postalCode: "",
  city: "",
  addressLine1: "",
  addressLine2: "",
  preRegistrationId: "",
  positionId: null,
  individualTypeId: 0,
  businessInfoId: "",
  ownershipPercentage: null,
  isUscitizen: "zero",
  isPoliticallyExposed: "zero",
  isPoliticallyexposedBool: false,
  politicallyExposedCategoryId: null,
  isOtherSelected: false,
  politicallyExposedText: "",
  sourceOfWealth: "",
  isUbo: "zero",
  isUboSelected: false,
  isShareHolder: false,
  isExecutive: "zero",
  isExecutiveSelected: false,
  otherPositionId: null,
  taxViewModel: {
    taxCountryId: null,
    tin: "",
    index: null,
  },
  addressCountryId: null,
  dialInCode: null,
  emailAddress: "",
  state: "",
  phoneNo: "",
  error: "",
  countryId: "",
  politicallyExposedname: "",
  otherPositionName: "",
  citizenshipCountryIdIndex: null,
  addressCountryIdIndex: null,
  dialInCodeIndex: null,
  PositionIdIndex: null,
  politicallyExposedCategoryIdIndex: null,
  warningMsg: [],
  DocumentType: null,
  isShareolderPersonDocAPICall: true,
  typeindex: null,
  DocumentNumber: "",
  IssuingCountryId: null,
  IssuingCountryIndex: null,
  DateOfIssue: "",
  DateOfExpiry: "",
  proofidentityfront: "",
  isLocalproofidentityfront: false,
  proofidentityback: "",
  isLocalproofidentityback: false,
  POA: "",
  isLocalPOA: false,
  POSF: "",
  isLocalPOSF: false,
};

const ShareHolderDetailPersonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHAREHOLDER_DETAIL_PERSON_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SHAREHOLDER_PERSON_REJECT":
      return {
        ...state,
        isRejected: action.payload.isRejected,
        isnewData: action.payload.isnewData,
      };
    case "SHAREHOLDER_DETAIL_PERSON_SUCCESS":
      return {
        ...state,
        loading: false,
        countryId: action.payload.countryId,
        error: "",
      };
    case "SHAREHOLDER_DETAIL_PERSON_FAILURE":
      return {
        ...state,
        loading: false,
        countryId: "",
        error: action.payload,
      };
    case "FETCH_SHAREHOLDERDETAIL_PERSON":
      return {
        ...state,
        updatestate: action.payload.updatestate,
        isShareholderPersonAPICall: action.payload.isShareholderPersonAPICall,
        loading: false,
        id: action.payload.id,
        proid: action.payload.proid,
        idcopy: action.payload.idcopy,
        isPreRegPerson: action.payload.isPreRegPerson,
        iamShareHolderstate: action.payload.iamShareHolderstate,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        postalCode: action.payload.postalCode,
        city: action.payload.city,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        preRegistrationId: action.payload.preRegistrationId,
        positionId: action.payload.positionId,
        individualTypeId: action.payload.individualTypeId,
        businessInfoId: action.payload.businessInfoId,
        ownershipPercentage: action.payload.ownershipPercentage,
        isUscitizen: action.payload.isUscitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        isPoliticallyexposedBool: action.payload.isPoliticallyexposedBool,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        isOtherSelected: action.payload.isOtherSelected,
        politicallyExposedText: action.payload.politicallyExposedText,
        sourceOfWealth: action.payload.sourceOfWealth,
        isUbo: action.payload.isUbo,
        isShareHolder: action.payload.isShareHolder,
        isExecutive: action.payload.isExecutive,
        isExecutiveSelected: action.payload.isExecutive,
        otherPositionId: action.payload.otherPositionId,
        taxViewModel: {
          taxCountryId: action.payload.taxViewModel.taxCountryId,
          tin: action.payload.taxViewModel.tin,
          index: action.payload.taxViewModel.index,
        },
        addressCountryId: action.payload.addressCountryId,
        dialInCode: action.payload.dialInCode,
        emailAddress: action.payload.emailAddress,
        state: action.payload.state,
        phoneNo: action.payload.phoneNo,
        isUboSelected: action.payload.isUboSelected,
        politicallyExposedname: action.payload.politicallyExposedname,
        otherPositionName: action.payload.otherPositionName,

        citizenshipCountryIdIndex: action.payload.citizenshipCountryIdIndex,
        addressCountryIdIndex: action.payload.addressCountryIdIndex,
        dialInCodeIndex: action.payload.dialInCodeIndex,
        PositionIdIndex: action.payload.PositionIdIndex,
        politicallyExposedCategoryIdIndex:
          action.payload.politicallyExposedCategoryIdIndex,
      };

    case "FETCH_SHAREHOLDERDETAIL_DOC_PERSON":
      return {
        ...state,
        DocumentType: action.payload.DocumentType,
        isShareolderPersonDocAPICall:
          action.payload.isShareolderPersonDocAPICall,
        typeindex: action.payload.typeindex,
        DocumentNumber: action.payload.DocumentNumber,
        IssuingCountryId: action.payload.IssuingCountryId,
        IssuingCountryIndex: action.payload.IssuingCountryIndex,
        DateOfIssue: action.payload.DateOfIssue,
        DateOfExpiry: action.payload.DateOfExpiry,
        proofidentityfront: action.payload.proofidentityfront,
        isLocalproofidentityfront: action.payload.isLocalproofidentityfront,
        proofidentityback: action.payload.proofidentityback,
        isLocalproofidentityback: action.payload.isLocalproofidentityback,
        POA: action.payload.POA,
        isLocalPOA: action.payload.isLocalPOA,
        POSFUploadedDocuments: action.payload.POSFUploadedDocuments,
        POSFDocumentsTobeUpload: action.payload.POSFDocumentsTobeUpload,
        warningMsg: action.payload.warningMsg,
      };
    case "INIT_SHAREHOLDERPERSON":
      return initialState;
    default:
      return state;
  }
};

export default ShareHolderDetailPersonReducer;
