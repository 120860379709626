// import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  // NavItem,
  // NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { WarningAlert } from "../../../components/CustomComponents/SuspendedHeaderAlert/WarningAlert";
// import classnames from "classnames";
// import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { history } from "../../../history";
import { logoutAPI } from "../../../Services/auth_API";
import { clearlocalstorage, clearSessionStorage } from "../../../helper";
import { RefreshTokenClass } from "../../../utility/RefreshToken/RefreshToken";
import { initSignup } from "redux/actions";
import { userApplicationStatus } from "helper/Constants/UserAppStatus/AppStatus";

const UserDropdown = (props) => {
  const logoutApi = async () => {
    try {
      const result = await logoutAPI();

      if (!(result.status === 200 && !result.data.error)) {
        clearlocalstorage();
        clearSessionStorage();
        await props.initSignup();
        // Broadcast Channel is Added for MultiTab Session Out Functionality
        RefreshTokenClass.stoprefreshToken();
        const logoutChannel = new BroadcastChannel('vault-business-authentication');
        logoutChannel.postMessage("vb-logout")
        history.push("/");
        // window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        // href="/"
        onClick={() => {
          history.push("/change-password");
        }}
      >
        <Icon.Lock size={14} className="mr-50" />
        <span className="align-middle">Change Password</span>
      </DropdownItem>

      <DropdownItem
        tag="a"
        // href="/"
        onClick={logoutApi}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    FinalAppStatus: "",
    status: false,
    isSuspendedFlag: this.props.profile.finalApplicationStatus,
  };

  componentDidMount() {
    if (this.props.profile.finalApplicationStatus === userApplicationStatus.suspended || this.props.profile.finalApplicationStatus === userApplicationStatus.membershipExpired) {
      this.setState({ isSuspendedFlag: this.props.profile.finalApplicationStatus , status: true })
    }
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {

    return (
      <React.Fragment>
        <div>
          {this.props.profile.finalApplicationStatus === userApplicationStatus.suspended || this.props.profile.finalApplicationStatus === userApplicationStatus.membershipExpired ?
            <Row>
              <div>
                {this.props.profile.finalApplicationStatus === userApplicationStatus.suspended ?
                  <WarningAlert
                    errorMsg={"This account is suspended"}
                    visible={true}
                  /> : this.props.profile.finalApplicationStatus === userApplicationStatus.membershipExpired ?
                    <WarningAlert
                      errorMsg={"This account is suspended (Membership Expired)"}
                      visible={true}
                    /> : ""
                }
              </div>
            </Row>
            : ""}
        </div>
        <div>
          <ul className="nav navbar-nav navbar-nav-user float-right">

            <div>
              <img
                src={this.props.bellImg}
                //   className="round"
                alt="bell"
                width="23px"
                height="57px"
              //   marginTop="5%"
              />
              <img
                src={this.props.lineImg}
                alt="line"
                //   className="round"
                width="33px"
                height="27px"
                border="5px solid #79879B"
              //   marginLeft="5%"
              />
            </div>

            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
              <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                  {/* <span className="user-name text-bold-600 text-dark"> */}
                  <span className="user-name vault-user-name">
                    {this.props.userName}
                  </span>
                  {/* <span className="user-email text-bold"> */}
                  <span className="user-email vault-user-email">
                    {this.props.userEmail}
                  </span>
                  {/* <span className="user-status">Available</span> */}
                </div>
                <span data-tour="user">
                  <img
                    src={this.props.userImg}
                    className="round"
                    height="40"
                    width="40"
                    alt="avatar"
                  />
                </span>
              </DropdownToggle>
              <UserDropdown {...this.props} />
            </UncontrolledDropdown>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.dashboard.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
	return {
		initSignup: () => {
			dispatch(initSignup());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
