const initialState = {
  loading: false,
  error: "",
  // proofidentityfront: [],
  // proofidentityback: [],
  updatestate: false,
  CertificateofincorporationDocumentsTobeUpload: [],
  CertificateofincorporationUploadedDocuments: [],

  ArticlesofassociationDocumentsTobeUpload: [],
  ArticlesofassociationUploadedDocuments: [],

  RegistryofshareholdersDocumentsTobeUpload: [],
  RegistryofshareholdersUploadedDocuments: [],

  OwnershipstructureDocumentsTobeUpload: [],
  OwnershipstructureUploadedDocuments: [],

  FinancialaccountsDocumentsTobeUpload: [],
  FinancialaccountsUploadedDocuments: [],

  BankstatementsDocumentsTobeUpload: [],
  BankstatementsUploadedDocuments: [],

  BusinessplanDocumentsTobeUpload: [],
  BusinessplanUploadedDocuments: [],

  BusinesslicenseDocumentsTobeUpload: [],
  BusinesslicenseUploadedDocuments: [],

  AMLpoliciesDocumentsTobeUpload: [],
  AMLpoliciesUploadedDocuments: [],

  callBusinessdocApi: true,
  // POA: [],
  // POA1: [],
  // POSF: [],
  // POSF1: [],
};

const BusinessDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BUSINESS_DOC":
      return {
        ...state,
        loading: false,
        updatestate: action.payload.updatestate,

        CertificateofincorporationDocumentsTobeUpload: action.payload.CertificateofincorporationDocumentsTobeUpload,
        CertificateofincorporationUploadedDocuments: action.payload.CertificateofincorporationUploadedDocuments,

        ArticlesofassociationDocumentsTobeUpload: action.payload.ArticlesofassociationDocumentsTobeUpload,
        ArticlesofassociationUploadedDocuments: action.payload.ArticlesofassociationUploadedDocuments,

        RegistryofshareholdersDocumentsTobeUpload: action.payload.RegistryofshareholdersDocumentsTobeUpload,
        RegistryofshareholdersUploadedDocuments: action.payload.RegistryofshareholdersUploadedDocuments,

        OwnershipstructureDocumentsTobeUpload: action.payload.OwnershipstructureDocumentsTobeUpload,
        OwnershipstructureUploadedDocuments: action.payload.OwnershipstructureUploadedDocuments,

        FinancialaccountsDocumentsTobeUpload: action.payload.FinancialaccountsDocumentsTobeUpload,
        FinancialaccountsUploadedDocuments: action.payload.FinancialaccountsUploadedDocuments,

        BankstatementsDocumentsTobeUpload: action.payload.BankstatementsDocumentsTobeUpload,
        BankstatementsUploadedDocuments: action.payload.BankstatementsUploadedDocuments,

        BusinessplanDocumentsTobeUpload: action.payload.BusinessplanDocumentsTobeUpload,
        BusinessplanUploadedDocuments: action.payload.BusinessplanUploadedDocuments,

        BusinesslicenseDocumentsTobeUpload: action.payload.BusinesslicenseDocumentsTobeUpload,
        BusinesslicenseUploadedDocuments: action.payload.BusinesslicenseUploadedDocuments,

        AMLpoliciesDocumentsTobeUpload: action.payload.AMLpoliciesDocumentsTobeUpload,
        AMLpoliciesUploadedDocuments: action.payload.AMLpoliciesUploadedDocuments,

        callBusinessdocApi: action.payload.callBusinessdocApi,
      };
    case "INIT_BUSINESS_DOC":
      return initialState;
    default:
      return state;
  }
};

export default BusinessDocReducer;
