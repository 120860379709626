import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import {
  API_BNUSINESS_INFO_ADD_AGGRI,
  API_BUSINESS_ADD_INFO,
  API_POST_ADDITIONAL_DETAIL,
  CURRENTACCOUNTPURPOSEAPI,
  getlocalstorage,
} from "../../../../helper";
import { getAPIcall, postAPIcall } from "Services/apiController";

export const getBusinessAddinfo = async (
  id = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID)
) => {
  return await getAPIcall(API_BUSINESS_ADD_INFO + id);
};
export const getBusinessAddinfoAggri = async (
  Businessinfoid = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID)
) => {
  return await getAPIcall(API_BNUSINESS_INFO_ADD_AGGRI + Businessinfoid);
};
export const saveBusinessInfo = async (values) => {
  let socialMediaLinks = [];
  for (var i = 0; i < values.socialMediaLinks.length; i++) {
    socialMediaLinks.push(values.socialMediaLinks[i].Links);
  }
  let partners = [];
  if (
    values.incomingPartners[0].countryId !== null &&
    values.incomingPartners[0].name !== ""
  ) {
    partners = [...partners, ...values.incomingPartners];
  }
  if (
    values.outgoingPartners[0].countryId !== null &&
    values.outgoingPartners[0].name !== ""
  ) {
    partners = [...partners, ...values.outgoingPartners];
  }

  // console.log("partners:", partners);
  let data1 = {
    businessInfoId: values.businessInfoId, // "string",
    description: values.description, //"string",
    activity: values.activity, //0,
    isProhibitedBussinessCategoryConfirmed:
      values.isProhibitedBussinessCategoryConfirmed,
    // otherActivity: values.otherActivity, //"string",
    licenceNumber: values.licenceNumber, // "string",
    incomeSources: values.incomeSources, //"string",
    isPassive: values.isPassive === "one" ? true : false, // true,
    accountPurpose: values.accountPurpose, // "string",
    accountPurposeOtherText: values.accountPurposeOtherText,
    investment: values.investment,
    expectedAnualTurnover: values.expectedAnualTurnover, //"string",
    monthlyVolOfIncomingTransfer: values.monthlyVolOfIncomingTransfer, // "string",
    monthlyNumberofIncomingTransactions:
      values.monthlyNumberofIncomingTransactions,
    monthlyVolOfOutgoingTransfer: values.monthlyVolOfOutgoingTransfer, // "string",
    monthlyNumberofOutgoingTransactions:
      values.monthlyNumberofOutgoingTransactions,
    destinationCountriesAndCounterparties:
      values.destinationCountriesAndCounterparties, // "string",
    clientBase: values.clientBase, // "string",
    // website: values.website, //"string",
    accounts: values.accounts, //[  ],
    partners: partners.length === 0 ? null : partners, // [],
    socialMediaLinks: socialMediaLinks, // // ["string"]
  };
  return await postAPIcall(API_POST_ADDITIONAL_DETAIL, data1);
};

export const getCurrentPurpose = async () => {
  return await getAPIcall(CURRENTACCOUNTPURPOSEAPI);
};
