const initialState = {
  loading: false,
  dialCode: null,
  dialcodeIndex: null,
  phoneNumber: "",
  resData: "",
  iso: "",
  name: "",
  POA: "",
  isLocalPOA: false,
  status: null,
  individualId: "",
};

const personalDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Pesrsonal_Details_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "Pesrsonal_Details_SUCCESS":
      return {
        ...state,
        loading: false,
        countryId: action.payload.countryId,
        resData: action.payload,
      };
    case "Pesrsonal_Details_FAILURE":
      return {
        ...state,
        loading: false,
        countryId: "",
        error: action.payload,
      };
    case "ADD_PERSONAL_DETAILS":
      return {
        ...state,
        dialCode: action.payload.dialCode,
        dialcodeIndex: action.payload.dialcodeIndex,
        phoneNumber: action.payload.phoneNumber,
        individualId: action.payload.individualId,
        POA: action.payload.POA,
        isLocalPOA: action.payload.isLocalPOA,
        status: action.payload.status,
      };
    case "INIT_PERSONAL_DETAILS":
      return initialState;
    case "documentdata_Success":
      return {
        ...state,
        resDocumentUpload: action.payload,
      };
    case "documentdata_Failure":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default personalDetailsReducer;
