export const EmployeeCardsAction = (data) => {
  return {
    type: "EMPLOYEE_CARDS_ACTION",
    payload: data,
  };
};

export const ActivatePhysicalCard_Action = (data) => {
  return {
    type: "ACTIVATE_PHYSICAL_CARD_ACTION",
    payload: data,
  };
};

export const VerifyCardActivationOtp_Action = (data) => {
  return {
    type: "VERIFY_CARD_ACTIVATION_OTP_ACTION",
    payload: data,
  };
};
