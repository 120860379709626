import {
  API_DOCUMENT_UPLOAD,
  DocumentTypeData,
  MultipartPostAfterLogin,
} from "../../../../helper";
const moment = require("moment");

const { POIF, POIB, POA } = DocumentTypeData;

export const POIFrontDocumentUpload = async (values, exeid) => {
  const form = new FormData();

  form.append("UID", `${exeid}`);
  form.append("IsIndividual", POIF.IsIndividual);
  form.append("DocumentType", `${POIF.DocumentType}${moment().format("x")}`);
  form.append("POIRequest.DocumentType", `${values.DocumentType}`);
  form.append("DocumentData", values.proofidentityfront);
  form.append("DocumentMasterId", POIF.DocumentMasterId);
  form.append("POIRequest.DocumentNumber", values.DocumentNumber);
  form.append("POIRequest.IssuingCountryId", values.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", values.DateOfIssue);
  form.append(
    "POIRequest.DateOfExpiry",
    values.DateOfExpiry != null ? values.DateOfExpiry : null
  );
  form.append("IsPOI", POIF.IsPOI); //true

  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const POIBackDocumentUpload = async (values, exeid) => {
  const form = new FormData();

  form.append("UID", `${exeid}`);
  form.append("IsIndividual", POIB.IsIndividual); //true
  form.append("DocumentType", `${POIB.DocumentType}${moment().format("x")}`); //POI-B_
  form.append("POIRequest.DocumentType", `${values.DocumentType}`);
  form.append("DocumentData", values.proofidentityback);
  form.append("DocumentMasterId", POIB.DocumentMasterId); //3
  form.append("POIRequest.DocumentNumber", values.DocumentNumber);
  form.append("POIRequest.IssuingCountryId", values.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", values.DateOfIssue);
  form.append("POIRequest.DateOfExpiry", values.DateOfExpiry);
  form.append("IsPOI", POIB.IsPOI); //true

  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};

export const POADocumentUpload = async (values, exeid) => {
  const form = new FormData();

  form.append("UID", `${exeid}`);
  form.append("IsIndividual", POA.IsIndividual); //true
  form.append("DocumentType", `${POA.DocumentType}${moment().format("x")}`); //POA_
  form.append("DocumentData", values.POA);
  form.append("DocumentMasterId", POA.DocumentMasterId); //1
  form.append("POIRequest.DocumentNumber", values.DocumentNumber);
  form.append("POIRequest.IssuingCountryId", values.IssuingCountryId);
  form.append("POIRequest.DateOfIssue", values.DateOfIssue);
  form.append("POIRequest.DateOfExpiry", values.DateOfExpiry);
  form.append("IsPOI", POA.IsPOI); //false

  return await MultipartPostAfterLogin(API_DOCUMENT_UPLOAD, form);
};
