const initialState = {
  loading: false,
  error: "",
  isExecutiveApiCall: true,
  isExecutiveDocApiCall: true,
  updatestate: false,
  id: "",
  isRejected: false,
  isnewData: false,
  otherPositionId: null,
  positionIndex: null,
  citizenshipCountryId: null,
  citizenshipCountryIndex: null,
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: null,
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  countryId: null,
  countryIndex: null,
  postalCode: "",
  isUscitizen: "zero",
  isPoliticallyExposed: "zero",
  politicallyExposedCategoryId: null,
  PoliticallyExposedIndex: null,
  PoliticallyExposedText: "",
  isExecutive: "zero",
  createdBy: "",
  createdDate: "",
  positionName: "",
  DocumentType: null,
  typeindex: null,
  DocumentNumber: "",
  IssuingCountryId: null,
  IssuingCountryIndex: null,
  DateOfIssue: "",
  DateOfExpiry: "",
  proofidentityfront: "",
  isLocalproofidentityfront: false,
  proofidentityback: "",
  isLocalproofidentityback: false,
  POA: "",
  isLocalPOA: false,
  DocumentName: null,
  isoFour: "",
  nameFour: "",
  warningMsg: [],
};

const ExeDirRepDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXECUTIVE_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_EXECUTIVE_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        updatestate: action.payload.updatestate,
        id: action.payload.id,
        otherPositionId: action.payload.otherPositionId,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        citizenshipCountryIndex: action.payload.citizenshipCountryIndex,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        city: action.payload.city,
        state: action.payload.state,
        countryId: action.payload.countryId,
        countryIndex: action.payload.countryIndex,
        postalCode: action.payload.postalCode,
        isUscitizen: action.payload.isUscitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        isExecutive: action.payload.isExecutive,
        createdBy: action.payload.createdBy,
        createdDate: action.payload.createdDate,
        error: "",
      };
    case "GET_EXECUTIVE_DETAILS_FAULURE":
      return {
        ...state,
        loading: false,
        businessName: "",
        error: action.payload,
      };
    case "FETCH_EXE_DIR_REP_DETAILS":
      return {
        ...state,
        loading: false,
        id: action.payload.id,
        isExecutiveApiCall: action.payload.isExecutiveApiCall,

        updatestate: action.payload.updatestate,
        businessName: action.payload.businessName,
        otherPositionId: action.payload.otherPositionId,
        positionIndex: action.payload.positionIndex,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        citizenshipCountryIndex: action.payload.citizenshipCountryIndex,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        city: action.payload.city,
        state: action.payload.state,
        countryId: action.payload.countryId,
        countryIndex: action.payload.countryIndex,
        postalCode: action.payload.postalCode,
        isUscitizen: action.payload.isUscitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        PoliticallyExposedIndex: action.payload.PoliticallyExposedIndex,
        PoliticallyExposedText: action.payload.PoliticallyExposedText,
        positionName: action.payload.positionName,
        createdBy: action.payload.createdBy,
      };

    case "EXE_REJECT_STATUS":
      return {
        ...state,
        isRejected: action.payload.isRejected,
        isnewData: action.payload.isnewData,
      };
    case "SAVE_EXECUTIVE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SAVE_EXECUTIVE_SUCCESS":
      return {
        ...state,
        loading: false,
      };
    case "SAVE_EXECUTIVE_FAULURE":
      return {
        ...state,
        loading: false,
      };

    case "EXE_DIR_REP_DOC":
      return {
        ...state,
        loading: false,
        isoFour: action.payload.isoFour,
        nameFour: action.payload.nameFour,
        DocumentType: action.payload.DocumentType,
        typeindex: action.payload.typeindex,
        DocumentNumber: action.payload.DocumentNumber,
        IssuingCountryId: action.payload.IssuingCountryId,
        IssuingCountryIndex: action.payload.IssuingCountryIndex,
        DateOfIssue: action.payload.DateOfIssue,
        DateOfExpiry: action.payload.DateOfExpiry,
        proofidentityfront: action.payload.proofidentityfront,
        isLocalproofidentityfront: action.payload.isLocalproofidentityfront,
        proofidentityback: action.payload.proofidentityback,
        isLocalproofidentityback: action.payload.isLocalproofidentityback,
        POA: action.payload.POA,
        isLocalPOA: action.payload.isLocalPOA,
        isExecutiveDocApiCall: action.payload.isExecutiveDocApiCall,
        DocumentName: action.payload.DocumentName,
      };
    case "EXE_INITIAL":
      return initialState;
    default:
      return state;
  }
};

export default ExeDirRepDetailsReducer;
