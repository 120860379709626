import { DOWNLOADFILEINDIVISUAL } from "../../../helper";
import { getAPIcallWithOption } from "Services/apiController";

export const DownloadIndivisualFileService = async (name, ID) => {
  return await getAPIcallWithOption(
    DOWNLOADFILEINDIVISUAL + ID + "&documentName=" + name,
    null,
    { responseType: "arraybuffer" }
  );
};
