const initialState = {
  loading: false,
  countrycode: "",
  otpRes: "",
  // otp1: "",
  // otp2: "",
  // otp3: "",
  // otp4: "",
  // otp5: "",
  // otp6: "",
  mobileNumber: "",
  isOTPVerified: false,
};
const otpReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_OTP":
      return {
        ...state,
        otp: action.payload.otp,
        mobileNumber: action.payload.mobileNumber,
        // countrycode:action.payload.countrycode
      };

    case "VERYFY_OTP_SUCCESS":
      return {
        ...state,
        loading: false,
        otpRes: action.payload,
        isOTPVerified: true,
      };
    case "VERYFY_OTP_FAILURE":
      return {
        ...state,
        loading: false,
        isOTPVerified: false,
        error: action.payload,
      };
    case "INIT_SIGNUP_VERIFYOTP":
      return initialState;
    default:
      return state;
  }
};

export default otpReducer;
