import {
  API_TRANSACTION,
  API_EXCEL_STATEMENT,
  API_GET_PDF_STATEMENT,
  GET_TRANSACTION_RECEIPT,
} from "../../../helper";
import {
  postAPIcall,
  postAPIcallWithResponseType,
} from "Services/apiController";

export const Transactions_api = async (
  value,
  individualId = "",
  isEmployee = false
) => {
  let data = {
    publicToken: value.curCode.split("@/@")[0],
    curCode: isEmployee ? value.curCode : value.curCode.split("@/@")[1],
    isBusiness: true,
    bDateRangeExists: true,
    startDate: value.startDate,
    endDate: value.endDate,
    numberOfTransactions: 0,
    isEmployee: isEmployee,
    individualId: individualId,
  };

  return await postAPIcall(API_TRANSACTION, data);
};

export const ExcelStatement_api = async (value, isEmployee = false) => {
  let data = {
    publicToken: value.curCode.split("@/@")[0],
    curCode: isEmployee ? value.curCode : value.curCode.split("@/@")[1],
    isBusiness: true,
    bDateRangeExists: true,
    startDate: value.startDate,
    endDate: value.endDate,
    numberOfTransactions: 0,
    isEmployee: isEmployee,
    individualId: value.EmpID,
  };
  return await postAPIcallWithResponseType(
    API_EXCEL_STATEMENT,
    data,
    "arraybuffer"
  );
};

export const GetPdfStatement_api = async (value, isEmployee = false) => {
  let data = {
    publicToken: value.curCode.split("@/@")[0],
    curCode: isEmployee ? value.curCode : value.curCode.split("@/@")[1],
    isBusiness: true,
    bDateRangeExists: true,
    startDate: value.startDate,
    endDate: value.endDate,
    numberOfTransactions: 0,
    isEmployee: isEmployee,
    individualId: value.EmpID,
  };
  return await postAPIcallWithResponseType(
    API_GET_PDF_STATEMENT,
    data,
    "arraybuffer"
  );
};
export const downloadBankTransactionPDF = async (res, curCode) => {
  let data = {
    wsid: res.wsid,
    itemId: res.itemId,
    curCode: curCode,
    txnDate: res.txnDate,
  };
  return await postAPIcallWithResponseType(
    GET_TRANSACTION_RECEIPT,
    data,
    "arraybuffer"
  );
};
