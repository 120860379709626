import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import {
  API_GET_LEGALBUSINESSDETAILS,
  API_GET_LEGALBUSINESSDETAILSID,
  API_INIT_LEGALBUSINESSDETAILS,
  API_LEGALBUSINESSDETAILS,
  API_UPDATE_LEGALBUSINESSDETAILS,
  getlocalstorage,
} from "../../../helper";
import { getAPIcall, postAPIcall } from "Services/apiController";

//`LegalBusiness Entity
export const getLegalBusinessEntity = async (
  businessInfoId = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID)
) => {
  return await getAPIcall(API_GET_LEGALBUSINESSDETAILSID + businessInfoId);
};
//`post Legal Business Entity
export const postLegalBusinessEntity = async (values) => {
  var payload = [
    {
      businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
      // businessInfoId: values.businessInfoId,
      addressTypeId: 1,
      countryId: values.registrationCountry,
      city: values.registrationCity,
      postalCode: values.registrationPostalCode,
      addressLine1: values.registrationAddressLineOne,
      addressLine2: values.registrationAddressLineTwo,
      state: values.registrationState,
      isSameAsRegAddress: values.correspondencesameasregistration,
    },
    {
      // businessInfoId: values.businessInfoId,
      businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),

      addressTypeId: 2,
      countryId: values.correspondenceCountry,
      city: values.correspondenceCity,
      postalCode: values.correspondencePostalCode,
      addressLine1: values.correspondenceAddressLineOne,
      addressLine2: values.correspondenceAddressLineTwo,
      state: values.correspondenceState,
      isSameAsRegAddress: values.correspondencesameasregistration,
    },
    {
      // businessInfoId: values.businessInfoId,
      businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
      addressTypeId: 3,
      countryId: values.operatingCountry,
      city: values.operatingCity,
      postalCode: values.operatingPostalCode,
      addressLine1: values.operatingAddressLineOne,
      addressLine2: values.operatingAddressLineTwo,
      state: values.operatingState,
      isSameAsRegAddress: values.operatingsameasregistration,
    },
  ];
  let data1 = {
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    companyName: values.companyName,
    legalEntityForm: values.legalEntityForm,
    countryId: values.countryId,
    companyIncorportionDate:
      values.companyIncorportionDate &&
      Array.isArray(values.companyIncorportionDate)
        ? values.companyIncorportionDate[0]
        : values.companyIncorportionDate,
    companyRegNumber: values.companyRegNumber,
    businessSectorMasterId: values.businessSectorMasterId,
    linkToStateInformationSystemOrRegister:
      values.linkToStateInformationSystemOrRegister,
    dialInCode: values.dialInCode.toString(),
    emailAddress: values.emailAddress,
    mobileNumber: values.mobileNumber,
    website: values.website,
    contactPerson: values.contactPerson,
    taxes: values.taxes,
    businessAddresses: payload,
  };
  return values.updatestate === false
    ? await postAPIcall(API_LEGALBUSINESSDETAILS, data1)
    : await postAPIcall(API_UPDATE_LEGALBUSINESSDETAILS, data1);
};
//`getinitLegalBusinessEntity
export const getinitLegalBusinessEntity = async (
  businessInfoId = getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID)
) => {
  return await getAPIcall(API_INIT_LEGALBUSINESSDETAILS + businessInfoId);
};
//`getLegalEntityDetails
export const getLegalEntityDetails = async () => {
  return await getAPIcall(API_GET_LEGALBUSINESSDETAILS, {
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID)
      .replace('"', "")
      .replace('"', ""),
  });
};
