// import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";
import { connect } from "react-redux";
import * as Icon from "react-feather";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import logo from "../../../assets/img/logo/logo-sidebar.png";
import { history } from "../../../history";
import { logoutAPI } from "../../../Services/auth_API";
import { clearlocalstorage, clearSessionStorage } from "../../../helper";
import "./NavBar.css";
import { initSignup } from "redux/actions";
import { userApplicationStatus } from "helper/Constants/UserAppStatus/AppStatus";

const UserDropdown = (props) => {
	const logoutApi = async () => {
		try {
			const result = await logoutAPI();
			if (!(result.status === 200 && !result.data.error)) {
				clearlocalstorage();
				clearSessionStorage();
				await props.initSignup();
				// Broadcast Channel is Added for MultiTab Session Out Functionality
				const logoutChannel = new BroadcastChannel('vault-business-authentication');
				logoutChannel.postMessage("vb-logout")
				history.push("/");
				// window.location.reload();
			}
		} catch (err) {
			console.log(err);
		}
	};
	const finalApplicationStatus = props.profile !== null ? props.profile.finalApplicationStatus : null
	const isFirstTimeLogin = props.profile !== null && props.profile.isFirstTimeLogin && ((finalApplicationStatus === userApplicationStatus.approved || finalApplicationStatus === userApplicationStatus.suspended || finalApplicationStatus === userApplicationStatus.membershipExpired)) ?
		true : false
	return (
		<DropdownMenu right>
			{isFirstTimeLogin === true ? null :
				<DropdownItem
					tag="a"
					// href="/"
					onClick={() => {
						history.push("/change-password-doc");
					}}
				>
					<Icon.Lock size={14} className="mr-50" />
					<span className="align-middle">Change Password</span>
				</DropdownItem>
			}
			<DropdownItem
				tag="a"
				// href="/"
				onClick={logoutApi}
			>
				<Icon.Power size={14} className="mr-50" />
				<span className="align-middle">Log Out</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

class NavbarUser extends React.PureComponent {
	state = {
		navbarSearch: false,
		suggestions: [],
	};

	componentDidMount() {
	}

	handleNavbarSearch = () => {
		this.setState({
			navbarSearch: !this.state.navbarSearch,
		});
	};

	render() {
		return (
			<div className="w-100">
				<ul className="nav navbar-nav float-left">
					<li className="nav-item">
						<a
							className="nav-link dropdown-user-link"
							href="/complete-your-application"
						>
							<div>
								<img className="nav-link-no-shadow" src={logo} alt="" />
							</div>
						</a>
					</li>
				</ul>
				<ul className="nav navbar-nav navbar-nav-user float-right">
					<div>
						<img
							src={this.props.bellImg}
							alt="bell"
							width="23px"
							height="57px"
						/>
						<img
							src={this.props.lineImg}
							alt="line"
							width="33px"
							height="27px"
							border="5px solid #79879B"
						/>
					</div>

					<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
						<DropdownToggle tag="a" className="nav-link dropdown-user-link">
							<div className="user-nav d-sm-flex d-none">
								{/* <span className="user-name text-bold-600 text-dark vault-user-name"> */}
								<span className="user-name vault-user-name">
									{this.props.userName}
								</span>
								{/* <span className="user-email text-bold"> */}
								<span className="user-email vault-user-email">
									{this.props.userEmail}
								</span>
								{/* <span className="user-status">Available</span> */}
							</div>
							<span data-tour="user">
								<img
									src={this.props.userImg}
									className="round"
									height="40"
									width="40"
									alt="avatar"
								/>
							</span>
						</DropdownToggle>
						<UserDropdown {...this.props} />
					</UncontrolledDropdown>
				</ul>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		profile: state.dashboard.profile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		initSignup: () => {
			dispatch(initSignup());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);