import { applicationstatus } from "../../../Services/docSubmitServices";

const get_docSubmitApplstatus_Request = () => {
  return {
    type: "DOCSUBMIT_GET_APPLICATION_STATUS_REQUEST",
  };
};
export const setSoleEnterprise = (value) => {
  return {
    type: "SET_SOLE_ENTERPRISE",
    payload: value,
  };
};

const get_docSubmitApplstatus_Success = (emailStatus) => {
  return {
    type: "DOCSUBMIT_GET_APPLICATION_STATUS_SUCCESS",
    payload: emailStatus,
  };
};

const get_docSubmitApplstatus_Failure = (error) => {
  return {
    type: "DOCSUBMIT_GET_APPLICATION_STATUS_FAULURE",
    payload: error,
  };
};

export const ondatoCurrentstatus_old = (data) => {
  return {
    type: "On_Dato_Current",
    payload: data,
  };
};

export const initPagesreducers = () => {
  return (dispatch) => {
    dispatch({ type: "DESTROY_SESSION" });
  };
};

export const initComplateYourApplicationReducers = () => {
  return {
    type: "INIT_COMPLATE_YOUR_APPLICATION",
  };
};

export const fetchdocSubmitStatus = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(get_docSubmitApplstatus_Request());
      applicationstatus()
        .then((response) => {
          dispatch(get_docSubmitApplstatus_Success(response.data));
          resolve(true);
        })
        .catch((error) => {
          dispatch(get_docSubmitApplstatus_Failure(error));
          reject(error);
        });
    });
  };
};
