import { APPLICATION_STATUS, KEYS_FOR_LOCALSTORAGE } from "configs/pageConst";
import { logoutAPI } from "../../Services/auth_API";
import { clearlocalstorage, clearSessionStorage, getlocalstorage } from "../../helper";
import { clearHistory, history } from "../../history";

export const logoutApi = async () => {
  try {
    const result = await logoutAPI();
    if (!(result.status === 200 && !result.data.error)) {
      clearlocalstorage();
      clearSessionStorage();
      history.push("/");
    }
    clearHistory();
  } catch (err) {
    console.log(err);
  }
};

export const componentDidMountFunction = async (props) => {
  const isAuthenticated = getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN);
  const profileData = getlocalstorage(KEYS_FOR_LOCALSTORAGE.PROFILE);
  const parsedProfileData = profileData !== null ? JSON.parse(profileData) : null;
  const finalApplicationStatus = parsedProfileData !== null ?
    parsedProfileData.finalApplicationStatus : null
  if (isAuthenticated) {
    clearHistory();
    if (
      finalApplicationStatus === APPLICATION_STATUS.APPROVED ||
      finalApplicationStatus === APPLICATION_STATUS.SUSPENDED_MANUAL ||
      finalApplicationStatus === APPLICATION_STATUS.SUSPENDED_SUBSC_EXP ||
      finalApplicationStatus === APPLICATION_STATUS.ACTIVE ||
      finalApplicationStatus === APPLICATION_STATUS.SUSPENDED_AUTO
    ) {
      props.history.push("/walletmain");
    } else {
      props.history.push("/complete-your-application");
    }
  } else {
    clearlocalstorage();
    await props.initSignup();
    // await props.init_Profile();
  }
}
