export const dateInTwoDigitbefore10 = (date = new Date()) => {
  let day =
    date[0].getDate() < 10 ? "0" + date[0].getDate() : date[0].getDate();
  let month =
    date[0].getMonth() + 1 < 10
      ? "0" + (date[0].getMonth() + 1)
      : date[0].getMonth() + 1;
  return date[0].getFullYear() + "-" + month + "-" + day;
};

export const dateInTwoDigitbefore10Func = (date = new Date()) => {
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  return date.getFullYear() + "-" + month + "-" + day;
};
