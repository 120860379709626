const initialState = {
  loading: false,
  sameasRedAddr: false,
  error: "",
  directorList: [],
  UltimatebenificiaryOwnerList: [],
};

const businessOwnerDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
  case "DOCSUBMIT_BUSINESSOWNERDETAILS_LIST_REQUEST":
    return {
      ...state,
      loading: true,
    };
  case "DOCSUBMIT_BUSINESSOWNERDETAILS_LIST_SUCCESS":
    return {
      ...state,
      loading: false,
      directorList: action.payload.directorList,
      UltimatebenificiaryOwnerList:
          action.payload.UltimatebenificiaryOwnerList,
      error: "",
    };
  case "DOCSUBMIT_BUSINESSOWNERDETAILS_LIST_FAILURE":
    return {
      ...state,
      sameasRedAddr: false,
      loading: false,
      directorList: [],
      UltimatebenificiaryOwnerList: [],
      error: action.payload,
    };
    // case "FETCH_DOCSUBMIT_ADDRESS_OPERATIONAL":
    //   return {
    //     ...state,
    //     loading: false,
    //     directorList: action.payload.directorList,
    //     UltimatebenificiaryOwnerList:
    //       action.payload.UltimatebenificiaryOwnerList,
    //   };
  default:
    return state;
  }
};

export default businessOwnerDetailsReducer;
