// var CryptoJS = require("crypto-js");

export const encryptData = (data) => {
  return data
  // if(process.env.REACT_APP_ENV !== "PROD") {
  //   return data;
  // }
  
  // const salt = process.env.REACT_APP_SALT;
  // return CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
};

export const decryptData = (ciphertext) => {
  return ciphertext;
  // if(process.env.REACT_APP_ENV !== "PROD") {
    //   return ciphertext;
    // }
    
  // const salt = process.env.REACT_APP_SALT
  //  try {
  //    let res = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  //    console.log("res",res)
  //   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //  } catch (err) {
  //     return null;
  //   }
  };
  
/**
 * 
 * @param {*} Key 
 * @param {*} CipherText 
 * @returns String
 */
// export async function decryptCipherText(Key, CipherText) {
//   let decryptedString = '';
//   try {
//       const encryptedBytes = Uint8Array.from(atob(CipherText), c => c.charCodeAt(0));

//       const keyBytes = Uint8Array.from(atob(Key), c => c.charCodeAt(0));
//       const iv = new Uint8Array(16);

//       const key = await crypto.subtle.importKey(
//           'raw',
//           keyBytes,
//           { name: 'AES-CBC' },
//           false,
//           ['decrypt']
//       );

//       const decryptedBytes = await crypto.subtle.decrypt(
//           { name: 'AES-CBC', iv },
//           key,
//           encryptedBytes
//       );

//       decryptedString = new TextDecoder().decode(decryptedBytes);
//   } catch (error) {
//       console.error('An error occurred: ', error.message);
//   }

//   return decryptedString;
// }

/**
 * 
 * @param {*} key 
 * @param {*} cipherText 
 * @returns Encrypted form of text
 */
export async function encryptCipherText(key, cipherText) {
  let encryptCipherText = '';
  try {
      // Decode the base64 encoded key
      const keyBytes = Uint8Array.from(atob(key), c => c.charCodeAt(0));

      // Create an AES key from the raw bytes
      const cryptoKey = await crypto.subtle.importKey(
          'raw',
          keyBytes,
          { name: 'AES-CBC' },
          false,
          ['encrypt']
      );

      // Initialize an empty IV (all zeros)
      const iv = new Uint8Array(16);

      // Convert the plain text to an ArrayBuffer
      const encoder = new TextEncoder();
      const plainTextBytes = encoder.encode(cipherText);

      // Encrypt the plain text
      const encryptedBytes = await crypto.subtle.encrypt(
          { name: 'AES-CBC', iv: iv },
          cryptoKey,
          plainTextBytes
      );

      // Convert the encrypted bytes to a base64 string
      const encryptedArray = new Uint8Array(encryptedBytes);
      encryptCipherText = btoa(String.fromCharCode(...encryptedArray));
  } catch (ex) {
      console.error("An error occurred: " + ex.message);
  }
  return encryptCipherText;
}


