export const EmpCardsettingsVar = {
  APHC: {
    name: "Physical Card",
    value: "APHC",
    label: "Activate Physical Card",
    subtext: "Once activated, your card will be ready to use",
    key: 102,
  },
  FRZC: {
    name: "Freeze card",
    value: "FRZC",
    label: "Freeze card",
    subtext: "Temporarily suspends the use of card",
    key: 103,
  },
  DMGP: {
    name: "Magstripe Payments",
    value: "DMGP",
    label: "Disable Magstripe Payments",
    subtext: "Disable Payments that use the card's magnetic stripe",
    key: 104,
  },
  DATMW: {
    name: "ATM Withdrawals",
    value: "DATMW",
    label: "Disable ATM Withdrawals",
    subtext: "Disable cash withdrawals from ATM",
    key: 105,
  },
  DECOM: {
    name: "eCommerce",
    value: "DECOM",
    label: "Disable eCommerce",
    subtext: "Disable card usage for eCommerce transactions",
    key: 106,
  },
  DCOPY: {
    name: "Contactless Payments",
    value: "DCOPY",
    label: "Disable Contactless Payments",
    subtext: "Instantly disable contactless card payments",
    key: 107,
  },
  DPOSP: {
    name: "POS Payments",
    value: "DPOSP",
    label: "Disable POS Payments",
    subtext: "Disable Point of Sale payments",
    key: 116,
  },
};

export const groupNumber = {
  CGRL: {
    name: "Card Group & Limit",
    value: "CGRL",
    key: 5001,
    SettingName: "Card Group & Limit",
    ModalHeading: `Update Card Group & Limit`,
  },
  USERD: {
    name: "User Details",
    value: "USERD",
    key: 5002,
    SettingName: "User Details",
    ModalHeading: `Edit User Details`,
  },
};

export const virtualCardpara = {
  label: "Generate Virtual Card Only?",
  optionCount: 2,
  options: [
    { key: "Yes", value: true },
    { key: "No", value: false },
  ],
};
