const initialState = {
  loading: false,
  error: "",
  Firstname: "",
  LastName: "",
  DateOfBirth: "",
  Email: "",
  MobNo: "",
  PercentOfOwnership: "",
};

const addDirectorReducer = (state = initialState, action) => {
  switch (action.type) {
  // case "GET_DOCSUBMIT_ADDRESS_REGISTERD_REQUEST":
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // case "GET_DOCSUBMIT_ADDRESS_REGISTERD_SUCCESS":
  //   return {
  //     ...state,
  //     loading: false,
  //     Firstname: action.payload.Firstname,
  //     LastName: action.payload.LastName,
  //     DateOfBirth: action.payload.DateOfBirth,
  //     Email: action.payload.Email,
  //     MobNo: action.payload.MobNo,
  //     PercentOfOwnership: action.payload.PercentOfOwnership,
  //     error: "",
  //   };
  // case "GET_DOCSUBMIT_ADDRESS_REGISTERD_FAILURE":
  //   return {
  //     ...state,
  //     loading: false,
  //     Firstname: "",
  //     LastName: "",
  //     DateOfBirth: "",
  //     Email: "",
  //     MobNo: "",
  //     PercentOfOwnership: "",
  //     error: action.payload,
  //   };
  case "ADD_DOCSUBMIT_DIRECTOR":
    return {
      ...state,
      loading: false,
      Firstname: action.payload.Firstname,
      LastName: action.payload.LastName,
      DateOfBirth: action.payload.DateOfBirth,
      Email: action.payload.Email,
      MobNo: action.payload.MobNo,
      PercentOfOwnership: action.payload.PercentOfOwnership,
    };
  default:
    return state;
  }
};

export default addDirectorReducer;
