import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import {
  API_ADDMULTIPLECURRENCY,
  API_ADD_CARD,
  API_EDITCURRENCYCARD,
  API_REMOVEMULTIPLECURRENCY,
  API_REMOVE_CARD,
  getlocalstorage,
} from "../../../helper";
import { postAPIcall } from "Services/apiController";

export const AddCurrencyCard = async (data) => {
  return await postAPIcall(API_ADD_CARD, data);
};

export const removeCurrencyCard = async (data) => {
  return await postAPIcall(API_REMOVE_CARD, data);
};
/**
 *
 * @param {*} value
 * @returns Remove mutiple currency API service
 */
export const removeMultipleCurrency = async (value) => {
  value = {
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    curCode: value.currencyCode,
    publicToken: value.publicToken,
    isDefaultCurrency: false,
    currencyAccountName: value.currencyAccountName,
    isBusiness: true,
  };
  return await postAPIcall(API_REMOVEMULTIPLECURRENCY, value);
};
/**
 *
 * @param {*} value
 * @returns Add mutiple currency API service
 */
export const addMultipleCurrency = async (value) => {
  value = {
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    isBusiness: true,
    curCode: value.curCode,
    publicToken: value.publicToken,
    isDefaultCurrency: value.isDefaultCurrency,
    currencyAccountName: value.currencyAccountName,
  };
  return await postAPIcall(API_ADDMULTIPLECURRENCY, value);
};
/**
 * // Edit currency card parameters service
 */
export const editCurrencyCard = async (value) => {
  value = {
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
    curCode: value.curCode,
    publicToken: value.publicToken,
    isDefaultCurrency: value.isDefaultCurrency,
    currencyAccountName: value.currencyAccountName,
    isBusiness: true,
  };
  return await postAPIcall(API_EDITCURRENCYCARD, value);
};
