import { sendOtp } from "../../../Services/verifyServices";
export const initMobleNo = () => {
  return {
    type: "INIT_SIGNUP_MOBILENO",
  };
};
export const addMobileNo = (data) => {
  return {
    type: "ADD_MOB_NO",
    payload: data,
  };
};

export const sendOtpSuccess = (data) => {
  return {
    type: "SEND_OTP_SUCCESS",
    payload: data,
  };
};

export const sendOtpFailure = (error) => {
  return {
    type: "SEND_OTP_FAILURE",
    payload: error,
  };
};

export const addMobno = (userObj) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(addMobileNo(userObj));
      sendOtp(
        userObj.preRegistrationId,
        userObj.countrycode,
        userObj.mobileNumber
      )
        .then((response) => {
          dispatch(sendOtpSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(sendOtpFailure(error));
          reject(error);
        });
    });
  };
};
