const initialState = {
  loading: false,
  error: "",
  mothlyVolumeOfTransfer: "",
  webLink: "",
  socialLink: "",
};

const BusinessInfoAddReducer = (state = initialState, action) => {
  switch (action.type) {
  case "SET_DOCSUBMIT_BUSINESS_ADDINFO_REQUEST":
    return {
      ...state,
      loading: true,
    };
  case "SET_DOCSUBMIT_BUSINESS_ADDINFO_SUCCESS":
    return {
      ...state,
      loading: false,
      mothlyVolumeOfTransfer: action.payload.mothlyVolumeOfTransfer,
      webLink: action.payload.webLink,
      socialLink: action.payload.socialLink,
      error: "",
    };
  case "SET_DOCSUBMIT_BUSINESS_ADDINFO_FAILURE":
    return {
      ...state,
      loading: false,
      mothlyVolumeOfTransfer: "",
      webLink: "",
      socialLink: "",
      error: action.payload,
    };

  default:
    return state;
  }
};

export default BusinessInfoAddReducer;
