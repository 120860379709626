export const SignupSubLine = {
  LoginSubLine: "Please enter your email address and password.",
  signUp_emailSubline:
    "Please enter your email address and optional referral code.",
  signUP_MobleNumber: "Please enter your mobile number.",
  signup_Business_details:
    "Please enter the company name and registration number as well as incorporation country and business activity.",
  signUP_personalDetails:
    "Please enter your personal details, residential address, US - related and PEP status.",
  signup_GDPR: "Please mark your privacy consent in relation to options below.",
  signup_setpassword: "Please enter and confirm your password.",
  signup_completereg1:
    "Initial registration of your business has been completed.",
  signup_completereg2:
    "We will ask you for more corporate details to complete the application.",
};

export const docSub_texts = {
  pdetails_ver:
    "Please enter personal details for identity verification purposes.",
  pdetails_idver:
    "Please use your mobile phone to submit your Passport or National ID.",
  legal_entity1: "Please enter the details of the legal entity",
  legal_entity2: "Please enter legal entity address details.",
  shp_doc: "Please upload shareholder documents.",
  ubo_details: "Please enter details for the Ultimate Beneficial Owner",
  ubo_doc: "Please upload documents for Ultimate Beneficial Owner.",
  exe_details: "Please enter details for the legal entity role.",
  exe_doc:
    "Please enter details for the legal entity role documents(s) and upload them.",
  buss_info: "Please enter a description of the business.",
  buss_addInfo: "Please enter additional information about the business.",
  buss_info_doc: "Please upload documents of the legal entity.",
};
