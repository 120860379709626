export const listofFullMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const listofShortMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const NoOptionMsg = {
  country: "No country found!",
  currency: "No currency found",
  dialInCode: "No country found",
  limitGroup: "No limit group found",
  mccGroup: "No MCC group found",
  Card: "Please select valid currency",
  emp: "Please select valid employee",
  reason: "Please select valid reason",
  purpuse: "No purpose found",
  position: "No position data found",
  political: "No political data found",
  documenttype: "No document type found!",
  legalEntityform: "Please select valid legal entity form",
  purposeCurrentAccount: "No purpose found",
};

export const autoInputData = {
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: "off",
};

export const FormAutoComplete = {
  autoComplete: "off",
};

export const ModalTextData = {
  rusure: "Are you sure, you want to",
};
