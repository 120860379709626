import axios from 'axios';
import { KEYS_FOR_LOCALSTORAGE } from 'configs/pageConst';
import { getlocalstorage } from 'helper';

export const postAPIcall = async (url, data = {}, params = {}) => {
  return await axios.post(url, data, {
    params: params,
    headers: {
      'content-type': 'application/json', // override instance defaults
      Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
    },
  });
};

export const postAPIcallWithResponseType = async (url, data = {}, resType = '') => {
  return await axios.post(url, data, {
    responseType: resType,
    headers: {
      'content-type': 'application/json', // override instance defaults
      Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
    },
  });
};

export const postAPIcallWithOutAuthorization = async (url, data = {}) => {
  return await axios.post(url, data, {
    headers: {
      'content-type': 'application/json', // override instance defaults
    },
  });
};

export const getAPIcall = async (url, params = null) => {
  return params
    ? await axios.get(url, {
        params: params,
        headers: {
          'content-type': 'application/json', // override instance defaults
          Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
        },
      })
    : await axios.get(url, {
        headers: {
          'content-type': 'application/json', // override instance defaults
          Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
        },
      });
};

export const getAPIcallWithoutAuthorization = async (url, params = null) => {
  return params
    ? await axios.get(url, {
        params: params,
        headers: {
          'content-type': 'application/json', // override instance defaults
        },
      })
    : await axios.get(url, {
        headers: {
          'content-type': 'application/json', // override instance defaults
        },
      });
};

export const getAPIcallWithOption = async (url, params = null, option) => {
  return params
    ? await axios.get(url, option, {
        params: params,
        headers: {
          'content-type': 'application/json', // override instance defaults
          Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
        },
      })
    : await axios.get(url, {
        headers: {
          'content-type': 'application/json', // override instance defaults
          Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
        },
      });
};

export const deleteAPIcall = async (url, data = {}) => {
  return await axios.delete(url, {
    data: data,
    headers: {
      'content-type': 'application/json', // override instance defaults
      Authorization: `Bearer ${getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)}`,
    },
  });
};
