const initialState = {
  loading: false,
  sendemaillinkLoading: false,
  isEmailVerified: false,
  isEmailLinkSend: false,
  countries: [],
  error: "",
};

const emailVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEND_EMAIL_VERYFY_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SEND_EMAIL_VERYFY_SUCCESS":
      return {
        ...state,
        loading: false,
        isEmailVerified: action.payload,
        error: "",
      };
    case "SEND_EMAIL_VERYFY_FAILURE":
      return {
        ...state,
        loading: false,
        isEmailVerified: "",
        error: action.payload,
      };
    case "SAVE_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };

    case "SEND_EMAIL_LINK_REQUEST":
      return {
        ...state,
        sendemaillinkLoading: true,
      };
    case "SEND_EMAIL_LINK_SUCCESS":
      return {
        ...state,
        sendemaillinkLoading: false,
        isEmailLinkSend: action.payload,
        error: "",
      };
    case "SEND_EMAIL_LINK_FAILURE":
      return {
        ...state,
        sendemaillinkLoading: false,
        isEmailLinkSend: "",
        error: action.payload,
      };
    case "INIT_SIGNUP_EMAIL_VERIFICATION":
      return initialState;
    default:
      return state;
  }
};
export default emailVerificationReducer;
