import { verifyOtp } from "../../../Services/verifyServices";

export const initOtp = () => {
  return {
    type: "INIT_SIGNUP_VERIFYOTP",
  };
};
export const addOtpRequest = (data) => {
  return {
    type: "ADD_OTP",
    payload: data,
  };
};

export const VeryFyOtpSuccess = (data) => {
  return {
    type: "VERYFY_OTP_SUCCESS",
    payload: data,
  };
};

export const VeryFyOtpFailure = (error) => {
  return {
    type: "VERYFY_OTP_FAILURE",
    payload: error,
  };
};

export const addOtp = (otpObj) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(addOtpRequest(otpObj));
      // let mobOtp = {
      //   otp:
      //     otpObj.otp1 +
      //     otpObj.otp2 +
      //     otpObj.otp3 +
      //     otpObj.otp4 +
      //     otpObj.otp5 +
      //     otpObj.otp6,
      // };
      verifyOtp(
        otpObj.mobileNumber,
        otpObj.otp,
        otpObj.requestId,
        otpObj.preRegistrationId
      )
        .then((response) => {
          dispatch(VeryFyOtpSuccess(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(VeryFyOtpFailure(error));
          reject(error);
        });
    });
  };
};
