// import { withFormik, connect, getIn } from "formik";
import React from "react";
// import { render } from "react-dom";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

export const isFocusableInput = (wtf) =>
  !!(wtf && typeof wtf.focus === "function");

export const getAllInputs = () => {
  if (typeof document === "undefined") {
    return [];
  }
  return Array.prototype.slice
    .call(document.forms)
    .reduce(
      (accumulator, form) =>
        accumulator.concat(
          Array.prototype.slice.call(form).filter(isFocusableInput)
        ),
      []
    );
};

export const getFormInputs = (name) => () => {
  if (typeof document === "undefined") {
    return [];
  }
  // $FlowFixMe
  const form = document.forms[name];

  return ((form && form.length
    ? Array.prototype.slice.call(form).filter(isFocusableInput)
    : [])); // cast cheat to get from HTMLFormElement children to FocusableInput
};

export class ScrollAndFocus extends React.Component {
  render() {
    return (
      <ScrollIntoViewIfNeeded {...this.props}>
        <div ref={(e) => (this.node = e)} tabIndex="-1">
          {this.props.children}
        </div>
      </ScrollIntoViewIfNeeded>
    );
  }
}
