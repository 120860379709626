import {
  DocumentUpload,
  ShareHolderDetailCompanyService,
} from "../../../Services/docSubmitServices";
// const moment = require("moment");

const fetch_ShareHolderDetail_Company = (payload) => {
  return {
    type: "FETCH_SHAREHOLDER_DETAIL_COMPANY",
    payload,
  };
};

const shareHolderDetail_Company_Request = () => {
  return {
    type: "SHAREHOLDER_DETAIL_COMPANY_REQUEST",
  };
};

const shareHolderDetail_Company_Success = (shareholderData) => {
  return {
    type: "SHAREHOLDER_DETAIL_COMPANY_SUCCESS",
    payload: shareholderData,
  };
};

const shareHolderDetail_Company_Failure = (error) => {
  return {
    type: "SHAREHOLDER_DETAIL_COMPANY_FAILURE",
    payload: error,
  };
};
const documentdata_Success = (data) => {
  return {
    type: "DOCUMENT_UPLOAD_SUCCESS",
    payload: data,
  };
};
const documentdata_Failure = (error) => {
  return {
    type: "DOCUMENT_UPLOAD_FAILURE",
    payload: error,
  };
};

export const init_shareholderCompany = () => {
  return {
    type: "INIT_SHAREHOLDER_COMPANY",
  };
};

export const setShaholderCompanyReject = (isRejected, addnewData) => {
  let payload = {
    isRejected: isRejected,
    addnewData: addnewData,
  };
  return {
    type: "SHAREHOLDER_COMPANY_REJECT",
    payload,
  };
};

export const ShareHolderDetailCompanyAction = (data) => {
  return (dispatch) => {
    dispatch(fetch_ShareHolderDetail_Company(data));
    return new Promise(function (resolve, reject) {
      dispatch(shareHolderDetail_Company_Request());
      ShareHolderDetailCompanyService(data)
        .then((response) => {
          dispatch(shareHolderDetail_Company_Success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(shareHolderDetail_Company_Failure(error));
          reject(error);
        });
    });
  };
};

export const uploaddocsharecompany = (data) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      DocumentUpload(data)
        .then((response) => {
          if (response?.data) {
            dispatch(documentdata_Success(response?.data));
            resolve(response?.data);
          } else {
            dispatch(documentdata_Failure("error"));
            resolve(false);
          }
        })
        .catch((error) => {
          console.log("catch", error)
          dispatch(documentdata_Failure(error));
          reject(false);
        });
    });
  };
};
