// ** Servide Import
import { getAutoVBToVBBeneficiaryAPI } from '../../../Services';

export const AutoAdd_VToVAddBeneficiaryFormTable = (payload) => {
  return {
    type: 'AUTO_ADD_VTOV_ADDBENEFICIARY_FROM_TABLE_ACTION',
    payload,
  };
};

export const AutoAdd_VToVAddBeneficiary = (payload) => {
  return {
    type: 'AUTOADD_VTOV_ADDBENEFICIARY_ACTION',
    payload,
  };
};

export const UpdateAutoPayment_VToV = (payload) => {
  return {
    type: 'UPDATE_AUTOPAYMENT_VToV',
    payload,
  };
};

export const AutoPaymentTransactionList = (payload) => {
  return {
    type: 'AUTO_PAYMENT_TRANSACTION_LIST',
    payload,
  };
};

// export const AutoPaymentTransactionFaieldList = (payload) => {
//   return {
//     type: "AUTO_PAYMENT_TRANSACTION_FAILED_LIST",
//     payload,
//   };
// };

export const init_autoPayment = () => {
  return {
    type: 'INIT_AUTOPAYMENT',
  };
};

export const get_AutoVBToVBBeneficiary_Request = () => {
  return {
    type: 'GET_AUTOVBTOVBBENEFICIARY_REQUEST',
  };
};

export const get_AutoVBToVBBeneficiary_Success = (data) => {
  return {
    type: 'GET_AUTOVBTOVBBENEFICIARY_SUCCESS',
    payload: data,
  };
};

export const get_AutoVBToVBBeneficiary_Failure = (error) => {
  return {
    type: 'GET_AUTOVBTOVBBENEFICIARY_FAILURE',
    payload: error,
  };
};

export const getAutoVBTOVBBeneficiary = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(get_AutoVBToVBBeneficiary_Request());
      getAutoVBToVBBeneficiaryAPI()
        .then((response) => {
          dispatch(get_AutoVBToVBBeneficiary_Success(response.data === undefined ? '' : response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(get_AutoVBToVBBeneficiary_Failure(error));
          reject(error);
        });
    });
  };
};
