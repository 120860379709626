import * as Icon from 'react-feather';
import Money from '../../src/assets/img/svg/money.svg';
import ownerDetailsActive from '../assets/img/sidebar/OwnerDetails-active.svg';
import ownerDetailsInactive from '../assets/img/sidebar/OwnerDetails-inactive.svg';
import autoPaymentActive from '../assets/img/sidebar/auto-payment-active.svg';
import autoPaymentInactive from '../assets/img/sidebar/auto-payment-inactive.svg';
import bulkpaymentActive from '../assets/img/sidebar/bulkPayment-Active.svg';
import bulkpaymentInactive from '../assets/img/sidebar/bulkPayment-inactive.svg';
import cardsActive from '../assets/img/sidebar/cards-active.svg';
import cardsInactive from '../assets/img/sidebar/cards-inactive.svg';
import empTransActive from '../assets/img/sidebar/emp-trans-active.svg';
import empTransInactive from '../assets/img/sidebar/emp-trans-inactive.svg';
import paymentActive from '../assets/img/sidebar/payments-active.svg';
import paymentInactive from '../assets/img/sidebar/payments-inactive.svg';
import walletActive from '../assets/img/sidebar/wallet-active.svg';
import walletInactive from '../assets/img/sidebar/wallet-inactive.svg';
import wallettransActive from '../assets/img/sidebar/wallet-trans-active.svg';
import wallettransInactive from '../assets/img/sidebar/wallet-trans-inactive.svg';

const navigationConfig = [
  {
    id: 'main',
    title: 'Wallets',
    type: 'item',
    activeIcon: walletActive,
    inactiveIcon: walletInactive,
    icon: <Icon.Briefcase size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/walletmain',
    parentOf: ['/dashboard-exchange', '/loadfund'],
  },
  {
    id: 'cards',
    title: 'Employee Cards',
    type: 'item',
    activeIcon: cardsActive,
    inactiveIcon: cardsInactive,
    icon: <Icon.CreditCard size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/employee-cards',
    parentOf: ['/addnewemployeecard', '/employee-card-setting', '/bulkcard'],
  },
  {
    id: 'payments',
    title: 'Payments',
    type: 'item',
    activeIcon: paymentActive,
    inactiveIcon: paymentInactive,
    icon: <Icon.Send size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/transfer',
  },
  {
    id: 'autopayment',
    title: 'Auto Payments',
    type: 'item',
    activeIcon: autoPaymentActive,
    inactiveIcon: autoPaymentInactive,
    icon: <Icon.Send size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/auto-payment',
  },
  {
    id: 'bulkpayment',
    title: 'Bulk Payments',
    type: 'item',
    activeIcon: bulkpaymentActive,
    inactiveIcon: bulkpaymentInactive,
    icon: <img src={Money} alt='Money' style={{ marginRight: '10px' }} />,
    permissions: ['admin', 'editor'],
    navLink: '/bulkpayments-filechooser',
  },
  {
    id: 'wallettransactions',
    title: 'Wallet Transactions',
    type: 'item',
    activeIcon: wallettransActive,
    inactiveIcon: wallettransInactive,
    icon: <Icon.Repeat size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/transactions',
  },
  {
    id: 'emptransactions',
    title: 'Employee Transactions',
    type: 'item',
    activeIcon: empTransActive,
    inactiveIcon: empTransInactive,
    icon: <Icon.Repeat size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/employee-transactions',
  },
  {
    id: 'owner',
    title: 'Owner Details',
    type: 'collapse',
    activeIcon: ownerDetailsActive,
    inactiveIcon: ownerDetailsInactive,
    icon: <Icon.Users size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/owner',
    childNavlink: [
      '/owner-personal-details',
      '/owner-legalentity-details',
      '/owner-shareholder-details',
      '/owner-ubo-details',
      '/owner-executive-details',
      '/owner-executive-details',
      '/owner-businessinfo-details',
    ],
    children: [
      {
        id: 'personaldetails',
        title: 'Personal Details',
        type: 'item',
        permissions: ['admin', 'editor'],
        navLink: '/owner-personal-details',
      },
      {
        id: 'legalentitydetails',
        title: 'Legal Entity Details',
        type: 'item',
        permissions: ['admin', 'editor'],
        navLink: '/owner-legalentity-details',
      },
      {
        id: 'shareholderdetails',
        title: 'Shareholder Details',
        type: 'item',
        permissions: ['admin', 'editor'],
        navLink: '/owner-shareholder-details',
      },
      {
        id: 'ubodetails',
        title: 'UBO Details',
        type: 'item',
        permissions: ['admin', 'editor'],
        navLink: '/owner-ubo-details',
      },
      {
        id: 'executivedetails',
        title: 'Exe/Dir/Rep Details',
        type: 'item',
        permissions: ['admin', 'editor'],
        navLink: '/owner-executive-details',
      },

      {
        id: 'businessinfodetails',
        title: 'Business Info & Docs',
        type: 'item',
        permissions: ['admin', 'editor'],
        navLink: '/owner-businessinfo-details',
      },
    ],
  },
];

export default navigationConfig;
