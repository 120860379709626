import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'

// import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import { persistor } from "./redux/storeConfig/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { AuthProvider } from "./utility/providers/authProvider";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
// ** i18n
import './configs/i18n'

const LazyApp = lazy(() => import("./App"));
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <AuthProvider>
            <LazyApp />
          </AuthProvider>
        </Layout>
      </Suspense>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
