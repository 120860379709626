import { getExecutivebyId, saveExecutive } from "../../../Services";

export const fetch_ExeDirRepDetails = (payload) => {
  return {
    type: "FETCH_EXE_DIR_REP_DETAILS",
    payload,
  };
};

export const init_Executive = () => {
  return {
    type: "EXE_INITIAL",
  };
};

export const setisrejeced = (isRejected, isnewData) => {
  let payload = {
    isRejected: isRejected,
    isnewData: isnewData,
  };
  return {
    type: "EXE_REJECT_STATUS",
    payload,
  };
};

const saveExecutive_Request = () => {
  return {
    type: "SAVE_EXECUTIVE_REQUEST",
  };
};
const saveExecutive_Success = () => {
  return {
    type: "SAVE_EXECUTIVE_SUCCESS",
  };
};
const saveExecutive_Failure = (error) => {
  return {
    type: "SAVE_EXECUTIVE_FAULURE",
    payload: error,
  };
};
const getExecutive_Request = () => {
  return {
    type: "GET_EXECUTIVE_DETAILS_REQUEST",
  };
};

const getExecutive_Success = (data) => {
  return {
    type: "GET_EXECUTIVE_DETAILS_SUCCESS",
    payload: data,
  };
};

const getExecutive_Failure = (error) => {
  return {
    type: "GET_EXECUTIVE_DETAILS_FAULURE",
    payload: error,
  };
};

// export const initexecutive = () => {
//   return {
//     type: "EXE_INITIAL",
//   };
// };

export const fetch_ExeDirRepDocAction = (data) => {
  return {
    type: "EXE_DIR_REP_DOC",
    payload: data,
  };
};

export const executiveSave = (values) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(saveExecutive_Request());
      saveExecutive(values)
        .then((response) => {
          dispatch(saveExecutive_Success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(saveExecutive_Failure(error));
          reject(error);
        });
    });
  };
};

export const getexecutive = (id) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(getExecutive_Request());
      getExecutivebyId(id)
        .then((response) => {
          dispatch(getExecutive_Success(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(getExecutive_Failure(error));
          reject(error);
        });
    });
  };
};
