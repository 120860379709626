export const add_Emp_VirtualCard_Data = (payload, requestId) => {
  let data = {
    ...payload,
    requestId: requestId,
  };
  return {
    type: "EMP_SAVE_VIRTUAL_CARD_DATA",
    payload: data,
  };
};

export const emp_Virtul_reset = () => {
  return {
    type: "INIT_EMP_CARD",
  };
};
