import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import { voidsetlocalstorage } from "../../../helper";
import { businessDetails } from "../../../Services/registrationServices";

export const addSetPassword = (userObj) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(addSetPasswordData(userObj));
      dispatch(setPasswordRequest());

      businessDetails(userObj)
        .then((response) => {
          dispatch(setPasswordSuccess(response.data));
          voidsetlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID, response.data);
          resolve(response);
        })
        .catch((error) => {
          // const errorMsg = error.Message;
          dispatch(setPasswordFailure(error));
          reject(error);
        });
    });
  };
};

export const addSetPasswordData = (payload) => {
  return {
    type: "Add_SetPassword_Data",
    payload,
  };
};

export const setPasswordRequest = () => {
  return {
    type: "SetPassword_REQUEST",
  };
};

export const setPasswordSuccess = (data) => {
  return {
    type: "SetPassword_SUCCESS",
    payload: data,
  };
};

export const setPasswordFailure = (error) => {
  return {
    type: "SetPassword_FAILURE",
    payload: error,
  };
};

export const initSetpassword = () => {
  return {
    type: "INIT_SIGNUP_SETPASS",
  };
};
