import { API_USERAPPSTATUS, PROFILE_URL } from "../helper";
import { getAPIcall } from "./apiController";

export const profileAPI = async () => {
  return await getAPIcall(PROFILE_URL);
};
/**
 * Cheking user finalApplication status.
 * @returns
 */
export const finalAppstatus = async () => {
  return await getAPIcall(API_USERAPPSTATUS);
};
