import {
  API_ONBOARDING_BUSINESS,
  // DocumentTypeData,
  MultipartPostBeforeLogin,
} from "../helper";
// const moment = require("moment");
// const { POWA } = DocumentTypeData;

/**
 * APi of user SignUp
 * @param {*} data user registration data
 * @returns axios.post of API_ONBOARDING_BUSINESS
 */
export const businessDetails = async (data) => {
  const form = new FormData();

  form.append("PreRegistrationId", data.preRegistrationId);
  form.append("BusinessName", data.businessName);
  form.append("CountryId", data.countryId);
  form.append("CRN", data.crn);
  form.append("BusinessActivity", data.businessActivity);
  form.append(
    "IsProhibitedBussinessCategoryConfirmed",
    data.isProhibitedBussinessCategoryConfirmed
  );
  form.append("PositionId", data.positionId);
  form.append("FirstName", data.firstName);
  form.append("MiddleName", data.middleName);
  form.append("LastName", data.lastName);
  form.append("DateOfBirth", data.dateOfBirth);
  form.append("CitizenshipCountryId", data.citizenshipCountryId);
  form.append("PersonalCode", data.personalCode);
  form.append("AddressLine1", data.addressLine1);
  form.append("AddressLine2", data.addressLine2);
  form.append("City", data.city);
  form.append("State", data.State);
  form.append("AddressCountryId", data.addressCountryId);
  form.append("PostalCode", data.postalCode);
  form.append("IsUSCitizen", data.isUSCitizen === "one" ? true : false);
  form.append(
    "IsPoliticallyExposed",
    data.isPoliticallyExposed === "one" ? true : false
  );
  form.append(
    "PoliticallyExposedCategoryId",
    data.politicallyExposedCategoryId
  );
  form.append("PoliticallyExposedText", data.PoliticallyExposedText);
  form.append("IsMarketingMsgsAllowed", data.isMarketingMsgsAllowed);
  form.append("IsGroupMarketingMsgsAllowed", data.isGroupMarketingMsgsAllowed);
  form.append(
    "IsGroupMarketingCallsAllowed",
    data.isGroupMarketingCallsAllowed
  );
  form.append("EmailAddress", data.email);
  form.append("PhoneNo", data.mobileNumber);
  form.append("DialInCode", data.countrycode);
  form.append("Password", data.password);
  form.append("ConfirmPassword", data.confirmPassword);
  form.append("IsAttachment", data.IsAttachment);
  if (data.IsAttachment === true) {
    form.append("DocumentType", data.DocumentType);
    form.append("DocumentData", data.DocumentData);
    form.append("DocumentMasterId", data.DocumentMasterId);
  }

  return await MultipartPostBeforeLogin(API_ONBOARDING_BUSINESS, form);
};
