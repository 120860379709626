const initialState = {
  loading: true,
  error: "",
  businessName: "",
  countryId: null,
  countryIndex: null,
  crn: "",
  dateOfIncorporation: new Date(),
  dateOfBirth: new Date(),
  // businessTypeId: "",
  // businessSectorId: "",
  // businessDetailsStatus: "",
  businessActivity: "",
  isProhibitedBussinessCategoryConfirmed: false,
};

const businessDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Add_Bussiness_Data":
      return {
        ...state,
        loading: false,
        businessName: action.payload.businessName,
        countryId: action.payload.countryId,
        countryIndex: action.payload.countryIndex,
        crn: action.payload.crn,
        businessActivity: action.payload.businessActivity,
        isProhibitedBussinessCategoryConfirmed:
          action.payload.isProhibitedBussinessCategoryConfirmed,

        // dateOfIncorporation: action.payload.dateOfIncorporation,
        // businessTypeId: action.payload.businessTypeId,
        // businessSectorId: action.payload.businessSectorId,
      };
    case "INIT_SIGNUP_BUSINESS":
      return initialState;
    default:
      return state;
  }
};

export default businessDetailsReducer;
