const fetch_Pesrsonal_Details = (payload) => {
  return {
    type: "ADD_PERSONAL_DETAILS",
    payload,
  };
};
export const init_Pesrsonal_Details = () => {
  return {
    type: "INIT_PERSONAL_DETAILS",
  };
};
// const Pesrsonal_DetailsRequest = () => {
//   return {
//     type: "Pesrsonal_Details_REQUEST",
//   };
// };

// const Pesrsonal_DetailsSuccess = (AddrData) => {
//   return {
//     type: "Pesrsonal_Details_SUCCESS",
//     payload: AddrData,
//   };
// };
// const Pesrsonal_DetailsFailure = (error) => {
//   return {
//     type: "Pesrsonal_Details_FAILURE",
//     payload: error,
//   };
// };

export const ondatoCurrentstatus = (data) => {
  console.log("on_dato_Current_status action", data);
  return {
    type: "On_Dato_Current",
    payload: data,
  };
};

// const persona_details_documentdata_Success = (data) => {
//   return {
//     type: "PERSONAL_DETAILS_DOCUMENT_UPLOAD_SUCCESS",
//     payload: data,
//   };
// };
// const persona_details_documentdata_Failure = (error) => {
//   return {
//     type: "PERSONAL_DETAILS_DOCUMENT_UPLOAD_FAILURE",
//     payload: error,
//   };
// };
export const personalDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(fetch_Pesrsonal_Details(data));
    // return new Promise(function (resolve, reject) {
    //   dispatch(Pesrsonal_DetailsRequest());
    //   personalDetailsService(data)
    //     .then((response) => {
    //       dispatch(Pesrsonal_DetailsSuccess(response.data));
    //       resolve(response.data);
    //       // return response.data;
    //     })
    //     .catch((error) => {
    //       dispatch(Pesrsonal_DetailsFailure(error));
    //       reject(error);
    //       // return error;
    //     });
    // });
  };
};
