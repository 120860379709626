import { combineReducers } from "redux";
import auth from "./auth/";
import countrylistReducer from "./countryList";
import customizer from "./customizer/";
import dashboardReducer from "./dashboard";
import docSubmitReducer from "./docSubmit";
import navbar from "./navbar/Index";
import pagesReducer from "./pagesReducer";
import EmpVirtualCardreducer from "./EmpVirtualCardreducer/EmpVirtualCardreducer";

const initRootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  pages: pagesReducer,
  docsubmit: docSubmitReducer,
  dashboard: dashboardReducer,
  countrylist: countrylistReducer,
  empVirCard: EmpVirtualCardreducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") {
    state = undefined;
  }
  return initRootReducer(state, action);
};

export default rootReducer;
