import {
  getLegalBusinessEntity,
  postLegalBusinessEntity,
} from "../../../Services";

export const fetch_legalEntityBusinessDetails = (payload) => {
  return {
    type: "FETCH_LEGAL_ENTITY_BUSINESS_DETAILS",
    payload,
  };
};

export const initLegalEntity = () => {
  return {
    type: "INIT_LEGAL_ENTITY_BUSINESS_DETAILS",
  };
};

export const fetch_legalEntityBusinessAddress = (payload) => {
  // const payload = [
  //   {
  //     businessInfoId: data.businessInfoId,
  //     addressTypeId: 0,
  //     country: data.registrationCountry,
  //     city: data.registrationCity,
  //     postalCode: data.registrationPostalCode,
  //     addressLine1: data.registrationAddressLineOne,
  //     addressLine2: data.registrationAddressLineTwo,
  //     state: data.registrationState,
  //     isSameAsRegAddress: false,
  //   },

  //   {
  //     businessInfoId: data.businessInfoId,
  //     addressTypeId: 1,
  //     country: data.correspondenceCountry,
  //     city: data.correspondenceCity,
  //     postalCode: data.correspondencePostalCode,
  //     addressLine1: data.correspondenceAddressLineOne,
  //     addressLine2: data.correspondenceAddressLineTwo,
  //     state: data.correspondenceState,
  //     isSameAsRegAddress: data.correspondencesameasregistration,
  //   },

  //   {
  //     businessInfoId: data.businessInfoId,
  //     addressTypeId: 2,
  //     country: data.operatingCountry,
  //     city: data.operatingCity,
  //     postalCode: data.operatingPostalCode,
  //     addressLine1: data.operatingAddressLineOne,
  //     addressLine2: data.operatingAddressLineTwo,
  //     state: data.operatingState,
  //     isSameAsRegAddress: data.operatingsameasregistration,
  //   },
  // ];
  return {
    type: "FETCH_LEGAL_ENTITY_BUSINESS_ADDRESS",
    payload,
  };
};

const post_legalEntityBusinessDetails_Request = () => {
  return {
    type: "POST_LEGAL_ENTITY_BUSINESS_DETAILS_REQUEST",
  };
};

const post_legalEntityBusinessDetails_Success = (bdetailsData) => {
  return {
    type: "POST_LEGAL_ENTITY_BUSINESS_DETAILS_SUCCESS",
    payload: bdetailsData,
  };
};

const post_legalEntityBusinessDetails_Failure = (error) => {
  return {
    type: "POST_LEGAL_ENTITY_BUSINESS_DETAILS_FAILURE",
    payload: error,
  };
};

const get_legalEntityBusinessDetails_Request = () => {
  return {
    type: "GET_LEGAL_ENTITY_BUSINESS_DETAILS_REQUEST",
  };
};

const get_legalEntityBusinessDetails_Success = (bdetailsData) => {
  return {
    type: "GET_LEGAL_ENTITY_BUSINESS_DETAILS_SUCCESS",
    payload: bdetailsData,
  };
};

const get_legalEntityBusinessDetails_Failure = (error) => {
  return {
    type: "GET_LEGAL_ENTITY_BUSINESS_DETAILS_FAILURE",
    payload: error,
  };
};

export const postLegalEntityBusiness = (PageValue) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(post_legalEntityBusinessDetails_Request());
      postLegalBusinessEntity(PageValue)
        .then((response) => {
          dispatch(post_legalEntityBusinessDetails_Success(response.data));
          resolve(true);
        })
        .catch((error) => {
          dispatch(post_legalEntityBusinessDetails_Failure(error));
          reject(error);
        });
    });
  };
};

export const getLegalEntityBusiness = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(get_legalEntityBusinessDetails_Request());
      getLegalBusinessEntity()
        .then((response) => {
          dispatch(get_legalEntityBusinessDetails_Success(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(get_legalEntityBusinessDetails_Failure(error));
          reject(error);
        });
    });
  };
};
