import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { ArrowUp } from "react-feather";
import classnames from "classnames";
import { validateURL } from "../../../helper";

let url = "https://devere-group.com";

const Footer = (props) => {
  useEffect(()=>{
    let scrollButton = document.getElementById("scroll-btn")
    window.onscroll = () => {
      if (document.body.scrollTop > 160 || document.documentElement.scrollTop > 160) {
        scrollButton.style.display = "block";
      } else {
        scrollButton.style.display = "none";
      }
    }

    return () => {
      scrollButton.style.display = "block";
      window.onscroll = () => {}
    }
    
  },[])

  let footerTypeArr = ["sticky", "static", "hidden"];
  
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
    >
      <p className="mb-0 clearfix footerFontColor">
        <span className="float-md-right d-block d-md-inline-block mt-25">
          Copyright © {new Date().getFullYear()}
          <a
            href={validateURL(url) ? url : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            deVere Group,
          </a>
          All rights reserved
        </span>
      </p>
      {props.hideScrollToTop === false ? (
        <Button id="scroll-btn" 
          onClick={()=>{
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }} 
          color="primary" 
          className="btn-icon scroll-top">
          <ArrowUp size={15} />
        </Button>
      ) : null}
    </footer>
  );
};

export default Footer;
