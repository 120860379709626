import { decryptData, encryptData } from "../utility/EncDec";

/**
 * for save sessionStorage single value and return data
 * @param {*} name
 * @param {*} data
 * @returns data
 */
export const setSessionStorage = (name, data) => {
  let encData = encryptData(data)
  if (sessionStorage.getItem(name)) {
    sessionStorage.removeItem(name);
    sessionStorage.setItem(name, encData);
  } else {
    sessionStorage.setItem(name, encData);
  }
  return data;
};


/**
 * for get sessionStorage return data
 * @param {*} name
 * @returns data
 */
export const getSessionStorage = (name) => {
  let localData = sessionStorage.getItem(name);
  if (localData) {
    return decryptData(localData)
  } else {
    return null;
  }
};

/**
 * for remove sessionStorage return voind
 * @param {*} name
 * @returns data
 */
export const removeSessionStorage = (name) => {
  sessionStorage.removeItem(name);
};

/**
 * for clear sessionStorage return voind
 * @returns 
 */
export const clearSessionStorage = () => {
  return sessionStorage.clear();
};