const initialState = {
  loading: false,
  error: "",
  email: "",
  password: "",
  resLogin: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Login_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "Login_SUCCESS":
      return {
        ...state,
        loading: false,
        resLogin: action.payload,
        error: "",
      };
    case "Login_FAILURE":
      return {
        ...state,
        loading: false,
        // emailStatus: "",
        error: action.payload,
      };
    case "Login_Data_Add":
      return {
        ...state,
        loading: false,
        email: action.payload.email,
        password: action.payload.password,
      };
    case "INIT_LOGIN":
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
