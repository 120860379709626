export const proof_of_identity_front_rej_text =
  "Proof of Identity (Front Side) document rejected -";
export const proof_of_identity_back_rej_text =
  "Proof of Identity (Back Side) document rejected -";
export const proof_of_address_rej_text = "Proof of Address document rejected -";
export const POSF_rej_text =
  "Proof of Source of Funds/Wealth document rejected";
export const COIEXCROWM_Doc_Reject =
  "Certificate of Incorporation / Extract from Companies Register/Ownership/Management document rejected -";
export const AMLP_Doc_reject = "AML Policies document rejected";
export const BUSL_Doc_reject = "Business license document rejected";
export const BSPL_Doc_reject = "Business Plan document rejected";
export const BKST_Doc_reject = "Bank Statements document rejected";
export const FIAC_Doc_reject = "Financial Accounts document rejected";
export const OWST_Doc_reject = "Ownership Structure document rejected";
export const ROSH_Doc_reject = "Registry of Shareholders document rejected";
export const AOA_Doc_reject = "Articles of Association document rejected";
export const COI_Doc_reject = "Certificate of Incorporation document rejected";
