import { deleteAPIcall, getAPIcall, postAPIcall } from "Services/apiController";
import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import {
  API_ADD_AUTOPAYMENT_VToB,
  API_ADD_BENEFICIARY,
  API_ADD_V2VAUTOPAYMENT,
  API_AUTOPAYMENT_V2VBENEFICIARY_SEARCH,
  API_AUTO_V2B_DELETE,
  API_UPDATE_AUTOPAYMENT_VToB,
  API_UPDATE_V2VAUTOPAYMENT,
  API_V2VAUTOPAYMENTDELETE,
  API_V2VAUTOPAYMENTLIST,
  API_V2VAUTOPAYMENT_FAILEDLIST,
  API_V2VAUTOPAYMENT_TRANSACTION_LIST,
  GETBANK_AUTOPAYMENT_LIST,
  V2VAutopaymentManualRetry,
  VToVLISTING,
  getlocalstorage,
} from "../../../../helper";

export const getOnlyPlainDate = (value) => {
  let date = new Date(value);
  let month = date.getMonth() + 1;
  return `${date.getFullYear()}-${("0" + month).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

/**
 * This api use for beneficiary search
 * @param {*} value
 * @returns
 */
export const autoPaymentV2VBeneficiarySearch = async (value) => {
  return await getAPIcall(
    API_AUTOPAYMENT_V2VBENEFICIARY_SEARCH + "searchTerm=" + value
  );
};

/**
 * This API use for VB To VB Auto payment Get Beneficiary list
 * @returns
 */
export const getAutoVBToVBBeneficiaryAPI = async () => {
  return await getAPIcall(VToVLISTING);
};

/**
 * API of V2V autopayment List
 * @param {*} value
 * @returns axios.post API_V2VAUTOPAYMENTLIST
 */
export const V2VAUTOPAYMENTLIST_API = async () => {
  let data = {};
  return await postAPIcall(API_V2VAUTOPAYMENTLIST, data);
};
/**
 * API of addUpdateAutoPayment
 * @param {*} value
 * @returns axios.post API_ADD_V2VAUTOPAYMENT
 */
export const addUpdateAutoPayment = async (value) => {
  if (value.updateautopayment === false) {
    let data = {
      businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
      beneficiaryId: value?.beneficiaryId ?? null,
      payeeBusinessInfoId: value?.payeeBusinessInfoId ?? "",
      currency: value.currency,
      toWalletToken: value.ToWalletToken,
      fromWalletToken: value.publicToken,
      isMultiCurrency: !value.isMVC,
      amount: parseFloat(value.amount),
      notes: value.note,
      frequencyType: parseInt(value.frequencyType),
      processingDay: value.processingDay,
      startDate: getOnlyPlainDate(value.startDate),
      endDate: value.endDate === "" ? null : getOnlyPlainDate(value.endDate),
      autoPaymentV2VInstructionsId: "",
    };
    return await postAPIcall(API_ADD_V2VAUTOPAYMENT, data);
  } else if (value.updateautopayment === true) {
    let data = {
      businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
      beneficiaryId: value?.beneficiaryId ?? null,
      payeeBusinessInfoId: value?.payeeBusinessInfoId ?? "",
      currency: value.currency,
      toWalletToken: value.ToWalletToken,
      fromWalletToken: value.publicToken,
      isMultiCurrency: !value.isMVC,
      amount: parseFloat(value.amount),
      notes: value.note,
      frequencyType: parseInt(value.frequencyType),
      processingDay: value.processingDay,
      startDate: getOnlyPlainDate(value.startDate),
      endDate: value.endDate === "" ? null : getOnlyPlainDate(value.endDate),
      autoPaymentV2VInstructionsId: value.id,
    };
    return await postAPIcall(API_UPDATE_V2VAUTOPAYMENT, data);
  }
};
/**
 * API of add AutoPayment VToB
 * @param {*} value
 * @param {*} beneficiaryId
 * @returns  axios.post API_ADD_AUTOPAYMENT_VToB
 */
export const add_AutoPayment_VToB = async (value, beneficiaryId) => {
  if (value.updateautopayment === false) {
    let data = {
      amount: parseInt(value.amount),
      currency: value.currency,
      beneficiaryId: beneficiaryId,
      startDate: value.startDate,
      endDate: value.endDate === "" ? null : value.endDate,
      frequencyType: parseInt(value.frequencyType),
      processingDay: value.processingDay,
      // transferMode: value.transferMode,
      transferMode: value.PaymentType,

      reasonForTransfer: value.Reason,
      reference: value.AddReference,
      note: value.SpecifyReason,
      standingInstructionId: value.standingInstructionId,
      paymentChargeType: value.RadioSelect,
    };
    return await postAPIcall(API_ADD_AUTOPAYMENT_VToB, data);
  }
  if (value.updateautopayment === true) {
    let data = {
      amount: parseInt(value.amount),
      currency: value.currency,
      beneficiaryId: beneficiaryId,
      startDate: value.startDate,
      endDate: value.endDate === "" ? null : value.endDate,
      frequencyType: parseInt(value.frequencyType),
      processingDay: value.processingDay,
      transferMode: value.PaymentType,
      reasonForTransfer: value.Reason,
      reference: value.AddReference,
      note: value.SpecifyReason,
      standingInstructionId: value.standingInstructionId,
      paymentChargeType: value.RadioSelect,
    };
    return await postAPIcall(API_UPDATE_AUTOPAYMENT_VToB, data);
  }
};

/**
 * API of  Delete AutoPayment
 * @param {*} value
 * @returns axios.delete API_V2VAUTOPAYMENTDELETE
 */
export const DeleteV2VAutoPayment = async (id) => {
  let data = {
    autoPaymentV2VInstructionId: id,
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
  };
  return await deleteAPIcall(
    API_V2VAUTOPAYMENTDELETE +
      `?businessInfoId=${data.businessInfoId}&autoPaymentV2VInstructionId=${data.autoPaymentV2VInstructionId}`
  );
};

/**
 * Get V2V StandingInstruction TransactionList
 * @param {*} value
 * @returns  axios.get GetV2VAutopaymentTransactionList
 */
export const GetV2VAutopaymentTransactionList = async (value) => {
  return await getAPIcall(
    API_V2VAUTOPAYMENT_TRANSACTION_LIST +
      "?autoPaymentV2VInstructionId=" +
      value
  );
};

/**
 * API of  Get V2V Failed Autopayment To Manual Retry
 * @param {*} value
 * @returns axios.get API_V2VAUTOPAYMENT_FAILEDLIST
 */
export const GetV2VFailedAutopaymentToManualRetryFun = async () => {
  return await getAPIcall(API_V2VAUTOPAYMENT_FAILEDLIST);
};

/**
 * API of retry transaction Function
 * @param {*} id
 * @returns axios.post V2VAutopaymentManualRetry
 */
export const V2VFailedAutopaymentManualRetry = async (id) => {
  let data = {
    autoPaymentV2VInstructionId: id,
    businessInfoId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID),
  };
  return await postAPIcall(
    V2VAutopaymentManualRetry +
      `?autoPaymentV2VInstructionId=${data.autoPaymentV2VInstructionId}&businessInfoId=${data.businessInfoId}`,
    {}
  );
};

/**
 * API of Add autoBeneficiary
 * @param {*} values
 * @returns axios.post(API_ADD_BENEFICIARY
 */
export const AddautoBeneficiary = async (values) => {
  let data = {
    beneficiaryName: values.beneficiaryName, // "string",
    userID: values.userID, //"string",
    email: values.email, //"string"
  };
  return await postAPIcall(API_ADD_BENEFICIARY, data);
};

/**
 * API of Delete Auto Payment V2B
 * @param {*} value
 * @returns axios.delete API_AUTO_V2B_DELETE
 */
export const DeleteAutoPaymentV2B = async (value) => {
  return await deleteAPIcall(API_AUTO_V2B_DELETE + value);
};

/**
 * API of get Bank Auto Payment List
 * @returns  axios.get(GETBANK_AUTOPAYMENT_LIST
 */
export const getBankAutoPaymentList = async () => {
  return await getAPIcall(GETBANK_AUTOPAYMENT_LIST);
};
