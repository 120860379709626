import { getCurrencyElement } from "../../../helper";

const initialState = {
  isMVC: "",
  token: "",
  curCode: "",
  accountCurrencyName: "",
  fromAccountCurrencyName: "",
  fromWalletToken: "",
  loading: null,
  beneficiaryCount: 0,
  beneficiaryList: [],
  beneficiaryName: "",
  beneficiaryBusinessInfoId: null,
  beneficiaryId: null,
  payeeBusinessInfoId:null,
  beneficiaryEmail: null,
  updateautopayment: false,
  currency: "",
  amount: "",
  selectedCurrency: "",
  startDate: new Date(),
  endDate: "",
  frequencyType: "",
  note: "",
  processingDay: 0,
  id:null,
  transactionBeneficiaryName: "",
  transactionBeneficiaryEmail: "",
  transactions: [],
  isMulticurrency: false,
  publicToken: null,
  currencyAccountName: null,
  currCode: null,
};

export const AutopaymentVtoVReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_AUTOVBTOVBBENEFICIARY_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_AUTOVBTOVBBENEFICIARY_SUCCESS":
      return {
        ...state,
        loading: false,
        beneficiaryCount: action.payload.beneficiaryCount,
        beneficiaryList: action.payload.beneficiaryList
      };
    case "GET_AUTOVBTOVBBENEFICIARY_FAILURE":
      return {
        ...state,
        loading: false,
        beneficiaryCount: 0,
        beneficiaryList: []
      };
    case "AUTO_ADD_VTOV_ADDBENEFICIARY_FROM_TABLE_ACTION":
      return {
        ...state,
        accountCurrencyName: action.payload?.accountCurrencyName,
        isMVC: action.payload?.isMVC,
        isMultiCurrency: !action.payload?.isMVC,
        toWalletToken: action.payload?.token,
        curCode: action.payload?.curCode,
        beneficiaryId: action.payload?.beneficiaryId??null,
        payeeBusinessInfoId: action.payload?.payeeBusinessInfoId??null,
        beneficiaryName: action.payload.beneficiaryName,
        beneficiaryEmail: action.payload.beneficiaryEmail,
      };

    case "AUTOADD_VTOV_ADDBENEFICIARY_ACTION":
      return {
        ...state,
        isMVC: !action.payload.isMultiCurrency,
        beneficiaryId: action.payload?.beneficiaryId??null,
        payeeBusinessInfoId: action.payload?.payeeBusinessInfoId??null,
        beneficiaryName: action.payload.beneficiaryName,
        beneficiaryEmail: action.payload.email,
        isMultiCurrency: action.payload.isMultiCurrency,
        toWalletToken: action.payload.publicToken,
        accountCurrencyName: action.payload.accountCurrencyName,
        curCode: action.payload.currency,
        isMulticurrency: action.payload.isMultiCurrency,
      };

    case "UPDATE_AUTOPAYMENT_VToV":
      return {
        ...state,
        updateautopayment: true,
        id: action.payload.id,
        beneficiaryId: action.payload.v2VBeneficiaryId,
        payeeBusinessInfoId: action.payload.businessInfoId,
        beneficiaryName: action.payload.beneficiaryName,
        beneficiaryEmail: action.payload.beneficiaryEmail,
        curCode: action.payload.curCode,
        currency: action.payload.curCode,
        amount: action.payload.amount,
        startDate: new Date(action.payload.nextDueDate),
        endDate: action.payload.endDate === null ? "" : new Date(action.payload.endDate),
        selectedCurrency: getCurrencyElement(action.payload.curCode),
        frequencyType: action.payload.frequencyType.toString(),
        note: action.payload.notes??'',
        processingDay: parseInt(action.payload.processingDay),
        isMVC: action.payload?.isMVC,
        isMultiCurrency: !action.payload?.isMVC,
        toWalletToken: action.payload?.toWalletToken,
        accountCurrencyName: action.payload?.toAccountCurrencyName,
        fromAccountCurrencyName: action.payload?.fromAccountCurrencyName,
        publicToken: action.payload?.fromWalletToken,
      };

    case "AUTO_PAYMENT_TRANSACTION_LIST":
      return {
        ...state,
        transactions: action.payload.data,
        transactionBeneficiaryName: action.payload.name,
        transactionBeneficiaryEmail: action.payload.email,
      };
    case "INIT_AUTOPAYMENT":
      return initialState;

    default:
      return state;
  }
};

export default AutopaymentVtoVReducer;
