import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import { setlocalstorageJSON, getlocalstorage } from "../../../helper";
import { finalAppstatus, profileAPI } from "../../../Services/profileService";

export const init_Profile = () => {
  return {
    type: "INIT_PROFILE",
  };
};

export const get_finialStatus_Success = (status) => {
  return {
    type: "GET_APPSTATUS_SUCCESS",
    payload: status,
  };
};

export const get_profile_Request = () => {
  return {
    type: "GET_PROFILE_REQUEST",
  };
};

export const get_profile_local = () => {
  return {
    type: "GET_PROFILE_LOCAL",
    payload: JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.PROFILE)),
  };
};

export const get_profile_Success = (profile) => {
  return {
    type: "GET_PROFILE_SUCCESS",
    payload: setlocalstorageJSON(KEYS_FOR_LOCALSTORAGE.PROFILE, profile),
  };
};

export const get_profile_Failure = (error) => {
  return {
    type: "GET_PROFILE_FAILURE",
    payload: error,
  };
};
/**
 *
 * @returns Setting user profile data into local storage
 */
export const getProfile = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(get_profile_Request());
      profileAPI()
        .then((response) => {
          dispatch(
            get_profile_Success(
              response.data === undefined ? "" : response.data
            )
          );
          resolve(response.data.isApplicationCompleted);
        })
        .catch((error) => {
          dispatch(get_profile_Failure(error));
          reject(error);
        });
    });
  };
};

/**
 *
 * @returns Returning user finial app status
 */
export const getApplicationStatus = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(get_profile_Request());
      finalAppstatus()
        .then((response) => {
          dispatch(
            get_finialStatus_Success(
              response?.data?.finalApplicationStatus ?? undefined
            )
          );
          resolve(response?.data?.finalApplicationStatus);
        })
        .catch((error) => {
          dispatch(get_profile_Failure(error));
          reject(error);
        });
    });
  };
};
