const initialState = {
  loading: false,
  countrycode: null,
  countryIndex: null,
  requestId: true,
  mobileNumber: "",
  iso: "gb",
  name: "",
};
const mobNoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MOB_NO":
      return {
        ...state,
        loading: true,
        mobileNumber: action.payload.mobileNumber,
        countrycode: action.payload.countrycode,
        countryIndex: action.payload.countryIndex,
        iso: action.payload.iso,
        name: action.payload.name,
      };

    case "SEND_OTP_SUCCESS":
      return {
        ...state,
        loading: false,
        requestId: action.payload.requestId,
      };
    case "SEND_OTP_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "INIT_SIGNUP_MOBILENO":
      return initialState;
    default:
      return state;
  }
};

export default mobNoReducer;
