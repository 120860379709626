import { getAPIcall, postAPIcall } from "Services/apiController";
import axios from "axios";
import {
  API_ADDNEWEMPLOYEE_CARD,
  API_CARD_LIST,
  API_GET_CARD_ACTIVATION,
  API_SEND_CARD_ACTIVATION_OTP,
  API_VERIFY_CARD_ACTIVATION_OTP,
} from "../../../helper";

/**
 * API of create New Emp Card api
 * @param {*} value
 * @returns axios.post API_ADDNEWEMPLOYEE_CARD
 */
export const createNewEmpCardapi = async (value) => {
  let data = {
    firstname: value.firstName,
    lastName: value.lastName,
    dob: value.dob,
    nameAsOnCard: value.nameAsOnCard,
    dialInCode: value.dialInCode.toString(),
    mobileNumber: value.mobileNumber,
    emailAddress: value.emailAddress,
    addressLine1: value.addressLine1,
    addressLine2: value.addressLine2,
    state: value.state,
    city: value.city,
    country: value.country,
    postCode: value.postCode,
    limitGroup: value.limitGroup,
    mccGroup: value.mccGroup,
    curCode: value.mvcCurr,
    isCardVirtual: value.isCardVirtual,
  };
  if (value.isCardVirtual === false) {
    data = { ...data, feeAmount: value.chargeAmount };
  }
  return await postAPIcall(API_ADDNEWEMPLOYEE_CARD, data);
};
/**
 * API of Card List API
 * @returns axios.get API_CARD_LIST
 */
export const CardListAPI = async () => {
  return await getAPIcall(API_CARD_LIST);
};

/**
 * API of GetCardActivation
 * @param {*} value
 * @returns  axios.get API_GET_CARD_ACTIVATION
 */
export const GetCardActivation = async (value) => {
  return await getAPIcall(API_GET_CARD_ACTIVATION + "?tokenId=" + value);
};
/**
 * API of Send Card Activation Otp
 * @param {*} value
 * @returns axios.get API_SEND_CARD_ACTIVATION_OTP
 */

export const SendCardActivationOtp = async (value) => {
  return await getAPIcall(
    API_SEND_CARD_ACTIVATION_OTP +
      "?token=" +
      value.token +
      "&" +
      "dialInCode=" +
      value.dialInCode +
      "&" +
      "mobileNumber=" +
      value.mobileNumber
  );
};
/**
 * API of Verify Card Activation Otp
 * @param {*} value
 * @returns  axios.get API_VERIFY_CARD_ACTIVATION_OTP
 */
export const VerifyCardActivationOtp = async (value) => {
  return await axios.post(API_VERIFY_CARD_ACTIVATION_OTP, {
    requestId: value.requestId,
    vcode: value.token,
    mobileNumber: value.mobileNumber,
    otp: value.otp,
    publicToken: value.publicToken,
    auth_token: "",
    isVirtual: false,
    isMobileVerified: value.isMobileVerified,
  });
};
