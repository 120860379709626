import { getAPIcall, postAPIcall } from 'Services/apiController';
import { KEYS_FOR_LOCALSTORAGE } from '../../../configs/pageConst';
import {
  ADD_CURRENCY_IBAN,
  API_CREATE_IABNSECONDARY_ACCOUNT,
  API_FETCH_MULIBAN_DETAILS,
  API_GET_CURRENCY,
  CHECKIBAN,
  GET_SAVED_CARD_LIST,
  LOADFUNDDDETAILS,
  getlocalstorage,
} from '../../../helper';
import { getCommonCurrency } from '../CommonAPI';

export const LoadCurrencyData = () => {
  let CurrencyData = [];
  getCommonCurrency().then((results) => {
    //TODO:filter to add only 4 currencies : USD, EUR, GBP, CHF
    let FilterCurrency = results.data.filter(
      (f) =>
        f.currencyCode === 'USD' || f.currencyCode === 'EUR' || f.currencyCode === 'GBP' || f.currencyCode === 'CHF',
    );
    FilterCurrency.forEach((item) => {
      CurrencyData.push({
        value: item.currencyCode,
        label: (
          <div>
            <img
              src={API_GET_CURRENCY + item.currencyCode.toLowerCase() + '.png'}
              width='20px'
              height='20px'
              alt='currency'
            />
            &nbsp; &nbsp;
            <p style={{ display: 'inline' }}>{item.currencyCode}</p>
          </div>
        ),
        iso: item.iso,
        name: item.name,
        currencySymbol: item.currencySymbol,
      });
    });
  });
  return CurrencyData;
};

export const getSavedCardList = async (cardInfo) => {
  return await postAPIcall(GET_SAVED_CARD_LIST, cardInfo, {
    businessId: getlocalstorage(KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID).replace('"', '').replace('"', ''),
  });
};

export const getLoadFund = async () => {
  return await getAPIcall(LOADFUNDDDETAILS);
};

export const addCurrencyIBAN = async () => {
  return await getAPIcall(ADD_CURRENCY_IBAN);
};

export const CheckIBAN = async () => {
  // let IsGenerateEURIBAN = 'IsGenerateEURIBAN'
  return await getAPIcall(CHECKIBAN, { ibanKey: 'IsGenerateEURIBAN' });
};

export const CheckIBANGBPS = async () => {
  // let IsGenerateEURIBAN = 'IsGenerateEURIBAN'
  return await getAPIcall(CHECKIBAN, { ibanKey: 'IsGenerateGBPIBAN' });
};

export const createSecondaryAccount = async (currCode) => {
  return await getAPIcall(API_CREATE_IABNSECONDARY_ACCOUNT, { currency: currCode });
};

export const fetchMulIBANDetails = async () => {
  return await getAPIcall(API_FETCH_MULIBAN_DETAILS);
};
