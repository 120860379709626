import {
  API_documentUpload
} from "../../../Services";
export const fetch_Business_Doc = (payload) => {
  return {
    type: "FETCH_BUSINESS_DOC",
    payload,
  };
};

export const init_Business_Doc = () => {
  return {
    type: "INIT_BUSINESS_DOC",
  };
};

export const uploadBusinessDocument = (data) => {
  return () => {
    return new Promise(function (resolve, reject) {
      API_documentUpload(data)
        .then((response) => {
          if (response?.data) {
            // dispatch(documentdata_Success(response?.data));
            resolve(response?.data);
          } else {
            // dispatch(documentdata_Failure("error"));
            resolve(false);

          }
        })
        .catch((error) => {
          console.log("catch", error)
          // dispatch(documentdata_Failure(error));
          reject(error);
        });
    });
  };
};