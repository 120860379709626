const initialState = {
  vcode: "",
  index: null,
  name: "",
  dialInCode: "",
  mobileNumber: "",
  isMobileVerified: false,
  isExpired: false,
  // publicToken: "",
  isVirtual: true,
  msg: "",
  requestId: "",
};

const EmpVirtualCardreducer = (state = initialState, action) => {
  switch (action.type) {
    // case "GET_DOCSUBMIT_ADDRESS_REGISTERD_REQUEST":
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case "GET_DOCSUBMIT_ADDRESS_REGISTERD_SUCCESS":
    //   return {
    //     ...state,
    //     loading: false,
    //     Firstname: action.payload.Firstname,
    //     LastName: action.payload.LastName,
    //     DateOfBirth: action.payload.DateOfBirth,
    //     Email: action.payload.Email,
    //     MobNo: action.payload.MobNo,
    //     PercentOfOwnership: action.payload.PercentOfOwnership,
    //     error: "",
    //   };
    // case "GET_DOCSUBMIT_ADDRESS_REGISTERD_FAILURE":
    //   return {
    //     ...state,
    //     loading: false,
    //     Firstname: "",
    //     LastName: "",
    //     DateOfBirth: "",
    //     Email: "",
    //     MobNo: "",
    //     PercentOfOwnership: "",
    //     error: action.payload,
    //   };
    case "EMP_SAVE_VIRTUAL_CARD_DATA":
      return {
        vcode: action.payload.vcode,
        index: action.payload.index,
        name: action.payload.name,
        dialInCode: action.payload.dialInCode,
        mobileNumber: action.payload.mobileNumber,
        isMobileVerified: action.payload.isMobileVerified,
        isExpired: action.payload.isExpired,
        // publicToken: action.payload.publicToken,
        isVirtual: action.payload,
        msg: action.payload.msg,
        requestId: action.payload.requestId,
      };
    case "INIT_EMP_CARD":
      return initialState;
    default:
      return state;
  }
};

export default EmpVirtualCardreducer;
