import axios from "axios";
import { ACCOUNT_PURPOSE } from "../..";

export let purposeofAccount_options = async () => {
  let MCCGroupOption = [];
  await axios
    .get(ACCOUNT_PURPOSE, {
      headers: {
        "content-type": "application/json", // override instance defaults
      },
    })
    .then((results) => {
      results.data.forEach((item) => {
        MCCGroupOption.push({
          value: item.key,
          label: item.value,
          id: item.id,
        });
      });
    });
  return MCCGroupOption;
};
