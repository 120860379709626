import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import { setlocalstorage } from "../../../helper/handleLocalStorage";

const initialState = {
  loading: false,
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  citizenshipCountryId: null,
  postalCode: "",
  city: "",
  companyName: "",
  addressLine1: "",
  addressLine2: "",
  preRegistrationId: "",
  positionId: null,
  phoneNo: "",
  isUSCitizen: false,
  isPoliticallyExposed: false,
  politicallyExposedCategoryId: "",
  politicallyExposedText: "",
  individualTypeId: null,
  isApplicationCompleted: false,
  addressCountryId: null,
  dialInCode: null,
  emailAddress: "",
  state: "",
  ownershipPercentage: null,
  sourceOfWealth: "",
  otherPositionId: null,
  isUbo: false,
  isShareHolder: false,
  isExecutive: false,
  isFirstTimeLogin: false,
  taxes: [],
  error: "",
  finalApplicationStatus : "",
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROFILE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "GET_PROFILE_SUCCESS":
      return {
        ...state,
        loading: false,
        id: action.payload.id,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        postalCode: action.payload.postalCode,
        companyName: action.payload.companyName,
        city: action.payload.city,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        preRegistrationId: action.payload.preRegistrationId,
        positionId: action.payload.positionId,
        phoneNo: action.payload.phoneNo,
        isUSCitizen: action.payload.isUSCitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        politicallyExposedText: action.payload.politicallyExposedText,
        individualTypeId: action.payload.individualTypeId,
        isApplicationCompleted: action.payload.isApplicationCompleted,
        addressCountryId: action.payload.addressCountryId,
        dialInCode: action.payload.dialInCode,
        emailAddress: action.payload.emailAddress,
        state: action.payload.state,
        ownershipPercentage: action.payload.ownershipPercentage,
        sourceOfWealth: action.payload.sourceOfWealth,
        otherPositionId: action.payload.otherPositionId,
        isUbo: action.payload.isUbo,
        isShareHolder: action.payload.isShareHolder,
        isExecutive: action.payload.isExecutive,
        isFirstTimeLogin: action.payload.isFirstTimeLogin,
        taxes: action.payload.taxes,
        businessInfoId: setlocalstorage(
          KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID,
          action.payload.businessInfoId
        ),
        error: "",
      };

    case "GET_APPSTATUS_SUCCESS":
      return {
        ...state,
        finalApplicationStatus: action.payload,
      };

    case "GET_PROFILE_LOCAL":
      return {
        loading: false,
        id: action.payload.id,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        postalCode: action.payload.postalCode,
        city: action.payload.city,
        finalApplicationStatus: action.payload.finalApplicationStatus,
        companyName: action.payload.companyName,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        preRegistrationId: action.payload.preRegistrationId,
        positionId: action.payload.positionId,
        phoneNo: action.payload.phoneNo,
        isUSCitizen: action.payload.isUSCitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,
        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        politicallyExposedText: action.payload.politicallyExposedText,
        individualTypeId: action.payload.individualTypeId,
        isApplicationCompleted: action.payload.isApplicationCompleted,
        addressCountryId: action.payload.addressCountryId,
        dialInCode: action.payload.dialInCode,
        emailAddress: action.payload.emailAddress,
        state: action.payload.state,
        ownershipPercentage: action.payload.ownershipPercentage,
        sourceOfWealth: action.payload.sourceOfWealth,
        otherPositionId: action.payload.otherPositionId,
        isUbo: action.payload.isUbo,
        isShareHolder: action.payload.isShareHolder,
        isExecutive: action.payload.isExecutive,
        isFirstTimeLogin: action.payload.isFirstTimeLogin,
        taxes: action.payload.taxes,
        error: "",
      };

    case "GET_PROFILE_FAILURE":
      return {
        ...state,
        loading: false,
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        finalApplicationStatus: "",
        citizenshipCountryId: null,
        postalCode: "",
        city: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        preRegistrationId: "",
        positionId: null,
        phoneNo: "",
        isUSCitizen: false,
        isPoliticallyExposed: false,
        politicallyExposedCategoryId: "",
        politicallyExposedText: "",
        individualTypeId: null,
        isApplicationCompleted: false,
        addressCountryId: null,
        dialInCode: null,
        emailAddress: "",
        state: "",
        ownershipPercentage: null,
        sourceOfWealth: "",
        otherPositionId: null,
        isUbo: false,
        isShareHolder: false,
        isExecutive: false,
        isFirstTimeLogin: false,
        taxes: [],
        error: action.payload,
      };
    case "INIT_PROFILE":
      return initialState;
    default:
      return state;
  }
};

export default ProfileReducer;
