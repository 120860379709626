//` COUNTRY API CALL AND RETURN DATA OF COUNTRY LIST AND DIAL IN CODE
import { getAPIcallWithoutAuthorization } from 'Services/apiController';
import axios from 'axios';
import { KEYS_FOR_SESSIONSTORAGE } from 'configs/pageConst';
import { getCurrentPurpose } from '..';
import {
  API_COUNTRYFLAG,
  API_COUNTRYLIST,
  API_CURRENCY,
  API_GET_BANKTRANSFER_CURRENCY,
  API_LEGAL_ENTITY_MASTER,
  API_PERSION_POSITION,
  API_POLITICAL_EXPOSE_LIST,
  API_PROHABITED_URL,
  GET_OTHERPOSITIONS,
  getSessionStorage,
  setSessionStorage,
  API_GET_MAINTENANCE,
} from '../../../helper';
import './CommonAPI.css';

/**
 * 
 * @returns returnObject = {
    status: null,
    msg: "",
    countryList: [],
    DialInCode: [],
  }
 */

export const getCurrencyListAPI = async () => {
  let res = null;
  let resFromLocalStorage = JSON.parse(getSessionStorage(KEYS_FOR_SESSIONSTORAGE.CURRENCY_LIST));
  if (Array.isArray(resFromLocalStorage)) {
    res = {
      data: resFromLocalStorage,
      status: 200,
      statusText: '',
    };
  } else {
    res = await axios.get(API_COUNTRYLIST, {
      headers: {
        'content-type': 'application/json', // override instance defaults
      },
    });
    setSessionStorage(KEYS_FOR_SESSIONSTORAGE.CURRENCY_LIST, JSON.stringify(res.data));
  }
  return new Promise((resolve) => resolve(res));
};

export const countryandDialinCode = async () => {
  let returnObject = {
    status: null,
    msg: '',
    countryList: [],
    DialInCode: [],
  };

  await getCurrencyListAPI()
    .then(async (results) => {
      returnObject.status = 1;
      let countriesdata = results.data;
      countriesdata.sort(function (a, b) {
        return parseInt(a.phoneCode) - parseInt(b.phoneCode);
      });
      await countriesdata.forEach((item) => {
        returnObject.DialInCode.push({
          value: item.phoneCode,
          label: (
            <div className='selectInsideText'>
              <img src={API_COUNTRYFLAG + item.iso.toLowerCase() + '.png'} alt='flag' width='20px' height='20px' />
              <p style={{ display: 'inline' }}>{` +${item.phoneCode}`}</p>
            </div>
          ),
          id: item.id,
          phoneCode: item.phoneCode,
        });
      });
      countriesdata.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
      await countriesdata.forEach((item) => {
        returnObject.countryList.push({
          value: item.name,
          label: (
            <div className='selectInsideText'>
              <img src={API_COUNTRYFLAG + item.iso.toLowerCase() + '.png'} alt='flag' width='20px' height='20px' />
              &nbsp; &nbsp;
              <p style={{ display: 'inline' }}>{item.name}</p>
            </div>
          ),
          id: item.id,
          iso: item.iso,
          name: item.name,
        });
      });
    })
    .catch((error) => {
      console.log('error', error);
      returnObject.status = 0;
      returnObject.msg = 'Error while processing request';
    });

  return returnObject;
};

//`POSITION API
/**
 * 
 * @returns Position = {
    status: null,
    msg: "",
    PositionData: [],
  };
 */
export const PositionAPI = async () => {
  let Position = {
    status: null,
    msg: '',
    PositionData: [],
  };
  await axios
    .get(API_PERSION_POSITION, {
      headers: {
        'content-type': 'application/json', // override instance defaults
      },
    })
    .then((results) => {
      Position.status = 1;
      let positionsListdata = results.data;
      positionsListdata.forEach((item) => {
        Position.PositionData.push({
          value: item.id,
          id: item.id,
          label: (
            <div className='selectInsideText'>
              <p style={{ display: 'inline' }}>{item.value}</p>
            </div>
          ),
          postionName: item.value,
        });
      });
    })
    .catch(() => {
      Position.status = 0;
      Position.msg = '';
    });
  return Position;
};

//`POLITICAL EXPOSE API
/**
 * 
 * @returns {
    status: null,
    msg: "",
    Politicaldata: [],
  };
 */

export const getPoliticalOptionsList = async () => {
  let res = null;
  let resFromSessionStorage = JSON.parse(getSessionStorage(KEYS_FOR_SESSIONSTORAGE.POLITICAL_EXPO_MERIT_LITST));
  if (Array.isArray(resFromSessionStorage)) {
    res = {
      data: resFromSessionStorage,
      status: 200,
      statusText: '',
    };
  } else {
    res = await getAPIcallWithoutAuthorization(API_POLITICAL_EXPOSE_LIST);
    setSessionStorage(KEYS_FOR_SESSIONSTORAGE.POLITICAL_EXPO_MERIT_LITST, JSON.stringify(res.data));
  }
  return new Promise((resolve) => resolve(res));
};

export const PoliticalOptions = async () => {
  let Political = {
    status: null,
    msg: '',
    Politicaldata: [],
  };

  await getPoliticalOptionsList()
    .then((results) => {
      let PoliticallyExposeddata = results.data;
      Political.status = 1;
      PoliticallyExposeddata.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });

      PoliticallyExposeddata.forEach((item) => {
        Political.Politicaldata.push({
          id: item.id,
          label: (
            <div className='selectInsideText'>
              <span>{item.value}</span>
            </div>
          ),
          value: item.id,
        });
      });
    })
    .catch(() => {
      Political.status = 0;
      Political.msg = '';
    });
  return Political;
};
//` PURPOSE oF CURRENT ACCOUNT API
/**
 * 
 * @returns puposeofCurrent = {
    status: null,
    msg: "",
    PurposeData: [],
  };
 */
export const getPurposeOfCurrentAccount = async () => {
  let puposeofCurrent = {
    status: null,
    msg: '',
    PurposeData: [],
  };
  await getCurrentPurpose()
    .then((results) => {
      let PurposeData = results.data;
      puposeofCurrent.status = 1;
      PurposeData.forEach((item) => {
        puposeofCurrent.PurposeData.push({
          id: item.id,
          label: (
            <div className='selectInsideText'>
              <span>{item.value}</span>
            </div>
          ),
          value: item.id,
        });
      });
    })
    .catch(() => {
      puposeofCurrent.status = 0;
      puposeofCurrent.msg = '';
    });
  return puposeofCurrent;
};

//`OTHER POSITION API
/**
 * 
 * @returns Position = {
    status: null,
    msg: "",
    Positiondata: [],
  };
 */

export const getOtherPositions = async () => {
  let res = null;
  let resFromSessionStorage = JSON.parse(getSessionStorage(KEYS_FOR_SESSIONSTORAGE.OTHER_POSITION_LIST));
  if (Array.isArray(resFromSessionStorage)) {
    res = {
      data: resFromSessionStorage,
      status: 200,
      statusText: '',
    };
  } else {
    res = await getAPIcallWithoutAuthorization(GET_OTHERPOSITIONS);
    setSessionStorage(KEYS_FOR_SESSIONSTORAGE.OTHER_POSITION_LIST, JSON.stringify(res.data));
  }
  return new Promise((resolve) => resolve(res));
};

export const OtherPositionOptions = async () => {
  let Position = {
    status: null,
    msg: '',
    Positiondata: [],
  };

  await getOtherPositions()
    .then((results) => {
      let PoliticallyExposeddata = results.data;
      Position.status = 1;
      PoliticallyExposeddata.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
      PoliticallyExposeddata.forEach((item) => {
        Position.Positiondata.push({
          id: item.id,
          label: (
            <div className='selectInsideText'>
              <span>{item.value}</span>
            </div>
          ),
          value: item.id,
        });
      });
    })
    .catch(() => {
      Position.status = 0;
      Position.msg = '';
    });
  return Position;
};

//`LEGAL ENTITY MASTER
/**
 * 
 * @returns legalEntity = {
    legalEntityData: [],
    msg: "",
    status: null,
  };
 */
export const LEGAL_ENTITY_MASTER_Data = async () => {
  let legalEntity = {
    legalEntityData: [],
    msg: '',
    status: null,
  };
  const getsessionData = JSON.parse(getSessionStorage('LEGAL-ENT-MASTER-LIST'));
  if (getsessionData !== null) {
    legalEntity.status = 1;
    getsessionData.legalEntityData.forEach((item) => {
      legalEntity.legalEntityData.push({
        value: item.value,
        id: item.id,
        label: item.label,
      });
    });
    return legalEntity;
  } else {
    await axios
      .get(API_LEGAL_ENTITY_MASTER, {
        headers: {
          'content-type': 'application/json', // override instance defaults
        },
      })
      .then((results) => {
        legalEntity.status = 1;
        results.data.forEach((item) => {
          legalEntity.legalEntityData.push({
            value: item.id,
            id: item.id,
            label: item.value,
          });
        });
        setSessionStorage(KEYS_FOR_SESSIONSTORAGE.LEGAL_ENT_MASTER_LIST, JSON.stringify(legalEntity));
      })
      .catch(() => {
        legalEntity.status = 0;
        legalEntity.msg = '';
      });
  }
  return legalEntity;
};

//`Common Currency

export const getCommonCurrency = async () => {
  return await getAPIcallWithoutAuthorization(API_CURRENCY);
};

export const getCommonCountry = async () => {
  return await getAPIcallWithoutAuthorization(API_COUNTRYLIST);
}
//`PROHABITED BUSINESS LIST
export const prohabitedList = () => {
  return getAPIcallWithoutAuthorization(API_PROHABITED_URL);
};

export const bankTransferCurrency = async () => {
  return await getAPIcallWithoutAuthorization(API_GET_BANKTRANSFER_CURRENCY);
};

export const getPositions = async () => {
  return await axios.get(API_PERSION_POSITION, {
    headers: {
      'content-type': 'application/json', // override instance defaults
    },
  });
};
/**
 * 
 * @returns Encrypted Date & Time 
 */
export const getMaintainceStatus = async (key) => {
  return await getAPIcallWithoutAuthorization(API_GET_MAINTENANCE + key)
}
