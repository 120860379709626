import { combineReducers } from "redux";
import completeUrApplReducer from "./completeUrApplReducer";
import businessAddressReducer from "./BusinessAddressReducer";
import ShareHolderDetailPersonReducer from "./ShareHolderDetailPersonReducer";
import BusinessInfoAddReducer from "./BusinessInfoAddReducer";
import businessOwnerDetailsReducer from "./BusinessOwnerDetailsReducer";
import addDirectorReducer from "./AddDirectorReducer";
import UltimateBenificialOwnerDetailsReducer from "./UltimateBenificialOwnerDetailsReducer";
import LegalEntityBusinessDetailsReducer from "./LegalEntityBusinessDetailsReducer";
import ShareHolderDetailCompanyReducer from "./ShareHolderDetailCompanyReducer";
import ExeDirRepDetailsReducer from "./ExeDirRepDetailsReducer";
import businessInfoReducer from "./BusinessInfoReducer";
import businessOwnerDetailsDocReducer from "./businessOwnerDetailsDocReducer";
import PersonaldetailsReducer from "./PersonaldetailsReducer";
import BusinessDocReducer from "./BusinessDocReducer";
const initDocSubmitReducer = combineReducers({
  docSubmitStatus: completeUrApplReducer,
  legalentitybusinessdetails: LegalEntityBusinessDetailsReducer,
  BusinessAddress: businessAddressReducer,
  shareholderdetailperson: ShareHolderDetailPersonReducer,
  shareholderdetailcompany: ShareHolderDetailCompanyReducer,
  ultimatebenficialownerdetails: UltimateBenificialOwnerDetailsReducer,
  executivedetails: ExeDirRepDetailsReducer,
  businessinfo: businessInfoReducer,
  businessinfoadd: BusinessInfoAddReducer,
  businessownerdetails: businessOwnerDetailsReducer,
  businessOwnerDetailsDoc: businessOwnerDetailsDocReducer,
  addDirector: addDirectorReducer,
  personalDetails: PersonaldetailsReducer,
  businessdoc: BusinessDocReducer,
});

const docSubmitReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") {
    state = undefined;
  }
  return initDocSubmitReducer(state, action);
};

export default docSubmitReducer;
