import { isEmailExists } from "../../../Services/verifyServices";

export const fetchEmailUuidRefcode = (payload) => {
  return {
    type: "FETCH_EMAIL_UUID_REFCODE",
    payload,
  };
};

export const isEmailExistsRequest = () => {
  return {
    type: "IS_EMAIL_EXISTS_REQUEST",
  };
};

export const isEmailExistSuccess = (emailStatus) => {
  return {
    type: "IS_EMAIL_EXISTS_SUCCESS",
    payload: emailStatus,
  };
};

export const isEmailExistFailure = (error) => {
  return {
    type: "IS_EMAIL_EXISTS_FAILURE",
    payload: error,
  };
};

export const initSignupEmail = () => {
  return {
    type: "INIT_SIGNUP_EMAIL",
  };
};

export const fetchEmail = (emailPageValue) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(isEmailExistsRequest());
      isEmailExists(emailPageValue.email, emailPageValue.preRegistrationId)
        .then((response) => {
          dispatch(isEmailExistSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          dispatch(isEmailExistFailure(error));
          reject(error);
        });
    });
  };
};
