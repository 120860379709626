import { applyMiddleware, compose, createStore } from "redux";
import createDebounce from "redux-debounced";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
const middlewares = [thunk, createDebounce()];

const blacklist1 = ["navbar", "auth", "customizer", "navbar", "empVirCard"];
// const docSubmitBlackList = [
//   "navbar",
//   "auth",
//   "navbar",
//   "customizer",
//   "pages",
//   "dashboard",
// ];
// const dashboardBalckList = [
//   "navbar",
//   "auth",
//   "navbar",
//   "customizer",
//   "pages",
//   "docsubmit",
// ];

const persistConfig = {
  key: "root",
  // key: "primary",
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  blacklist: blacklist1,
};

//redux persist reducer
const pReducer = persistReducer(persistConfig, rootReducer);
//redux devtool composer
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store =
  process.env.REACT_APP_ENV === "PROD"
    ? createStore(pReducer, applyMiddleware(thunk))
    : createStore(
      pReducer,
        {},
        composeEnhancers(applyMiddleware(...middlewares))
      );

export const persistor = persistStore(store);

export { store };
