import { components } from "react-select";

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>No data found </span>
    </components.NoOptionsMessage>
  );
};

export const noOptionMsgStyles = {
  //   background: "#3b8aff",
  background: "#3b8aff",
  opacity: "0.5",
  color: "white",
};
