import {
  v2vSearch,
  V2VFUND_TRANSFER,
  VToVLISTING,
  API_VTOBACCOUNT_REASON,
  BENEFICIARY_REQUIRED_DETAILS,
  VToBLISTING,
  ADDBENEFICIARY_BANK,
  API_DELETE_BANK_BENEFICIARY,
  API_VTOBACCOUNT_FEES,
  API_MAKE_SELF_TRANSFER,
  API_MAKE_PAYMENT,
  API_GET_BENEFICIARY,
  UPDATE_BENEFICIARY_BANK,
  API_DELETE_V_TO_VB_BENEFICIARY,
  API_DELETE_V_TO_VRETAIL_BENEFICIARY,
  API_VOLUME_BASED_FEE,
} from "../../../helper";
import { deleteAPIcall, getAPIcall, postAPIcall } from "Services/apiController";

export const getvolumefee = async () => {
  return await getAPIcall(API_VOLUME_BASED_FEE);
};
export const V2VSearch = async (value) => {
  return await getAPIcall(v2vSearch + "searchTerm=" + value);
};

export const v2vFundTransfer = async (value) => {
  let data = {
    amount: parseFloat(value.amount),
    currency: value.currency.split("@/@")[1],
    FromWalletToken: value.currency.split("@/@")[0],
    ToWalletToken: value.toWalletToken,
    isMultiCurrency: value.isMultiCurrency,
    beneficiaryId: value.beneficiaryId,
    userID: value.userID,
    isBusiness: value.isBusiness,
    notes: value.Note,
  };
  return await postAPIcall(V2VFUND_TRANSFER, data);
};

export const VToVListing = async () => {
  return await getAPIcall(VToVLISTING);
};

export const VToBAccountReason = async (value) => {
  return await getAPIcall(
    API_VTOBACCOUNT_REASON +
      "?Country=" +
      value.CountryISO +
      "&" +
      "Currency=" +
      value.currency
  );
};

export const BeneficiaryRequiredDetails = async (value) => {
  return await getAPIcall(
    BENEFICIARY_REQUIRED_DETAILS +
      "Currency=" +
      value.currency +
      "&BankAccountCountry=" +
      value.BankAccountCountry +
      "&EntityType=" +
      value.EntityType
  );
};

export const AddBeneficiaryBank = async (
  value,
  currency,
  BankAccountCountry,
  formdata
) => {
  let data = {
    bankAccountHolderName: value.BankAccountHolderName,
    name: value.Name,
    bankCountry: BankAccountCountry,
    currency: currency,
    email: value.email,
    paymentType:
      formdata.PaymentType === undefined ? [] : [formdata.PaymentType],
    beneficiaryEntityType:
      formdata.BeneficiaryEntityType === "individual"
        ? "self"
        : formdata.BeneficiaryEntityType,
    beneficiaryFirstName: value.BeneficiaryFirstName,
    beneficiaryLastName: value.BeneficiaryLastName,
    beneficiaryCompanyName: value.BeneficiaryCompanyName,
    beneficiaryIdentificationType: value.BeneficiaryIdentificationType,
    beneficiaryIdentificationValue: value.BeneficiaryIdentificationValue,
    beneficiaryAddress:
      value.BeneficiaryAddress === "" ? [] : [value.BeneficiaryAddress],
    beneficiaryCity: value.BeneficiaryCity,
    beneficiaryCountry: value.BeneficiaryCountry,
    beneficiaryStateOrProvince: value.BeneficiaryStateOrProvince,
    beneficiaryPostcode: value.BeneficiaryPostcode,
    bankAccountType: value.BankAccountType,
    accountNumber: value.AcctNumber,
    bankName: value.BankName,
    bankCode: value.BankCode,
    branchCode: value.BranchCode,
    bankAddress: [],
    mobileNumber: value.mobileNumber,
    dialInCode: value.dialInCode.toString(),
    sortCode: value.SortCode,
    abaCode: value.Aba,
    bsbCode: value.BsbCode,
    iban: value.Iban,
    bicSwift: value.BicSwift,
    cnaps: value.Cnaps,
    clabe: value.Clabe,
  };

  return await postAPIcall(ADDBENEFICIARY_BANK, data);
};

export const GetBankBeneficiaryList = async () => {
  return await getAPIcall(VToBLISTING);
};

export const DeleteBankBeneficiary = async (value) => {
  return await deleteAPIcall(
    API_DELETE_BANK_BENEFICIARY + "?BeneficiaryID=" + value
  );
};

export const deleteVBbeneficiary = async (value) => {
  return await getAPIcall(
    API_DELETE_V_TO_VB_BENEFICIARY + "?BeneficiaryID=" + value
  );
};

export const deleteVRetailbeneficiary = async (value) => {
  return await getAPIcall(
    API_DELETE_V_TO_VRETAIL_BENEFICIARY + "?BeneficiaryID=" + value
  );
};

export const VToBAccountFees = async (value) => {
  // ?PayerCurrency=AED&BeneficiaryCurrency=EUR&BeneficiaryBankCountry=GB
  return await getAPIcall(
    API_VTOBACCOUNT_FEES +
      "?PayerCurrency=" +
      value.curCode +
      "&" +
      "BeneficiaryCurrency=" +
      value.currency +
      "&" +
      "BeneficiaryBankCountry=" +
      value.CountryISO
  );
};

export const VTobMakePayment = async (value) => {
  let data = {
    beneficiaryId: value.beneficiaryId.toString(),
    currency: value.curCode,
    BuyCurrencyToken:
      value.currency === value.curCode ? value.publicToken : value.buyCurrToken,
    publicToken: value.publicToken,
    SellCurrencyToken: value.publicToken,
    amount: value.amount.toString(),
    fee: value.FeesAmt.toString(),
    VolFee: value.VolFee.toString(),
    totalAmount: value.totalTransferAmt.toString(),
    reason: value.Reason,
    reference: value.reference,
    exchangeRate: value.exchangeRate.toString(),
    exchangeAmount: value.exchangeAmount.toString(),
    beneficiaryCurrency: value.currency,
    paymentType: value.PaymentType,
    paymentChargeType: value.RadioSelect,
    requestId: value.requestId.toString(),
    reasonCode: value.reasonCode,
    SpecifyReason: value.SpecifyReason,
  };
  return await postAPIcall(API_MAKE_PAYMENT, data);
};

export const makeSelfTreansfer = async (value) => {
  let data = {
    toWalletToken: value.buyCurrencyToken,
    fromWalletToken: value.sellCurrencyToken,
    amount: value.amount,
    currency: value.currency,
    notes: value.note,
  };

  return await postAPIcall(API_MAKE_SELF_TRANSFER, data);
};

export const GetBeneficiary = async (value) => {
  return await getAPIcall(
    API_GET_BENEFICIARY + "?BeneficiaryID=" + value.BeneficiaryID
  );
};

export const UpdateBeneficiaryBank = async (value) => {
  let data = {
    bankAccountHolderName: value.BankAccountHolderName,
    name: value.Name,
    bankCountry: value.BankAccountCountry,
    currency: value.currency,
    email: value.email,
    paymentType: value.PaymentType === undefined ? [] : [value.PaymentType],
    beneficiaryEntityType:
      value.BeneficiaryEntityType === "individual"
        ? "self"
        : value.BeneficiaryEntityType,
    beneficiaryFirstName: value.BeneficiaryFirstName,
    beneficiaryLastName: value.BeneficiaryLastName,
    beneficiaryCompanyName: value.BeneficiaryCompanyName,
    beneficiaryIdentificationType: value.BeneficiaryIdentificationType,
    beneficiaryIdentificationValue: value.BeneficiaryIdentificationValue,
    beneficiaryAddress:
      value.BeneficiaryAddress === "" ? [] : [value.BeneficiaryAddress],
    beneficiaryCity: value.BeneficiaryCity,
    beneficiaryCountry: value.BeneficiaryCountry,
    beneficiaryStateOrProvince: value.BeneficiaryStateOrProvince,
    beneficiaryPostcode: value.BeneficiaryPostcode,
    bankAccountType: value.BankAccountType,
    accountNumber: value.AcctNumber,
    bankName: value.BankName,
    bankCode: value.BankCode,
    branchCode: value.BranchCode,
    bankAddress: [],
    mobileNumber: value.mobileNumber,
    dialInCode: value.dialInCode.toString(),

    sortCode: value.SortCode,
    abaCode: value.Aba,
    bsbCode: value.BsbCode,
    iban: value.Iban,
    bicSwift: value.BicSwift,
    cnaps: value.Cnaps,
    clabe: value.Clabe,
  };

  return await postAPIcall(
    UPDATE_BENEFICIARY_BANK + "?BeneficiaryID=" + value.beneficiaryId,
    data
  );
};
