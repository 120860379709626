import {
  FORGOT_PASSWORD_USER,
  RESET_PASWORD_USER,
  SEND_TOKEN_VERIFICATION,
} from "../../../helper";
import { getAPIcallWithoutAuthorization, postAPIcallWithOutAuthorization } from "Services/apiController";

export const forgotPasswordService = async (data) => {
  return await postAPIcallWithOutAuthorization(FORGOT_PASSWORD_USER, data);
};

export const tokenVerification = async (token = "") => {
  return await getAPIcallWithoutAuthorization(SEND_TOKEN_VERIFICATION + "?fpcode=" + token);
};

export const resetPassword = async (data) => {
  return await postAPIcallWithOutAuthorization(RESET_PASWORD_USER, data);
};
