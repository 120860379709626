export const validation = {
  legel: {
    cname: { min: 3, max: 40 },
    crn: { min: 2, max: 30 },
    link: { min: 0, max: 100 },
    email: { min: 1, max: 50 },
    mobile: { min: 5, max: 15 },
    website: { max: 100 },
    TIN: { min: 1, max: 20 },
    fname: { min: 1, max: 20 },
    mname: { min: 1, max: 25 },
    lname: { min: 1, max: 20 },
    addressLine1: { min: 1, max: 50 },
    addressLine2: { max: 50 },
    city: { min: 1, max: 20 },
    state: { max: 20 },
    pincode: { min: 1, max: 10 },
  },

  shareholderdetail: {
    fname: { min: 1, max: 25 },
    mname: { min: 1, max: 25 },
    lname: { min: 1, max: 25 },
    addressLine1: { min: 1, max: 50 },
    addressLine2: { max: 50 },
    city: { min: 1, max: 20 },
    state: { max: 20 },
    pincode: { min: 1, max: 10 },
    email: { max: 50 },
    mobile: { min: 5, max: 15 },
    sow: { min: 1, max: 200 },
    TIN: { min: 1, max: 20 },
    documentNo: { min: 1, max: 20 },
    cname: { min: 3, max: 40 },
    incorpoNo: { min: 2, max: 30 },
    link: { max: 100 },
    PEPOtherSpecify: {min : 2 , max : 200 }
  },

  businessInfo: {
    description: {
      min: 1,
      max: 500
    },
    businessActivity: {
      min: 1,
      max: 50
    },
    licenceNumber: {
      max: 50
    },
    incomeSources: {
      min: 1,
      max: 200
    },
    investment: {
      min: 1,
      max: 50
    },
    accountPurposeOtherText: {
      min: 1,
      max: 50
    },
  },

  addBusinessInfo: {
    clientBase: {
      min: 1,
      max: 200
    },
    accounts: {
      bankName: {
        min: 1,
        max: 100
      },
      otherPurpose: {
        min: 1,
        max: 50
      }
    },

    incomingPartner: {
      name: {
        max: 100
      },
      companyCode: {
        max: 100
      },
      businessServices: {
        max: 200
      }
    },

    outgoingPartner: {
      name: {
        max: 100
      },
      companyCode: {
        max: 100
      },
      businessServices: {
        max: 200
      }
    },

    socialMediaLink: {
      max: 100
    },

  },

  executiveDetails: {
    firstName: { min: 1, max: 25 },
    middleName: { min: 1, max: 25 },
    lastName: { min: 1, max: 25 },
    PEPOtherSpecify: { min: 1, max: 200 },
    addressLine1: { min: 1, max: 50 },
    addressLine2: { min: 0, max: 50 },
    city: { min: 1, max: 20 },
    state: { min: 0, max: 20 },
    pinCode: { min: 1, max: 10 },
    documentNumber: { min: 1, max: 20 },
  },

  UBODetails: {
    firstName: { min: 1, max: 25 },
    middleName: { min: 1, max: 25 },
    lastName: { min: 1, max: 25 },
    sourceOfWealthOfUBO: { min: 1, max: 200 },
    PEPOtherSpecify: { min: 1, max: 200 },
    TIN: { min: 1, max: 20 },
    addressLine1: { min: 1, max: 50 },
    addressLine2: { min: 0, max: 50 },
    city: { min: 1, max: 20 },
    state: { min: 0, max: 20 },
    pinCode: { min: 1, max: 10 },
    emailAddress: { min: 1, max: 50 },
    mobileNumber: { min: 5, max: 15 },
    documentNumber: { min: 1, max: 20 },
  },

  login: {
    email: { min: 1, max: 50 }
  },

  signup: {
    email: { min: 1, max: 50 },
    mobileNumber: { min: 5, max: 15 },
    referralCode: { max: 15 }
  },

  businessDetails: {
    companyName: { min: 3, max: 40 },
    companyRegistrationNumber: { min: 2, max: 30 },
    businessActivity: { min: 2, max: 50 }
  },

  personalDetails: {
    firstName: { min: 1, max: 20 },
    middleName: { min: 1, max: 25 },
    lastName: { min: 1, max: 20 },
    addressLine1: { min: 1, max: 50 },
    addressLine2: { min: 0, max: 50 },
    city: { min: 1, max: 20 },
    state: { min: 0, max: 20 },
    pinCode: { min: 1, max: 10 },
    PEPOtherSpecify: { min: 1, max: 200 },
  },

  changePassword: {
    oldPassword: {min : 8 , max: 30},
    newPassword: {min : 8 , max: 30},
    confirmPassword: {min : 8 , max: 30}
  },

  addBankBeneficary: {
    mobileno : {min : 5, max : 15}
  }
}

export const addNewEmpcard = {
  fname : {min : 1 , max : 20},
  lname : {min : 1 , max : 20},
  nameOncard : {min : 3 , max: 20},
  mobileNo : {min: 5 , max:15},
  email : {min: 1 , max:50},
  addLine1 : {min : 1 , max: 20},
  pinCode : {min: 1 , max:10},
  addLine2 : { max:50},
  state : {max:20},
  city : { min: 1, max:20}
}

export const mininputLimit = {
  emailpayAndautopay : 3,
  crn: 2,
  businessActivity: 2,
  businessName: 2,
  PoliticallyExposedText: 1,
  email: 3,
  mobile: 4,
  mobileAsNumber: 999,
  fName: 3,
  lName: 3,
  nameAsOnCard: 3,
  addressLine1: 3,
  addressLine2: 3,
  city: 2,
  postCode: 3,
  companyName: 3,
  companyRegNumber: 4,
  taxNumber: 3,
  incorporationNumber: 4,
  sourceOfWealth: 3,
  DocumentNumber: 4,
  clientBase: 2,
  accountBankName: 2,
  description: 2,
  incomeSources: 2,
  PurposeOtherText: 2,
  investment: 2,
};

export const maxinputLimit = {
  emailpayAndautopay : 50,
  /**Transfer */
  NoteField : 200,
  Note: 255,
  SpecifyReason: 255,
  reference: 255,

  /**bank details */
  AcctNumber: 255,
  BankAccountHolderName: 255,
  Ifsc: 255,
  Cnaps: 255,
  Aba: 255,
  SortCode: 255,
  Iban: 255,
  BsbCode: 255,
  InstitutionNo: 255,
  BankCode: 255,
  Clabe: 255,
  BranchCode: 255,
  BicSwift: 255,
  BeneficiaryCompanyName: 255,
  state: 255,
  bankaddressLine1: 100,
  /**other input forms */
  PoliticallyExposedText: 200,
  businessActivity: 50,
  crn: 200,
  email: 75,
  mobile: 15,
  fName: 200,
  lName: 200,
  nameAsOnCard: 20,
  addressLine1: 200,
  addressLine2: 200,
  city: 200,
  postCode: 200,
  companyName: 200,
  companyRegNumber: 20,
  taxNumber: 20,
  incorporationNumber: 20,
  sourceOfWealth: 200,
  DocumentNumber: 20,
  linkToStateInformationSystemOrRegister: 200,
  website: 200,
  ownershipPercentage: 100,
  licenceNumber: 200,
  incomeSources: 200,
  PurposeOtherText: 50,
  investment: 200,
  expectedAnualTurnover: 999999999999.99,
  monthlyVolOfIncomingTransfer: 99999999.99,
  monthlyVolOfOutgoingTransfer: 99999999.99,
  monthlyNumberofIncomingTransactions: 99999999.99,
  monthlyNumberofOutgoingTransactions: 99999999.99,
  clientBase: 200,
  bankName: 200,
  partnerName: 200,
  partnerCompanyCode: 200,
  partnerBusinessServices: 200,
  socialMediaLinks: 200,
  businessDescription: 300,
  publicToken: 10,
  amountLimit: 11,
  amountLimitLength: 9999999.99,
  maxPercentage: 100
};


export const maxinput = {
  city: 21,
  fName: 21,
  lName: 21,
  dob: 11,
  mobile: 26,
  email: 51,
  addressLine1: 51,
  addressLine2: 51,
  postCode: 11,
}
/**
 *
 * @param {*} value
 * @returns `Minimum length should be ${value}`
 */
export const minLimitMsg = (value) => {
  return `Minimum length should be ${value}`;
};

export const maxLimitMsg = (value) => {
  return `Maximum length should be ${value}`;
};