import {
  API_CARD_CHANGES_GROUP,
  API_CARD_STATUS_CHANGE,
  API_CHANGE_CARD_CONTACT_DETAILS,
  API_CHANGE_CARD_LIMIT,
  API_EMP_ACTIVATE_CARD,
  API_GETEMPCARDSETTING,
  EMPCARDREPLACEMENT,
  EMPLOSTSTOLENCARD,
  EMPLOYEECARDPIN,
  EMPLOYEEUPDATEADDRESS,
  EMPPLOYEE_CARD_ACTIVATION_EMAIL,
  GET_EMPLOYEE_CARD_IMAGE,
  TERMINATEEMPCARD,
  PHYSICAL_CARD_FEE,
  VIRTUALCARDRESENDEMAIL,
} from "../../../../helper";
import { getAPIcall, postAPIcall } from "Services/apiController";

export const getEmpCardSetting = async (EmpId = "") => {
  return await getAPIcall(API_GETEMPCARDSETTING + EmpId);
};

export const CardChangeGroups = async (
  settingKey = 0,
  isAllInactive = false,
  businessEmployeeId = "sample"
) => {
  let data = {
    settingKey: settingKey,
    isAllInactive: isAllInactive,
    businessEmployeeId: businessEmployeeId,
  };
  return await postAPIcall(API_CARD_CHANGES_GROUP, data);
};

export const empActivateCard = async (EmpId = "") => {
  return await postAPIcall(API_EMP_ACTIVATE_CARD + EmpId);
};

export const empCardStatusChage = async (EmpId = "", statusCode = 0) => {
  return await postAPIcall(
    API_CARD_STATUS_CHANGE + EmpId + "&StatusCode=" + statusCode
  );
};

export const empChangeCardContactDetails = async (values) => {
  let data = {
    employeeId: values.employeeId,
    emailAddress: values.emailAddress,
    mobileNumber: values.mobileNumber,
    dialInCode: values.dialInCode.toString(),
  };
  return await postAPIcall(API_CHANGE_CARD_CONTACT_DETAILS, data);
};

export const empChangeCardLimit = async (values) => {
  let data = {
    employeeId: values.employeeId,
    limitGroup: values.limitGroup,
    mccGroup: values.mccGroup,
  };
  return await postAPIcall(API_CHANGE_CARD_LIMIT, data);
};

export const getEmployeeCardImage = async (data) => {
  return await getAPIcall(GET_EMPLOYEE_CARD_IMAGE, {
    businessInfoId: data.businessId,
    employeeId: data.employeeId,
  });
};

export const EmployeeResendCardActivationEmail = async (EmployeeId) => {
  return await getAPIcall(EMPPLOYEE_CARD_ACTIVATION_EMAIL, {
    EmployeeId: EmployeeId,
  });
};

export const EmployeeSendCardPIN = async (EmployeeId) => {
  return await getAPIcall(EMPLOYEECARDPIN, { EmployeeId: EmployeeId });
};

export const EMP_Update_Address = async (values, EmpId) => {
  let data = {
    ...values,
    employeeId: EmpId,
  };
  return await postAPIcall(EMPLOYEEUPDATEADDRESS, data);
};
export const Cardreplacement = async (
  businessInfoId,
  individualId,
  publicToken
) => {
  let data = {
    businessInfoId: businessInfoId,
    individualId: individualId,
    publicToken: publicToken,
  };
  return await postAPIcall(EMPCARDREPLACEMENT, data);
};

export const EMPLostStolenCard = async (EmployeeId, publicToken) => {
  return await getAPIcall(EMPLOSTSTOLENCARD, {
    individualId: EmployeeId,
    publicToken: publicToken,
  });
};

export const TerminateCard = async (EmployeeId, publicToken) => {
  return await getAPIcall(TERMINATEEMPCARD, {
    individualId: EmployeeId,
    publicToken: publicToken,
  });
};

export const PhysicalCardFee = async () => {
  return await getAPIcall(PHYSICAL_CARD_FEE, {});
};

export const ResendmailVirtualcard = async (empID) => {
  return await getAPIcall(VIRTUALCARDRESENDEMAIL, { EmployeeId: empID });
};
