export const BankDetails = {
  heading: "Via Bank Transfer",
  Text: "GBP details for LOCAL transfer via either UK Faster Payment or BACS",
  Data: [
    {
      id: "ACCPTCURR",
      label: "Accepted Currency",
      value: "GBP",
      refer: false,
    },
    {
      id: "PAYMETHOD",
      label: "Payment Method",
      value: "UK Faster Payment and BACS",
      refer: false,
    },
    {
      id: "BENEACCNAME",
      label: "Beneficiary Account Name",
      value: "UAB deVer E-Money",
      refer: false,
    },
    {
      id: "BENEACCADDR",
      label: "Beneficiary Account Address",
      refer: false,
      value:
        "Wework Waterhouse Square holborn, EC1N 2SW London, United Kingdom",
    },
    {
      id: "SOFTCODE",
      label: "Soft Code",
      value: "040053",
      refer: false,
    },
    {
      id: "ACCNO",
      label: "Account Number",
      value: "04842099",
      refer: false,
    },
  ],
  Method1: [
    {
      id: "TITLE",
      label: "Title",
      value:
        "GBP details for LOCAL transfers via either UK Faster Payments or BACS transfers:",
      refer: false,
    },
    {
      id: "ACCPTCURR",
      label: "Accepted Currency",
      value: "GBP",
      refer: false,
    },
    {
      id: "PAYMETHOD",
      label: "Payment Method",
      value: "UK Faster Payments and BACS",
      refer: false,
    },
    {
      id: "BENEACCNAME",
      label: "Beneficiary Account Name",
      value: "UAB deVere E-Money",
      refer: false,
    },
    {
      id: "BNKNAME",
      label: "Bank Name",
      value: "PayrNet Ltd.",
      refer: false,
    },
    {
      id: "BNKADDRESS",
      label: "Bank Address",
      value:
        "Wework Waterhouse Square Holborn, EC1N 2SW London, United Kingdom",
      refer: false,
    },
    {
      id: "SOFTCODE",
      label: "Sort Code",
      value: "040053",
      refer: false,
    },
    {
      id: "ACCNUMBER",
      label: "Account Number",
      value: "04842099",
      refer: false,
    },
    {
      id: "BENEACCADDR",
      label: "Beneficiary Address",
      refer: false,
      value: "Mesiniu g. 5, LT-01133 Vilnius, Lithuania",
    },
  ],
  Method2: [
    {
      id: "TITLE",
      label: "Title",
      value: "GBP details for SWIFT transfers :",
      refer: false,
    },
    {
      id: "ACCPTCURR",
      label: "Accepted Currency",
      value: "GBP",
      refer: false,
    },
    {
      id: "PAYMETHOD",
      label: "Payment Method",
      value: "SWIFT for GBP ",
      refer: false,
    },
    {
      id: "BNKNAME",
      label: "Bank Name",
      value: "AS LHV Pank",
      refer: false,
    },
    {
      id: "BNKADDRESS",
      label: "Bank Address",
      value: "Tartu mnt 2, 10145 Tallinn, Estonia",
      refer: false,
    },
    {
      id: "BNKSWIFTCODE",
      label: "Bank BIC/SWIFT Code",
      value: "LHVBEE22",
      refer: false,
    },
    {
      id: "BENEACCNAME",
      label: "Beneficiary Account Name",
      value: "PayrNet Limited",
      refer: false,
    },
    {
      id: "BENEIBAN",
      label: "Beneficiary IBAN",
      value: "EE967700771002997385",
      refer: false,
    },

    {
      id: "BENEACCADDR",
      label: "Beneficiary Address",
      refer: false,
      value:
        "Wework Waterhouse Square Holborn, EC1N 2SW London, United Kingdom",
    },
  ],
  Method3: [
    {
      id: "TITLE",
      label: "Title",
      value:
        "Your bank details to receive funds into your Vault Business wallet.",
      refer: false,
    },
    {
      id: "ACCPTCURR",
      label: "Currency",
      value: "EUR",
      refer: false,
    },
    {
      id: "BNKNAME",
      label: "Bank Name",
      value: "AS LHV Pank",
      refer: false,
    },
    {
      id: "BENEACCNAME",
      label: "Beneficiary Account Name",
      value: "PayrNet Limited",
      refer: false,
    },
    {
      id: "BENEIBAN",
      label: "Beneficiary IBAN",
      value: "GB29NWBK60161331926856",
      refer: false,
    },
    {
      id: "BNKSWIFTCODE",
      label: "Beneficiary BIC/SWIFT Code",
      value: "BUKFGB78",
      refer: false,
    },
  ],
};

export const NoteMethod1 = [
  {
    para: `You can only transfer funds to the bank account above in GBP from another bank within the UK. We do not charge a fee to receive a GBP payment made from a bank within UK, but please be aware that your bank might charge a fee for a UK Faster Payments transfer. If you wish to send GBP from a bank that is not within UK, you must make a SWIFT transfer to the bank account detailed below. `,
  },
  {
    para: `Note 1: PayrNet Ltd is a London based Fintech banking platform regulated by the UK FCA. They provide a range of client collection and storage bank accounts to deVere E-Money Vault clients using a selection of specialist European-based banking partners including Starling bank, Crédit Mutuel Arkéa, LHV Bank, BFC Bank and Barclays Bank plc.`,
  },
  {
    para: `Note 2: For us to check the status of a bank transfer load, the Vault support team will require a proof of payment with all the details included in the transfer. This can be done by contacting us via Live Chat(Vault app → Support → Live Chat) or email at support@devere - vault.com.`,
  },
];

export const NoteMothod2 = [
  {
    para: `We do not charge a fee to receive a SWIFT payment but please be aware that your bank will charge you a fee for a SWIFT transfer. If you wish to send GBP from a bank that is within SEPA, you should avoid making a SWIFT payment and should instead make a SEPA Credit Transfer. We do not charge a fee to receive a GBP payment made as a SEPA Credit Transfer, but please be aware that your bank might charge a small fee of approximately £2.`,
  },
  {
    para: `Note 1: PayrNet Ltd is a London based Fintech banking platform regulated by the UK FCA. They provide a range of client collection and storage bank accounts to deVere E-Money Vault clients using a selection of specialist European-based banking partners including Starling bank, Crédit Mutuel Arkéa, LHV Bank, BFC Bank and Barclays Bank plc.`,
  },
  {
    para: `Note 2: For us to check the status of a bank transfer load, the Vault support team will require a proof of payment with all the details included in the transfer. This can be done by contacting us via Live Chat(Vault app → Support → Live Chat) or email at support@devere - vault.com. `,
  },
];

export const NoteMothod3 = [
  {
    para: `We do not charge a fee to receive a SWIFT payment but please be aware that your bank will charge you a fee for a SWIFT transfer. If you wish to send EUR from a bank that is within SEPA, you should avoid making a SWIFT payment and should instead make a SEPA Credit Transfer. We do not charge a fee to receive a EUR payment made as a SEPA Credit Transfer, but please be aware that your bank might charge a small fee of approximately €2.`,
  },
  {
    para: `Note 1: PayrNet Ltd is a London based Fintech banking platform regulated by the UK FCA. They provide a range of client collection and storage bank accounts to deVere E-Money Vault clients using a selection of specialist European-based banking partners including Starling bank, Crédit Mutuel Arkéa, LHV Bank, BFC Bank and Barclays Bank plc.`,
  },
  {
    para: `Note 2: For us to check the status of a bank transfer load, the Vault support team will require a proof of payment with all the details included in the transfer. This can be done by contacting us via Live Chat(Vault app → Support → Live Chat) or email at support@devere - vault.com.`,
  },
];

export const BankTextCopyToClipboard = `
GBP details for LOCAL transfer via either UK Faster Payment or BACS
Accepted Currency: GBP,
Payment Method: UK Faster Payment and BACS,
Beneficiary Account Name: UAB deVer E-Money,
Beneficiary Account Address: Wework Waterhouse Square holborn, EC1N 2SW London, United Kingdom,
Soft Code: 040053,
Account Number: 04842099
`;
export const BankTransferTextMethod1 = `
GBP details for LOCAL transfers via either UK Faster Payments or BACS transfers:",
Accepted Currency: GBP,
Payment Method: UK Faster Payments and BACS,
Beneficiary Account Name: UAB deVere E-Money,
Bank Name:PayrNet Ltd.,
Bank Address: Wework Waterhouse Square Holborn, EC1N 2SW London, United Kingdom
Sort Code: 040053,
Account Number: 04842099,
Beneficiary Address: Mesiniu g. 5, LT-01133 Vilnius, Lithuania
`;
export const BankTransferTextMethod2 = `
GBP details for SWIFT transfers,
Accepted Currency: GBP,
Payment Method: SWIFT for GBP ,
Bank Name: AS LHV Pank,
Bank Address: Tartu mnt 2, 10145 Tallinn, Estonia,
Bank BIC/SWIFT Code: LHVBEE22,
Beneficiary Account Name: PayrNet Limited, 
Beneficiary IBAN: EE967700771002997385 ,
Beneficiary Address: Wework Waterhouse Square Holborn, EC1N 2SW London, United Kingdom
`;

export const BankTransferTextMethod3 = `
EUR details for SWIFT transfers and LOCAL EUR SEPA Credit Transfers:",
Accepted Currency: EUR,
Payment Method: SWIFT for EUR and SEPA for EUR,
Bank Name: AS LHV Pank,
Bank Address: Tartu mnt 2, 10145 Tallinn, Estonia,
Bank BIC/SWIFT Code:LHVBEE22,
Beneficiary Account Name:PayrNet Limited,
Beneficiary IBAN: EE967700771002997385,
Beneficiary Address : Wework Waterhouse Square Holborn, EC1N 2SW London, United Kingdom
`;
