import { KEYS_FOR_LOCALSTORAGE } from "../configs/pageConst";
import { decryptData, encryptData } from "../utility/EncDec";

/**
 * for save locastorage single value and return data
 * @param {*} name
 * @param {*} data
 * @returns data
 */
export const setlocalstorage = (name, data) => {
  let encData = encryptData(data)
  if (localStorage.getItem(name)) {
    localStorage.removeItem(name);
    localStorage.setItem(name, encData);
  } else {
    localStorage.setItem(name, encData);
  }
  return data;
};

/**
 * for save locastorage JSON data and return JSON data
 * @param {*} name
 * @param {*} data
 * @returns {*} data
 */
export const setlocalstorageJSON = (name, data) => {
  let encData = encryptData(JSON.stringify(data))

  if (localStorage.getItem(name)) {
    localStorage.removeItem(name);
    localStorage.setItem(name, encData);
  } else {
    localStorage.setItem(name, encData);
  }
  return data;
};

/**
 * for save locastorage single value and return void
 * @param {*} name
 * @param {*} data
 */
export const voidsetlocalstorage = (name, data) => {
  data = encryptData(data)
  if (localStorage.getItem(name)) {
    localStorage.removeItem(name);
    localStorage.setItem(name, data);
  } else {
    localStorage.setItem(name, data);
  }
};

export const voidsetRefreshToken = (name, data) => {
  data = encryptData(data)
  if (localStorage.getItem(name)) {
    localStorage.removeItem(name);
    localStorage.setItem(name, data);
  } else {
    localStorage.setItem(name, data);
  }
};

/**
 *if locastorage has access token then return true
 * @returns {boolean} boolean
 */
export function isLogin() {
  if (localStorage.getItem(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)) {
    return true;
  } else {
    return false;
  }
}

/**
 * for get locastorage return data
 * @param {*} name
 * @returns data
 */
export const getlocalstorage = (name) => {
  let localData = localStorage.getItem(name);
  if (localData) {
    return decryptData(localData)
  } else {
    return null;
  }
};

/**
 * for remove locastorage return voind
 * @param {*} name
 * @returns data
 */
export const removelocalstorage = (name) => {
  localStorage.removeItem(name);
};

/**
 * for clear locastorage return voind
 * @returns 
 */
export const clearlocalstorage = () => {
  return localStorage.clear();
};