const initialState = {
  loading: true,
  emailStatus: true,
  error: "",
  email: "",
  referralCode: "",
  preRegistrationId: "",
  // tandc1: false,
  // tnadc: true,
  tandc1: false,
  tandc2: false,
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_EMAIL_EXISTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "IS_EMAIL_EXISTS_SUCCESS":
      return {
        ...state,
        loading: false,
        emailStatus: action.payload,
        error: "",
      };
    case "IS_EMAIL_EXISTS_FAILURE":
      return {
        ...state,
        loading: false,
        emailStatus: "",
        error: action.payload,
      };
    case "FETCH_EMAIL_UUID_REFCODE":
      return {
        ...state,
        loading: false,
        email: action.payload.email,
        referralCode: action.payload.referralCode,
        preRegistrationId: action.payload.preRegistrationId,
        tandc1: action.payload.tandc1,
        tandc2: action.payload.tandc2,
      };
    case "INIT_SIGNUP_EMAIL":
      return initialState;
    default:
      return state;
  }
};

export default emailReducer;
