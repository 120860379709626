import { ErrMsg } from "../ErrorMsg";

const { requiredMsg } = ErrMsg;

export const DocsFields = {
  DocumentType: requiredMsg("Document Type"),
  IssuingCountryId: requiredMsg("Issuing Country of the Document"),
  // DateOfExpiry: "Date of Expiry can not be before Date of Issue.",
  DocumentNumber: requiredMsg("Document Number"),
  DateOfIssue: requiredMsg("Issue Date"),
  DateOfExpiry : requiredMsg("Expiry Date")
};

export const DocumentDefault_RAW = {
  head: {
    imagesrc: "",
    type: "",
    heading: "",
    para: "",
  },
  file: {
    name: "",
    Namedata: "",
  },
  error: {
    Text: "",
  },
};
export const POI_RAW = {
  POI: {
    head: {
      type: "",
      imagesrc: "POI",
      heading: "Proof of Identity (POI)*",
      para: "(Both sides if applicable)",
    },
    POIF: {
      file: {
        name: "proofidentityfront",
        Namedata: "Add Front Side",
      },
      error: {
        Text: requiredMsg("Proof of Identity (Front Side)"),
      },
    },
    POIB: {
      file: {
        name: "proofidentityback",
        Namedata: "Add Back Side",
      },
      error: {
        Text: requiredMsg("Proof of Identity (Back Side)"),
      },
    },
  },
};
export const POA_RAW = {
  POA: {
    ...DocumentDefault_RAW,
    head: {
      imagesrc: "undraw",
      heading: "Proof of Address (POA)*",
      para: "(Copy of Utility bill not older than 3 Months)",
    },
    file: {
      name: "POA",
      Namedata: "Add File",
    },
    error: {
      Text: requiredMsg("Proof of Address"),
    },
  },
};

export const POAT_RAW = {
  POAT: {
    ...DocumentDefault_RAW,
    head: {
      imagesrc: "POAT",
      type: "POAT",
      heading: "Power of Attorney*",
      para: "OR equivalent document proving that you have legal rights to represent the company",
    },
    file: {
      name: "DocumentData",
      Namedata: "Add File",
    },
    error: {
      Text: requiredMsg("Power of Attorney"),
    },
  },
};
export const PSOF_RAW = {
  POSF: {
    ...DocumentDefault_RAW,
    head: {
      imagesrc: "SOF",
      type: "POSF",
      heading: "Proof of Source of Funds/Wealth*",
      para: "(If UBO Selected)",
    },
    file: {
      name: "POSF",
      Namedata: "Add File",
    },
    error: {
      Text: requiredMsg("Proof of Source of Funds/Wealth"),
    },
  },
};
export const DocSubmitPanel_RAW = {
  DocSubmitPanel: {
    isStatusBarShow: true,
    pagename: "",
    totalpage: 0,
    pagenumber: 0,
    textaligndata: "left",
    heading: "",
    para: "",
    BCI1: "",
    BCI2: "",
    BCI3: "",
    typenext: "submit",
  },
};
