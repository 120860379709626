const initialState = {
  loading: true,
  beneficiaryName: '',
  isBusiness: null,
  userID: null,
  formData: [],
  beneficiaryId: null,
  beneficiaryEmail: null,
  transactions: [],
  temail: '',
  tname: '',
  SelectedCountry: '',
  CountryIndex: '',
  publicToken: null,
  currencyAccountName: null,
  currCode: null,
  isMulticurrency: false,
  showPopUp: false,
  isUpdateState: false,
};

const TransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TRANSFER_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'TRANSFER_SUCCESS':
      return {
        ...state,
        loading: false,
        emailStatus: action.payload,
        error: '',
      };
    case 'TRANSFER_FAILURE':
      return {
        ...state,
        loading: false,
        emailStatus: '',
        error: action.payload,
      };
    case 'ADD_VTOV_ADDBENEFICIARY_ACTION':
      return {
        ...state,
        beneficiaryName: action.payload.beneficiaryName,
        isBusiness: action.payload.isBusiness,
        userID: action.payload.userID,
        beneficiaryEmail: action.payload.email,
        publicToken: action.payload.publicToken,
        currencyAccountName: action.payload.accountCurrencyName,
        currCode: action.payload.currency,
        isMulticurrency: action.payload.isMultiCurrency,
        showPopUp: true,
      };
    case 'ADD_VTOV_ADDBENEFICIARY_FROM_TABLE_ACTION':
      return {
        ...state,
        beneficiaryName: action.payload.beneficiaryName,
        isBusiness: action.payload.isBusiness,
        beneficiaryId: action.payload.beneficiaryId,
        beneficiaryEmail: action.payload.beneficiaryEmail,
        publicToken: action.payload.token,
        currencyAccountName: action.payload.accountCurrencyName,
        currCode: action.payload.curCode,
        isMulticurrency: !action.payload.isMVC,
        showPopUp: true,
      };
    case 'ADD_VTOBANK_ADDBENEFICIARY_FROM_TABLE_ACTION':
      return {
        ...state,
        beneficiaryEntityType: action.payload.beneficiaryEntityType,
        beneficiaryId: action.payload.beneficiaryId,
        country: action.payload.country,
        currency: action.payload.currency,
        fieldName1: action.payload.fieldName1,
        fieldName2: action.payload.fieldName2,
        fieldValue1: action.payload.fieldValue1,
        fieldValue2: action.payload.fieldValue2,
        name: action.payload.name,
        EntityType: undefined,
      };
    case 'ADD_VTOBANK_ADDBENEFICIARY_ACTION':
      return {
        ...state,
        formData: action.payload.formData,
        currencyCode: action.payload.currencyCode,
        iso: action.payload.iso,
        BankAccountCountry: action.payload.BankAccountCountry,
        currency: action.payload.currency,
        countryName: action.payload.countryName,
        currencySymbol: action.payload.currencySymbol,
        CountryIndex: action.payload.CountryIndex,
      };
    case 'ADD_VTOBANK_NO_ADDBENEFICIARY_ACTION':
      return {
        ...state,
        EntityType: action.payload.EntityType,
      };

    case 'VTOB_ACCOUNT_TRANSFER_ACTION':
      return {
        ...state,
        sellCurrency: action.payload.currency,
        BeneficiaryCurrency: action.payload.BeneficiaryCountry,
        BeneficiaryCountry: action.payload.BeneficiaryCurrency,
        beneficiaryId: action.payload.beneficiaryId,
        beneficiaryName: action.payload.beneficiaryName,
        CurrencyOptions: action.payload.CurrencyOptions,
        balance: action.payload.balance,
        currencySymbol: action.payload.currencySymbol,
        beneficiarycurrencySymbol: action.payload.beneficiarycurrencySymbol,
        Country: action.payload.Country,
        currency: action.payload.currency,
        curCode: action.payload.curCode,
        amount: action.payload.amount,
        Reason: action.payload.Reason,
        SpecifyReason: action.payload.SpecifyReason,
        RadioSelect: action.payload.RadioSelect,
        ReasonOptions: action.payload.ReasonOptions,
        reference: action.payload.reference,
        frequencyType: action.payload.frequencyType,
      };

    case 'AUTO_VTOB_ACCOUNT_TRANSFER_ACTION':
      return {
        ...state,

        beneficiaryName: action.payload.beneficiaryName,
        CurrencyOptions: action.payload.CurrencyOptions,
        balance: action.payload.balance,
        currencySymbol: action.payload.currencySymbol,
        beneficiarycurrencySymbol: action.payload.beneficiarycurrencySymbol,
        Country: action.payload.Country,
        currency: action.payload.currency,
        curCode: action.payload.curCode,
        amount: action.payload.amount,
        Reason: action.payload.Reason,
        SpecifyReason: action.payload.SpecifyReason,
        RadioSelect: action.payload.RadioSelect,
        ReasonOptions: action.payload.ReasonOptions,
      };

    case 'ADD_VTOBANK_ADDBENEFICIARY_RESPONSE_ACTION':
      return {
        ...state,
        beneficiaryId: action.payload.beneficiaryId,
      };

    case 'PUT_USER_NAVIGATION_STATUS':
      return {
        ...state,
        isUpdateState: action.payload.isUpdateState,
      };
    case 'CLEAR_REDUX_ADDBENIFICIARY_ACTION':
      return initialState;
    case 'CLEAR_REDUX_ADDBENIFICIARY_ACTION_EXCEPT_TYPE':
      return { ...initialState, EntityType: state.EntityType };
    default:
      return state;
  }
};

export default TransferReducer;
