import {
  ShareHolderDetailPersonService,
  // DocumentUploadPerson,
} from "../../../Services/docSubmitServices";
// const moment = require("moment");

export const fetch_ShareHolderDetail_Person = (payload) => {
  return {
    type: "FETCH_SHAREHOLDERDETAIL_PERSON",
    payload,
  };
};

export const init_ShareHolderPerson = () => {
  return {
    type: "INIT_SHAREHOLDERPERSON",
  };
};
export const setShaholderPersonReject = (isRejected, isnewData) => {
  let payload = {
    isRejected: isRejected,
    isnewData: isnewData,
  };
  return {
    type: "SHAREHOLDER_PERSON_REJECT",
    payload,
  };
};

export const fetch_ShareHolderDetail_Doc_Person = (data) => {
  return {
    type: "FETCH_SHAREHOLDERDETAIL_DOC_PERSON",
    payload: data,
  };
};
const shareHolderDetail_Person_Success = (shareholderData) => {
  return {
    type: "SET_SHAREHOLDERDETAIL_PERSON_SUCCESS",
    payload: shareholderData,
  };
};

const shareHolderDetail_Person_Failure = (error) => {
  return {
    type: "SET_SHAREHOLDERDETAIL_PERSON_FAILURE",
    payload: error,
  };
};

export const ShareHolderDetailPersonAction = (data) => {
  return (dispatch) => {
    // dispatch(fetch_ShareHolderDetail_Person(data));
    return new Promise(function (resolve, reject) {
      ShareHolderDetailPersonService(data)
        .then((response) => {
          dispatch(shareHolderDetail_Person_Success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(shareHolderDetail_Person_Failure(error));
          reject(error);
        });
      // let proofidentityfront = {
      //   UID: "109829",
      //   IsIndividual: true,
      //   DocumentType: data.DocumentType + `_${moment().format("x")}`,
      //   DocumentNumber: data.DocumentNumber,
      //   DocumentMasterId: "2",
      //   DocumentData: data.proofidentityfront1,
      //   IssuingCountryId: data.IssuingCountryId,
      //   DateOfIssue: data.DateOfIssue,
      //   DateOfExpiry: data.DateOfExpiry,
      //   isPOI: true,
      // };
      // DocumentUploadPerson(proofidentityfront)
      //   .then((response) => {
      //     dispatch(documentdata_POIF_Success(response.data));
      //     resolve(response.data);
      //   })
      //   .catch((error) => {
      //     dispatch(documentdata_POIF_Failure(error));
      //     reject(error);
      //   });
      // let proofidentityback = {
      //   UID: "109829",
      //   IsIndividual: true,
      //   DocumentType: data.DocumentType + `_${moment().format("x")}`,
      //   DocumentNumber: data.DocumentNumber,
      //   DocumentMasterId: "3",
      //   DocumentData: data.proofidentityfront1,
      //   IssuingCountryId: data.IssuingCountryId,
      //   DateOfIssue: data.DateOfIssue,
      //   DateOfExpiry: data.DateOfExpiry,
      //   isPOI: true,
      // };
      // DocumentUploadPerson(proofidentityback)
      //   .then((response) => {
      //     dispatch(documentdata_POIB_Success(response.data));
      //     resolve(response);
      //   })
      //   .catch((error) => {
      //     dispatch(documentdata_POIB_Failure(error));
      //     reject(error);
      //   });
      // let POA = {
      //   UID: "109829",
      //   IsIndividual: true,
      //   DocumentType: "POA" + `_${moment().format("x")}`,
      //   DocumentMasterId: "1",
      //   DocumentData: data.POA1,
      //   isPOI: false,
      // };
      // DocumentUploadPerson(POA)
      //   .then((response) => {
      //     dispatch(documentdata_POA_Success(response.data));
      //     resolve(response);
      //   })
      //   .catch((error) => {
      //     dispatch(documentdata_POA_Failure(error));
      //     reject(error);
      //   });
      // if (data.isUbo === "one") {
      //   let SOF = {
      //     UID: "109829",
      //     IsIndividual: true,
      //     DocumentType: "SOF" + `_${moment().format("x")}`,
      //     DocumentMasterId: "5",
      //     DocumentData: data.POSF1,
      //     isPOI: false,
      //   };
      //   DocumentUploadPerson(SOF)
      //     .then((response) => {
      //       dispatch(documentdata_POSF_Success(response.data));
      //       resolve(response);
      //     })
      //     .catch((error) => {
      //       dispatch(documentdata_POSF_Failure(error));
      //       reject(error);
      //     });
      // }
    });
  };
};
