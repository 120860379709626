import { combineReducers } from "redux";

import emailReducer from "./EmailReducer";
import emailVerificationReducer from "./EmailVerificationReducer";
import personalDetailsReducer from "./PersonalDetailsReducers";
import termsAndConditionReducer from "./TermsAndConditionsReducers";
import mobNoReducer from "./mobNoSignupReducer";
import otpReducer from "./verfyotpReducer";
import loginReducer from "./loginReducer";
import businessDetailsReducer from "./BusinessDetailsReducer";
import setPasswordReducer from "./SetPasswordReducer";
import GDPRDetailsReducer from "./GDPRReducers";

const pagesReducer1 = combineReducers({
  emailpage: emailReducer,
  emailver: emailVerificationReducer,
  personaldetails: personalDetailsReducer,
  tandc: termsAndConditionReducer,
  mobno: mobNoReducer,
  otp: otpReducer,
  login: loginReducer,
  businessdetails: businessDetailsReducer,
  setPassword: setPasswordReducer,
  gdpr: GDPRDetailsReducer,
});

const pagesReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") state = undefined;

  return pagesReducer1(state, action);
};

export default pagesReducer;
