import { combineReducers } from "redux";

import ProfileReducer from "./ProfileReducer";
import ExchangeReducer from "./ExchangeReducer";
import TransferReducer from "./TransferReducer";
import CardsReducer from "./CardsReducer";
import BulkPaymentReducer from "./BulkPaymentReducer";
import funsampleReducer from "./funsampleReducer";
import AutopaymentVtoVReducer from "./AutopaymentVtoVReducer";
import EmpTransReducer from "./EmpTransactionReaducer";
import AutopaymentVtoRReducer from "./AutopaymentVtoRReducer";
import AutoPayVtoBankReduser from "./AutoPayVtoBankReduser";

const dashboardReducer = combineReducers({
  profile: ProfileReducer,
  exchange: ExchangeReducer,
  transfer: TransferReducer,
  autoVtoV: AutopaymentVtoVReducer,
  autoVToB: AutoPayVtoBankReduser,
  autoVtoR: AutopaymentVtoRReducer,
  cards: CardsReducer,
  bulkpayment: BulkPaymentReducer,
  funsample: funsampleReducer,
  emptrans: EmpTransReducer,
});

export default dashboardReducer;
