import { uuidGenerator } from "../../../helper";

const initialState = {
  loading: false,
  destindex: [],
  error: "",
  businessInfoId: "",
  description: "",
  activity: "",
  isProhibitedBussinessCategoryConfirmed: true,
  licenceNumber: "",
  incomeSources: "",
  isPassive: "one", // true,
  accountPurpose: "",

  purposeName: "",
  purposeIndex: null,
  isOtherPurpose: false,
  accountPurposeOtherText: "",
  isinvestment: false,
  investment: "",

  expectedAnualTurnover: "",
  monthlyVolOfIncomingTransfer: "",
  monthlyVolOfOutgoingTransfer: "",
  monthlyNumberofOutgoingTransactions: "",
  monthlyNumberofIncomingTransactions: "",

  destinationCountriesAndCounterparties: "",
  // businessactivity: "",
  clientBase: "",
  // website: "",
  calladdBusinessApi: true,
  callbusinessapi: true,
  accounts: [
    {
      bankName: "",
      bankCountryId: null,
      index: null,
      id: uuidGenerator().toString(),
      PurposeofCurrentAccountId: null,
      purposeIndex: null,
      isOtherpurpose: false,
      otherPurpose: "",
    },
  ],

  incomingPartners: [
    {
      id: uuidGenerator().toString(),
      index: null,
      name: "",
      companyCode: "",
      countryId: null,
      businessServices: "",
      countryofIncorporation: null,
      countryIncropIndex: null,
      transactionType: "Incoming",
    },
  ],
  outgoingPartners: [
    {
      id: uuidGenerator().toString(),
      index: null,
      name: "",
      companyCode: "",
      countryId: null,
      businessServices: "",
      countryofIncorporation: null,
      countryIncropIndex: null,
      transactionType: "Outgoing",
    },
  ],
  socialMediaLinks: [{ Links: "", id: 0 }],
};

const businessInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BUSINESS_INFO_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_BUSINESS_INFO_SUCCESS":
      return {
        ...state,
        loading: false,
        error: "",
      };
    case "GET_BUSINESS_INFO_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "FETCH_BUSINESS_INFO":
      return {
        ...state,
        loading: false,
        callbusinessapi: action.payload.callbusinessapi,
        description: action.payload.description,
        isProhibitedBussinessCategoryConfirmed:
          action.payload.isProhibitedBussinessCategoryConfirmed,
        // businessactivity: action.payload.businessactivity,
        activity: action.payload.activity,

        licenceNumber: action.payload.licenceNumber,
        incomeSources: action.payload.incomeSources,
        isPassive: action.payload.isPassive,
        accountPurpose: action.payload.accountPurpose,

        purposeName: action.payload.purposeName,
        purposeIndex: action.payload.purposeIndex,
        isOtherPurpose: action.payload.isOtherPurpose,
        accountPurposeOtherText: action.payload.accountPurposeOtherText,
        isinvestment: action.payload.isinvestment,
        investment: action.payload.investment,
      };
    case "BUSINESS_INFO_ADD_DATA":
      return {
        ...state,
        loading: false,
        calladdBusinessApi: action.payload.calladdBusinessApi,
        expectedAnualTurnover: action.payload.expectedAnualTurnover,
        monthlyVolOfIncomingTransfer:
          action.payload.monthlyVolOfIncomingTransfer,
        monthlyVolOfOutgoingTransfer:
          action.payload.monthlyVolOfOutgoingTransfer,
        monthlyNumberofIncomingTransactions:
          action.payload.monthlyNumberofIncomingTransactions,
        monthlyNumberofOutgoingTransactions:
          action.payload.monthlyNumberofOutgoingTransactions,
        destinationCountriesAndCounterparties:
          action.payload.destinationCountriesAndCounterparties,
        destindex: action.payload.destindex,
        clientBase: action.payload.clientBase,
        // website: action.payload.website,
        accounts: action.payload.accounts,
        incomingPartners: action.payload.incomingPartners,
        outgoingPartners: action.payload.outgoingPartners,
        socialMediaLinks: action.payload.socialMediaLinks,
      };

    case "INIT_BUSINESS":
      return initialState;
    default:
      return state;
  }
};

export default businessInfoReducer;
