// import { ACTIONS } from "react-joyride";
// import { actions } from "react-table";
import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import { setlocalstorage } from "../../../helper";

const initialState = {
  loading: false,
  updatestate: false,
  posterror: "",
  postsuccessmsg: "",
  geterror: "",
  getsuccessmsg: "",
  businessInfoId: "",
  companyName: "",
  legalEntityForm: null,
  countryId: null,
  companyIncorportionDate: null,

  companyRegNumber: "",
  businessSectorMasterId: 2,
  linkToStateInformationSystemOrRegister: "",
  dialInCode: null,
  emailAddress: "",
  mobileNumber: "",
  website: "",
  contactPerson: {
    isPreRegPerson: false,
    firstName: "",
    middleName: "",
    lastName: "",
    dialInCode: "",
    emailAddress: "",
    mobileNumber: "",
  },
  taxes: [{ taxNumber: null, taxCountryId: null, id: null, index: null }],

  index: null,
  registrationAddressLineOne: "",
  registrationAddressLineTwo: "",
  registrationCity: "",
  registrationState: "",
  registrationCountry: null,
  registrationPostalCode: "",
  correspondencesameasregistration: false,
  correspondenceAddressLineOne: "",
  correspondenceAddressLineTwo: "",
  correspondenceCity: "",
  correspondenceState: "",
  correspondenceCountry: null,
  correspondencePostalCode: "",
  operatingsameasregistration: false,
  operatingAddressLineOne: "",
  operatingAddressLineTwo: "",
  operatingCity: "",
  operatingState: "",
  operatingCountry: null,
  operatingPostalCode: "",

  indexone: null,
  countryindex: null,
  dialInCodeindex: null,
  isApiCall: true,
  legalAddressApiCall: true,
  countryindex1: null,
  countryindex2: null,
  countryindex3: null,
  isSoleEnterpriseLocal: false,
};

const LegalEntityBusinessDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_LEGAL_ENTITY_BUSINESS_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "POST_LEGAL_ENTITY_BUSINESS_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        postsuccessmsg: action.payload,

        posterror: "",
      };
    case "POST_LEGAL_ENTITY_BUSINESS_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        posterror: action.payload,
      };
    case "GET_LEGAL_ENTITY_BUSINESS_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_LEGAL_ENTITY_BUSINESS_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        // getsuccessmsg: action.payload,

        businessInfoId: setlocalstorage(
          KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID,
          action.payload.businessInfoId
        ),
        companyName: action.payload.companyName,
        legalEntityForm: action.payload.legalEntityForm,
        countryId: action.payload.countryId,
        companyIncorportionDate: action.payload.companyIncorportionDate,
        companyRegNumber: action.payload.companyRegNumber,
        businessSectorMasterId: action.payload.businessSectorMasterId,
        linkToStateInformationSystemOrRegister:
          action.payload.linkToStateInformationSystemOrRegister,
        dialInCode: action.payload.dialInCode,
        emailAddress: action.payload.emailAddress,
        mobileNumber: action.payload.mobileNumber,
        website: action.payload.website,
        isApiCall: action.payload.isApiCall,
        contactPerson: {
          ...state.contactPerson,
          isPreRegPerson: action.payload.contactPerson.isPreRegPerson,
          firstName: action.payload.contactPerson.firstName,
          middleName: action.payload.contactPerson.middleName,
          lastName: action.payload.contactPerson.lastName,
          dialInCode: action.payload.contactPerson.dialInCode,
          emailAddress: action.payload.contactPerson.emailAddress,
          mobileNumber: action.payload.contactPerson.mobileNumber,
        },
        taxes: action.payload.taxes,
        businessAddresses: action.payload.businessAddresses,
        geterror: "",
      };
    case "GET_LEGAL_ENTITY_BUSINESS_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        geterror: action.payload,
      };
    case "FETCH_LEGAL_ENTITY_BUSINESS_DETAILS":
      return {
        ...state,
        loading: false,
        isApiCall: action.payload.isApiCall,
        updatestate: action.payload.updatestate,
        companyName: action.payload.companyName,
        legalEntityForm: action.payload.legalEntityForm,
        countryId: action.payload.countryId,
        companyIncorportionDate: action.payload.companyIncorportionDate,
        companyRegNumber: action.payload.companyRegNumber,
        businessSectorMasterId: action.payload.businessSectorMasterId,
        linkToStateInformationSystemOrRegister:
          action.payload.linkToStateInformationSystemOrRegister,
        dialInCode: action.payload.dialInCode,
        emailAddress: action.payload.emailAddress,
        mobileNumber: action.payload.mobileNumber,
        website: action.payload.website,
        contactPerson: {
          ...state.contactPerson,
          firstName: action.payload.contactPerson.firstName,
          middleName: action.payload.contactPerson.middleName,
          lastName: action.payload.contactPerson.lastName,
          dialInCode: action.payload.contactPerson.dialInCode,
          emailAddress: action.payload.contactPerson.emailAddress,
          mobileNumber: action.payload.contactPerson.mobileNumber,
          isPreRegPerson: action.payload.contactPerson.isPreRegPerson,
        },
        taxes: action.payload.taxes,
        isSoleEnterpriseLocal: action.payload.isSoleEnterpriseLocal,
        index: action.payload.index,
        indexone: action.payload.indexone,
        countryindex: action.payload.countryindex,
        dialInCodeindex: action.payload.dialInCodeindex,

        // businessAddresses: [
        //   {
        //     businessInfoId: action.payload.businessAddresses.businessInfoId,
        //     addressTypeId: action.payload.businessAddresses.addressTypeId,
        //     country: action.payload.businessAddresses.country,
        //     city: action.payload.businessAddresses.city,
        //     postalCode: action.payload.businessAddresses.postalCode,
        //     addressLine1: action.payload.businessAddresses.addressLine1,
        //     addressLine2: action.payload.businessAddresses.addressLine2,
        //     state: action.payload.businessAddresses.state,
        //     isSameAsRegAddress:
        //       action.payload.businessAddresses.isSameAsRegAddress,
        //   },
        // ],
      };
    case "FETCH_LEGAL_ENTITY_BUSINESS_ADDRESS":
      return {
        ...state,
        loading: false,
        // businessAddresses: action.payload,
        businessInfoId: action.payload.businessInfoId,
        legalAddressApiCall: action.payload.legalAddressApiCall,
        registrationAddressLineOne: action.payload.registrationAddressLineOne,
        registrationAddressLineTwo: action.payload.registrationAddressLineTwo,
        registrationCity: action.payload.registrationCity,
        registrationState: action.payload.registrationState,
        registrationCountry: action.payload.registrationCountry,
        registrationPostalCode: action.payload.registrationPostalCode,
        correspondencesameasregistration:
          action.payload.correspondencesameasregistration,
        correspondenceAddressLineOne:
          action.payload.correspondenceAddressLineOne,
        correspondenceAddressLineTwo:
          action.payload.correspondenceAddressLineTwo,
        correspondenceCity: action.payload.correspondenceCity,
        correspondenceState: action.payload.correspondenceState,
        correspondenceCountry: action.payload.correspondenceCountry,
        correspondencePostalCode: action.payload.correspondencePostalCode,
        operatingsameasregistration: action.payload.operatingsameasregistration,
        operatingAddressLineOne: action.payload.operatingAddressLineOne,
        operatingAddressLineTwo: action.payload.operatingAddressLineTwo,
        operatingCity: action.payload.operatingCity,
        operatingState: action.payload.operatingState,
        operatingCountry: action.payload.operatingCountry,
        operatingPostalCode: action.payload.operatingPostalCode,
        countryindex1: action.payload.countryindex1,
        countryindex2: action.payload.countryindex2,
        countryindex3: action.payload.countryindex3,
      };
    case "INIT_LEGAL_ENTITY_BUSINESS_DETAILS":
      return initialState;
    default:
      return state;
  }
};

export default LegalEntityBusinessDetailsReducer;
