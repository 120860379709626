export const PDCONST = "PD";
export const LECONST = "LED";
export const SHCONST = "SHO";
export const SHPCONST = "SHP";
export const SHCCONST = "SHC";
export const UBOCONST = "UBOD";
export const EXECONST = "EXED";
export const BUSDCONST = "BSI";
export const MEMPCONST = "MEP";

export const LETEXT = "Legal Entity Details of Business";
export const PDTEXT = "Personal Details";
export const SHTEXT = "Shareholder Details";
export const UBOTEXT = "Ultimate Beneficial Owners Details";
export const EXETEXT = " Executives/Directors/ Representatives Details";
export const BITEXT = "Business Information";
export const MEMPTEXT = "Membership Plan";
