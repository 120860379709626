export const Purpose_of_accountData = [
  {
    id: 1,
    label: "Payments to service providers",
    value: "1",
  },
  {
    id: 2,
    label: "Receiving funds from your clients",
    value: "2",
  },
  {
    id: 3,
    label: "Receiving funds on behalf of the other company(ies) or person(people)",
    value: "3",
  },
  {
    id: 4,
    label: "For company’s operations and administrative expenses ",
    value: "4",
  },
  {
    id: 5,
    label: "Investments",
    value: "5",
  },
  {
    id: 6,
    label: "Other",
    value: "6",
  },
];
