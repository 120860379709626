import axios from 'axios';
import { KEYS_FOR_SESSIONSTORAGE } from 'configs/pageConst';
import { getSessionStorage, setSessionStorage } from 'helper/handleSessionStorage';
import { API_COUNTRYFLAG, API_LIMIT_GROUP_LIST, API_MCC_GROUP_LIST } from '../globalAPIVariables';

export const ListOfDialIncode12 = async () => {
  let ListOfDialIncode = [];

  getCurrencyListAPI().then((results) => {
    let sample = results.data;

    sample.sort(function (a, b) {
      return parseInt(a.phoneCode) - parseInt(b.phoneCode);
    });
    sample.forEach((item) => {
      ListOfDialIncode.push({
        value: parseInt(item.phoneCode),
        label: item.name,
        id: parseInt(item.id),
        phoneCode: parseInt(item.phoneCode),
        numCode: parseInt(item.numCode),
        flag: `${API_COUNTRYFLAG + item.iso.toLowerCase()}.png`,
      });
    });
  });
  return ListOfDialIncode;
};
export const LimitGroupOptions = () => {
  let LimitGroupOption = [];
  const getsessionData = JSON.parse(getSessionStorage('LIMITGPLIST')); 
  if(getsessionData !== null)
  {
     getsessionData.forEach((item) => {
      let i = 1;
      LimitGroupOption.push({
        value: item.value,
        label: item.label,
        id: i++,
      });
    });
    return LimitGroupOption
  } else {
    axios.get(API_LIMIT_GROUP_LIST, {
        headers: {
          'content-type': 'application/json', // override instance defaults
        },
      })
      .then((results)  => {
        results.data.forEach((item) => {
          let i = 1;
          LimitGroupOption.push({
            value: item.value,
            label: item.key,
            id: i++,
          });
        });
        setSessionStorage(KEYS_FOR_SESSIONSTORAGE.LIMITGPLIST,JSON.stringify(LimitGroupOption));
      });
  }
  return LimitGroupOption;
};

export let MCCGroupOptions = async () => {
  let MCCGroupOption = [];
  const sessionData = JSON.parse(getSessionStorage('MCGLIST'));
  if(sessionData !== null)
  {
    sessionData.forEach((item) => {
      let i = 1;
      MCCGroupOption.push({
        value: item.value,
        label: item.label,
        id: i++,
      });
    }); 
    return MCCGroupOption
  } else {
    await axios
    .get(API_MCC_GROUP_LIST, {
      headers: {
        'content-type': 'application/json', // override instance defaults
      },
    })
    .then((results) => {
      // let sample = results.data;
      results.data.forEach((item) => {
        let i = 1;
        MCCGroupOption.push({
          value: item.value,
          label: item.key,
          id: i++,
        });
      });
      setSessionStorage(KEYS_FOR_SESSIONSTORAGE.MCGLIST, JSON.stringify(MCCGroupOption))
    });
  }
  return MCCGroupOption;
};

// const positionSelect = [
//   { label: "Chief executive officer", value: 1, id: 1 },
//   { label: "Executive (management/supervisory board member)", value: 2, id: 2 },
//   { label: "CFO, COO, CIO, CTO etc.", value: 3, id: 3 },
//   { label: "Representative", value: 4, id: 4 },
// ];
