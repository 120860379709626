import { RefreshTokenClass } from "utility/RefreshToken/RefreshToken";
import { KEYS_FOR_LOCALSTORAGE } from "../configs/pageConst";
import { API_BASE_URL, getlocalstorage, MultipartPostBeforeLogin, setlocalstorage } from "../helper";

const auth_URL = API_BASE_URL + "identity/connect/token";
// const refresh_auth_URL = API_BASE_URL + "identity/connect/token";
const logout_auth_URL = API_BASE_URL + "identity/connect/revocation";

const client_Id = process.env.REACT_APP_CLIENT_ID; //env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET; //"vault.business.kyc"; //env.client_secret;
const grant_type = process.env.REACT_APP_GRANT_TYPE; //"password"; //env.grant_type;
const scope = process.env.REACT_APP_SCOPE; //"Business.Kyc offline_access"; //env.scope;
const response_type = process.env.REACT_APP_RESPONCE_TYPE; //"code id_token token"; //env.response_type;

//Refresh Token Details
// const refresh_client_Id = process.env.REACT_APP_CLIENT_ID; //"kyc"; //env.client_Id;
const refresh_grant_type = process.env.REACT_APP_REFRESH_GRANT_TYPE; // "refresh_token"; //env.grant_type;
// const refresh_client_secret = "vault.business.kyc"; //env.client_secret;

export const tokenAPI = async (email = "", pass = "") => {
  const data1 = new FormData();
  data1.append("client_Id", client_Id);
  data1.append("client_secret", client_secret);
  data1.append("grant_type", grant_type);
  data1.append("scope", scope);
  data1.append("response_type", response_type);
  data1.append("username", email);
  data1.append("password", pass);
  try {
    const resp = await MultipartPostBeforeLogin(auth_URL, data1);
    // voidsetlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN, resp.data.access_token);
    // voidsetRefreshToken(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN, resp.data.refresh_token);

    RefreshTokenClass.setSessionTime(resp.data.expires_in)
    setlocalstorage(KEYS_FOR_LOCALSTORAGE.EXPIRE_TIME , resp.data.expires_in)
    setlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN, resp.data.access_token);
    setlocalstorage(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN, resp.data.refresh_token);

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const refreshTokenAPI = async () => {
  let refreshToken = getlocalstorage(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN);

  const data1 = new FormData();
  data1.append("client_Id", client_Id);
  data1.append("client_secret", client_secret);
  data1.append("grant_type", refresh_grant_type);
  data1.append("refresh_token", refreshToken);

  return await MultipartPostBeforeLogin(auth_URL, data1);
};

export const logoutAPI = async () => {
  let refreshToken = getlocalstorage(KEYS_FOR_LOCALSTORAGE.REFRESH_TOKEN);

  const data1 = new FormData();
  data1.append("client_Id", client_Id);
  data1.append("token", refreshToken);
  data1.append("token_type_hint", refresh_grant_type);
  data1.append("client_secret", client_secret);

  try {
    await MultipartPostBeforeLogin(logout_auth_URL, data1);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
