const initialState = {
  loading: true,
  bulkPaymentData: [],
  successConversionList: [],
  failConversionList: [],
  summerydata: [],
  // AllCards:[]
};

const BulkPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BULKPAYMENT_ACTION":
      return {
        ...state,
        bulkPaymentData: action.payload.data,
        successConversionList: action.payload.successConversionList,
        failConversionList: action.payload.failConversionList,
        summerydata: action.payload.summerydata,
        // nameOnCard: action.payload.nameOnCard,
        // maskedPan: action.payload.maskedPan,
        // expDate: action.payload.expDate,
        // employeeId: action.payload.employeeId,
        // businessId: action.payload.businessId,
      };

    default:
      return state;
  }
};

export default BulkPaymentReducer;
