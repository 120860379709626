import AML from "../../assets/ownerdetails/AMLPolicies.svg";
import AOA from "../../assets/ownerdetails/ArticlesofAssociation.svg";
import BANKSTA from "../../assets/ownerdetails/BankStatements.svg";
import BUSPLAN from "../../assets/ownerdetails/BusinessPlan.svg";
import BUSILIC from "../../assets/ownerdetails/Businesslicense.svg";
import COI from "../../assets/ownerdetails/CertificateofIncorporation.svg";
import OWNERSTRUCTURE from "../../assets/ownerdetails/OwnershipStructure.svg";
import ROS from "../../assets/ownerdetails/RegistryofShareholders.svg";
import FIA from "../../assets/ownerdetails/financialaccount.svg";
import POATTR from "../../assets/ownerdetails/powerofattorney.svg";
import { default as POIBACK, default as POIFRONT } from "../../assets/ownerdetails/proofidentity.svg";
import POADD from "../../assets/ownerdetails/proofofaddress.svg";
import SOF from "../../assets/ownerdetails/proofofsource.svg";
import selfie from "../../assets/ownerdetails/selfie.svg";
import { KEYS_FOR_LOCALSTORAGE } from "../../configs/pageConst";
import { getlocalstorage } from "../../helper";
import { listofShortMonth } from "../Constants";
const moment = require("moment");

export const Regexp = {
  allspecailChar: new RegExp(/[!@#$%^&*()_+\-=\[\]{};':~"\\|,.<`>\/?]+/), //detect all specail character
  multispaceDetect: new RegExp(/  +/g),
  morethan100: new RegExp(/^(?!(?:\d{1,2}|100)$)[0-9]\d+$/gm),
  initialSpaces: new RegExp(/^\s+/g),
  onlychar: new RegExp("[^a-zA-Z' ]"),
  onlyNum: new RegExp("[^0-9 ]"),
};

export const charOnly = (event) => {
  return event.target.value
    .replace(Regexp.initialSpaces, "")
    .replace(Regexp.allspecailChar, "");
};

export const NoSpaceinput = (event) => {
  return event.target.value.replace(Regexp.initialSpaces, "")
}

export const returnIdFromSelect = (array, idmatch) => {
  return array.findIndex((o) => o.id === idmatch);
};

export const returnIndexFromSelect = (array, idmatch) => {
  return array.findIndex((o) => o.value === idmatch);
};

export const returnDialInCodeFromSelect = (array, code) => {
  return array.findIndex(input => input.phoneCode === code);
}

export const readOnlyDecide = (value) => {
  if (value === 2 || value === 3) {
    return true;
  } else {
    return false;
  }
};

export const toInputUppercase = (e) => {
  e.target.value = ("" + e.target.value).toUpperCase();
};

export const toInputUppercaseAndRemoveSpace = (e) => {
  e.target.value = ("" + e.target.value).toUpperCase().replace(/\s/g, "");
};

///textOnlyInput
export const nameInput = (event) => {
  return event.target.value
    .replace(Regexp.initialSpaces, "")
    .replace(new RegExp("[^a-zA-Z' ]"), "");

};
export const aplhaNum = (event) => {
  return event.target.value
    .replace(Regexp.initialSpaces, "")
    .replace(new RegExp("[^a-zA-Z0-99 ']"), "");
};
/**
 *
 * @param {*} event
 * @returns value of removedinitial space and multiple space
 */
export const InitalNoSpaceinput = (event) => {
  return event.target.value.replace(Regexp.initialSpaces, "")
};

export const NoSpacailcharVb = (event) => {
  return event.target.value
    .replace(/^\s+/, "")
    .replace(RegExp("[^a-zA-Z0-9'._+@%-]"), "");
}

export const Nochar = (event) => {
  return event.target.value
    .replace(/^\s+/, "")
    .replace(new RegExp("[^a-zA-Z ]"), "");
};

export const NoSpacailchar = (event) => {
  return event.target.value
    .replace(/^\s+/, "")
    .replace(RegExp("[^a-zA-Z0-9-.'/,&;|() ]"), "");
};
/**
 *
 * @param {*} event
 * @returns result of initial space removed also more than one space removed
 */
export const InitalNoSpaceSpecailCharInput = (event) => {
  return event.target.value
    .replace(Regexp.initialSpaces, "")
};
/**
 *
 * @param {*} event
 * @return result of email validated value
 */
export const emailInput = (event) => {
  return event.target.value.replace(" ", "");
};
/**
 *
 * @param {*} event
 * @returns password Result value
 */
export const passwordInput = (event) => {
  return event.target.value.replace(" ", "");
};

///Only Number with event
export const onlyNumberInput = (event) => {
  return event.target.value.replace(new RegExp("([^0-9])"), "");
  // return event.target.value.replace(/[^0-9]/gi, "");
};

///Only Number
export const onlyNumberValue = (num) => {
  return num.replace(new RegExp("([^0-9])"), "");
};

export const Date_MMDYYYY = (date = new Date()) => {
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${listofShortMonth[month]} ${day}, ${year}`;
};

export const Date_Time_YYYY_MM_DD = (date = new Date()) => {
  let currentdate = date
  var datetime = currentdate.getFullYear() + "/" + (currentdate.getMonth()+1)  + "/" + currentdate.getDate() + " @ "  + currentdate.getHours() + ":"  + currentdate.getMinutes() + ":" + currentdate.getSeconds();
  return `${datetime}`;
};

// const setLocalBalance = async () => {
//   GetCurrenciesAPI().then((results) => {
//     removelocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY);
//     setlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY, JSON.stringify(results.data));
//   });
// };

export const getMvcCurrency = async () => {
  let value1 = [];

  let currency =
    (await JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY))) === null
      ? []
      : JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY));

  value1 = await currency.filter(function (data) {
    return data.isMvc === true;
  });
  if (value1.length === 0) {
    return await value1;
  } else {
    return await value1[0];
  }
};
export const getSecondaryCurr = async (currCode) => {
  let value1 = [];
  let currency =
    (await JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY))) === null
      ? []
      : JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY));

  value1 = currency.filter(function (data) {
    return data.isMvc === true && data.currencyCode.toString().toUpperCase() === currCode.toString().toUpperCase();
  });
  if (value1.length === 0) {
    let rtn = currency.filter(function (data) {
      return data.isDefaultCurrency === true && data.currencyCode.toString().toUpperCase() === currCode.toString().toUpperCase();
    })[0];
    return rtn
  } else {
    return await value1[0];
  }
};
// console.log(getSeco ndaryCurr())


export const getCurrencyElement = (value) => {
  let currency =
    JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY)) === null
      ? []
      : JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY));
  let value1 = currency.filter(function (data) {
    return data.currencyCode === value;
  });
  return value1[0];
};

export const getCurrencySymbol = (value) => {
  let currency =
    JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY)) === null
      ? []
      : JSON.parse(getlocalstorage(KEYS_FOR_LOCALSTORAGE.BALANCECURRENCY));

  let value1 = currency.filter(function (data) {
    return data.currencyCode === value;
  });
  return value1.length > 0 ? value1[0].currencySymbol : "";
};

export const numberWithCommas = (x = "") => {
  let nf = new Intl.NumberFormat("en-US");
  return nf.format(x); // "1,234,567,890"
};

export const isDisablepage = (
  status = 0,
  isRejected = false,
  isAddnew = false
) => {
  if (isAddnew === true) return false;
  else if (isAddnew === false) {
    if (status === 4) {
      if (isRejected === false) return true;
      if (isRejected === true) return false;
    } else if (status === 3 || status === 2) return true;
    else if (status === 1 || status === 0) return false;
    else return false;
  }
};

export const validate2f = (value) => {
  return value.match(/^(\d*\.{0,1}\d{0,2}$)/);
};
export const validateexe = (value) => {
  return value.match(/^(\d*\.{0,1}\d{0,2}$)/);
};

export const validateexewithoutdot = (value) => {
  return value.match(/^(\d{0,9}\.{0,0}\d{0,0}$)/);
};

export const validateVR = (event) => {
  return event.target.value.replace((/^\d+\.\d{0,2}$/), "");
};
export const formatBytesFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const TitleCaseText = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const getUnixID = (docName, index) => {
  return `${docName}${moment().format("x")}${index}`
}

export function validateURL(url) {
  const parsed = new URL(url);
  return ["https:", "http:"].includes(parsed.protocol);
}

export const callFileDownload = async (filename, res) => {
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const FileDownloadbyBlob = async (filename, res) => {
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`); //or any other extension
  document.body.appendChild(link);
  link.click();
};
/**
 * 
 * @param {*} docID 
 * @returns document ICON
 */
export const addOwnerDocIcon = (docID) => {
  let returnIcon = docID === 1 ? POADD : docID === 2 ? POIFRONT : docID === 3 ? POIBACK : docID === 4 ? selfie : docID === 5 ? SOF : docID === 6 ? POATTR : docID === 7 ? COI : docID === 8 ? AOA : docID === 9 ? ROS : docID === 10 ? OWNERSTRUCTURE : docID === 11 ? FIA : docID === 12 ? BANKSTA : docID === 13 ? BUSPLAN : docID === 14 ? BUSILIC : docID === 15 ? AML : ""
  return returnIcon;
}
/**
 * 
 * @param {*} docID 
 * @returns document fullname 
 */
export const getDocFullForm = (docID) => {
  let returndocFullName = docID === 1 ? "Proof of Address" : docID === 2 ? "Proof of Identity (Front)" : docID === 3 ? "Proof of Identity(Back)" : docID === 4 ? "Selfie" : docID === 5 ? "Proof of Source of Funds/Wealth" : docID === 6 ? "Power of Attorney" : docID === 7 ? "Certificate of Incorporation" : docID === 8 ? "Articles of Association" : docID === 9 ? "Registry of Shareholders" : docID === 10 ? "Ownership Structure" : docID === 11 ? "Financial Accounts" : docID === 12 ? "Bank Statements" : docID === 13 ? "Business Plan" : docID === 14 ? "Business License" : docID === 15 ? "AML Policies" : ""
  return returndocFullName;
}
/**
 * 
 * @param {*} value 
 * @returns Remove initial space , special char & numeric 
 */
export const noSpacialCharDDValidation = (value) => {
  return value.replace(Regexp.initialSpaces, "").replace(Regexp.allspecailChar, "").replace(Regexp.onlychar, "");
}
/**
 * 
 * @param {*} value 
 * @returns Remove initial space , special char  
 */
export const numericDDValidation = (value) => {
  return value.replace(Regexp.initialSpaces, "").replace(Regexp.allspecailChar, "").replace(Regexp.onlyNum, "");
}
