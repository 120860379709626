export const RowCol = {
  row: {
    marginLeft: 0,
    marginRight: 0,
  },
  col: {
    // paddingLeft: 0,
    paddingRight: 0,
  },
};
