import { KEYS_FOR_LOCALSTORAGE } from "../../../configs/pageConst";
import { readOnlyDecide, setlocalstorage } from "../../../helper";

const initialState = {
  id: "",
  businessInfoId: null,
  membershipPlanStatus: null,
  membershipPlansRes: null,
  personalDetailStatus: null,
  legalBusinessEntityStatus: null,
  shareHolderStatus: null,
  shareHolderCompanyStatus: null,
  shareHolderPersonStatus: null,
  ultimateBeneficialOwnersStatus: null,
  executiveStatus: null,
  businessDocumentStatus: null,
  individualInfoId: "",
  loading: false,
  error: "",
  ispersonalDetailsDisabled: false,
  islegalBusinessEntityDisabled: false,
  isshareHolderDisabled: false,
  isshareHolderCompanyDisabled: false,
  isshareHolderPersonDisabled: false,
  isUBODisabled: false,
  isexecutiveDisabled: false,
  isbusinessDocDisabled: false,
  isSole: false,
  OnDatoData: "asdas",
};

const completeUrApplReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DOCSUBMIT_GET_APPLICATION_STATUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SET_SOLE_ENTERPRISE":
      return {
        ...state,
        isSole: action.payload,
      };
    case "DOCSUBMIT_GET_APPLICATION_STATUS_SUCCESS":
      return {
        ...state,
        loading: false,
        isSole: action.payload.isSole,
        id: action.payload.id,
        businessInfoId: setlocalstorage(
          KEYS_FOR_LOCALSTORAGE.BUSINESSINFOID,
          action.payload.businessInfoId
        ),
        personalDetailStatus: action.payload.personalDetailStatus,
        legalBusinessEntityStatus: action.payload.legalBusinessEntityStatus,
        shareHolderStatus: action.payload.shareHolderStatus,
        shareHolderCompanyStatus: action.payload.shareHolderCompanyStatus,
        shareHolderPersonStatus: action.payload.shareHolderPersonStatus,
        ultimateBeneficialOwnersStatus:
          action.payload.ultimateBeneficialOwnersStatus,
        executiveStatus: action.payload.executiveStatus,
        businessDocumentStatus: action.payload.businessDocumentStatus,
        membershipPlanStatus: action.payload.membershipPlanStatus,

        individualInfoId: setlocalstorage(
          KEYS_FOR_LOCALSTORAGE.INDIVIDUALINFOID,
          action.payload.individualInfoId
        ),

        ispersonalDetailsDisabled: readOnlyDecide(
          action.payload.personalDetailStatus
        ),
        islegalBusinessEntityDisabled: readOnlyDecide(
          action.payload.legalBusinessEntityStatus
        ),
        isshareHolderDisabled: readOnlyDecide(action.payload.shareHolderStatus),
        isshareHolderCompanyDisabled: readOnlyDecide(
          action.payload.shareHolderCompanyStatus
        ),
        isshareHolderPersonDisabled: readOnlyDecide(
          action.payload.shareHolderPersonStatus
        ),
        isUBODisabled: readOnlyDecide(
          action.payload.ultimateBeneficialOwnersStatus
        ),
        isexecutiveDisabled: readOnlyDecide(action.payload.executiveStatus),
        isbusinessDocDisabled: readOnlyDecide(
          action.payload.businessDocumentStatus
        ),
        membershipPlansRes: action.payload.membershipPlansRes,

        // error: "",
      };
    case "DOCSUBMIT_GET_APPLICATION_STATUS_FAULURE":
      return {
        ...state,
        loading: false,
        id: "",
        businessInfoId: null,
        personalDetailStatus: null,
        legalBusinessEntityStatus: null,
        shareHolderStatus: null,
        shareHolderCompanyStatus: null,
        shareHolderPersonStatus: null,
        ultimateBeneficialOwnersStatus: null,
        executiveStatus: null,
        businessDocumentStatus: null,
        error: action.payload,
      };
    // case "On_Dato_Current":
    //   return {
    //     ...state,
    //     OnDatoData: "gsagsa",
    //   };
    case "INIT_COMPLATE_YOUR_APPLICATION":
      return initialState;
    default:
      return state;
  }
};

export default completeUrApplReducer;
