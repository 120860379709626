import { getIn } from "formik";
import React from "react";
import { getFormInputs } from "./ErrorOnFocus";

export class Scroller extends React.Component {
  state = {
    input: undefined,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formik.submitCount > 0 &&
      prevProps.formik.submitCount !== this.props.formik.submitCount &&
      !this.props.formik.isValid
    ) {
      const firstInput = getFormInputs("signup")().find(
        (input) =>
          (input.id || input.name) &&
          getIn(this.props.formik.errors, input.id || input.name)
      );

      if (firstInput) {
        this.setState({ input: firstInput.id || firstInput.name });
        // firstInput.focus();
      }
    }
  }
  render() {
    return this.props.children(this.state);
  }
}
