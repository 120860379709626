export const API_BASE_URL =
  process.env.REACT_APP_ENV === "PROD"
    ? process.env.REACT_APP_PROD_API_BASE_URL
    : process.env.REACT_APP_DEV_API_BASE_URL;

export const API_PROHABITED_URL =
  API_BASE_URL + "common/api/Types/ProhibitedBussinessCategoryList";

export const API_COUNTRYLIST = API_BASE_URL + "common/api/Country";

export const API_PERSONAL_VERIFY =
  API_BASE_URL + "hellosoda/api/HelloSoda/HelloSodaResult?individualId=";

export const API_CURRENCY = API_BASE_URL + "common/api/Currency";
export const API_GET_BANKTRANSFER_CURRENCY =
  API_BASE_URL + "common/api/Currency";

export const API_PERSONLIST =
  API_BASE_URL + "signup/api/Details/ShareHolderPersons?businessInfoId=";

export const GETUBOLIST =
  API_BASE_URL + "signup/api/Details/GetUboList?businessInfoId=";
export const API_COMPANYLIST =
  API_BASE_URL + "signup/api/Details/ShareHolderCompanies?businessInfoId=";

export const API_SHAREHOLDER_DETAILS_COMPANY =
  API_BASE_URL + "signup/api/Details/SaveShareHolderCompany";

export const API_UPDATE_SHAREHOLDER_COMPANYID =
  API_BASE_URL + "signup/api/Details/UpdateShareHolderCompany";

export const API_UPDATE_SHAREHOLDER_OR_UBO_BYID =
  API_BASE_URL + "signup/api/Details/UpdateShareHolderOrUboDetails";

export const API_SHAREHOLDER_DETAILS_PERSON_AND_UBO =
  API_BASE_URL + "signup/api/Details/SaveShareHolderOrUboDetails";
export const API_SHAREHOLDER_DETAILS_COMPANYID =
  API_BASE_URL +
  "businessaggregator/api/Onboarding/GetShareholderCompanyDetails?";
export const API_SHAREHOLDER_DETAILS_PERSONID =
  API_BASE_URL + "signup/api/Details/ShareHolderPersonById?individualId=";
export const API_GETSHAREHOLDER_UBO_BYID =
  API_BASE_URL +
  "businessaggregator/api/Onboarding/GetUboOrShareholderPersonDetails?IndividualId=";

export const API_COUNTRYFLAG = API_BASE_URL + "common/content/country/";

export const API_PERSION_POSITION =
  API_BASE_URL + "common/api/Types/businessPositions";

export const API_POLITICAL_EXPOSE_LIST =
  API_BASE_URL + "common/api/Types/PoliticallyExposedMasterList";

export const API_STATUSAPPLICATION =
  API_BASE_URL + "signup/api/Details/ApplicationStatus";

export const API_DOCUMENT_UPLOAD = API_BASE_URL + "kyc/api/Kyc/upload";
export const API_DOCUMENT_DELETE = API_BASE_URL + "kyc/api/Kyc/DeleteDocument";

export const API_LEGALBUSINESSDETAILS =
  API_BASE_URL + "signup/api/Details/legalbusinessentity";
export const API_UPDATE_LEGALBUSINESSDETAILS =
  API_BASE_URL + "signup/api/Details/updatelegalentity";

export const SUBMITAPPLICATION =
  API_BASE_URL + "signup/api/Application/submitApplication?businessId=";

export const API_GET_LEGALBUSINESSDETAILSID =
  API_BASE_URL + "signup/api/Details/getlegalbusinessentity?businessInfoId=";

export const API_GET_LEGALBUSINESSDETAILS =
  API_BASE_URL + "signup/api/Details/getlegalbusinessentity";

export const PROFILE_URL = API_BASE_URL + "signup/api/Details/Profile";

export const API_UPDATETAX = API_BASE_URL + "signup/api/Details/UpdateTax";

export const API_ONBOARDING_BUSINESS =
  API_BASE_URL + "signup/api/Register/Business";

export const API_SAVEEXECUTIVE =
  API_BASE_URL + "signup/api/Details/saveExecutives";

export const API_GET_EXECUTIVE_BY_ID =
  API_BASE_URL + "signup/api/Details/getExecutiveById?id=";

export const API_GET_EXECUTIVE_WITH_DOC =
  API_BASE_URL +
  "businessaggregator/api/Onboarding/GetExecutiveDetails?IndividualId=";

export const API_GET_EXECUTIVE_LIST =
  API_BASE_URL + "signup/api/Details/getExecutiveslList?businessInfoId=";

export const API_DELETE_EXECUTIVE_BY_ID =
  API_BASE_URL + "signup/api/Details/DeleteExecutive?id=";

export const API_DELETE_SHAREHOLDER_BY_ID =
  API_BASE_URL + "signup/api/Details/DeleteShareHolder?id=";
export const API_UPDATE_EXECUTIVE =
  API_BASE_URL + "signup/api/Details/UpdateExecutive";

export const API_BUSINESS_ADD_INFO =
  API_BASE_URL + "signup/api/Details/GetAdditionalDetails?businessInfoId=";

export const API_BNUSINESS_INFO_ADD_AGGRI =
  API_BASE_URL +
  "businessaggregator/api/Onboarding/GetAdditionalBusinessDetails?BusinessId=";
export const API_POST_ADDITIONAL_DETAIL =
  API_BASE_URL + "signup/api/Details/saveAdditionalDetails";

export const API_PERSONAL_DETAILS =
  API_BASE_URL + "signup/api/Details/SetPersonalSectionPending?IndividualId=";

export const API_INIT_LEGALBUSINESSDETAILS =
  API_BASE_URL +
  "businessaggregator/api/BackOffice/GetLegalBusinessDetails?BusinessId=";

export const API_DELETE_UBO = API_BASE_URL + "signup/api/Details/DeleteUBO?id=";

export const API_GETSHAREHOLDER_COMPANY_BYID =
  API_BASE_URL + "signup/api/Details/ShareHolderCompanyById?companyId=";

export const API_CARD_BALANACEINQUIRY =
  API_BASE_URL + "businessaggregator/api/Card/cardbalanceenquiryV2";

export const API_GET_RATES = API_BASE_URL + "cc/api/CC/getrates";
export const API_CREATECONVERSION = API_BASE_URL + "cc/api/CC/createConversion";

export const API_GET_CURRENCY = API_BASE_URL + "common/Content/currency/";

export const API_GETCURRENCIES =
  API_BASE_URL + "signup/api/CurrencyManager/GetCurrencies";

export const API_MAX_CURR_COUNT =
  API_BASE_URL + "gps/api/GPS/FetchConfigValue?configKey=MultiCurrencyCardCount";

export const v2vSearch = API_BASE_URL + "signup/api/V2V/v2vSearch?";
export const V2VFUND_TRANSFER = API_BASE_URL + "signup/api/V2V/v2vFundTransfer";

export const VToVLISTING = API_BASE_URL + "signup/api/V2V/v2vListing";
export const VToBLISTING =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/GetBankBeneficiaryList";

export const API_ADD_CARD = API_BASE_URL + "signup/api/CurrencyManager/addCard";

export const BENEFICIARY_REQUIRED_DETAILS =
  API_BASE_URL +
  "signup/api/BankTransferBeneficiary/BeneficiaryRequiredDetails?";

export const ADDBENEFICIARY_BANK =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/AddBeneficiary";
export const UPDATE_BENEFICIARY_BANK =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/UpdateBeneficiary";
export const API_REMOVE_CARD =
  API_BASE_URL + "signup/api/CurrencyManager/RemoveCurrency";

export const API_TRANSACTION =
  API_BASE_URL + "signup/api/Transaction/Transactions";

export const API_EXCEL_STATEMENT =
  API_BASE_URL + "signup/api/Transaction/ExcelStatement";

export const API_GET_PDF_STATEMENT =
  API_BASE_URL + "signup/api/Transaction/GetPdfStatement";

export const API_GET_PERSONALDETAIL_BUSINESS_AGGRIGATOR =
  API_BASE_URL +
  "businessaggregator/api/Onboarding/GetPersonalDetails?IndividualId=";

export const API_VTOBACCOUNT_REASON =
  API_BASE_URL + "common/api/Types/PaymentResons";

export const GET_SAVED_CARD_LIST = API_BASE_URL + "st/api/ST/GetSavedCardList";

export const CARD_BALANCE_ENQUIRY =
  API_BASE_URL + "businessaggregator/api/Card/cardbalanceenquiryV2";
export const LOAD_VIA_DEBITCARD =
  API_BASE_URL + "paymentmvc/payments/LoadViaDebitCard";

export const GET_WAY_SCRIPT = API_BASE_URL + "common/content/getwayScript.js";

export const API_VTOBACCOUNT_FEES =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/GetBankTransferFees";

export const API_VOLUME_BASED_FEE =
  API_BASE_URL + "gps/api/GPS/OutgoingBTFee";

export const API_DELETE_BANK_BENEFICIARY =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/DeleteBankBeneficiary";

export const API_MAKE_PAYMENT =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/MakePayment";

export const API_DELETE_V_TO_VB_BENEFICIARY =
  API_BASE_URL + "gps/api/GPS/Vb2VbRemoveBeneficiary";

export const API_DELETE_V_TO_VRETAIL_BENEFICIARY =
  API_BASE_URL + "gps/api/GPS/Vb2VRemoveBeneficiary";

export const API_GET_BENEFICIARY =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/GetBeneficiary";

export const API_MAKE_SELF_TRANSFER =
  API_BASE_URL + "businessaggregator/api/FundTransfer/SelfTransfer"

export const API_CARD_LIST =
  API_BASE_URL + "signup/api/EmployeeCard/GetEmployeeCards";

export const API_ADDNEWEMPLOYEE_CARD =
  API_BASE_URL + "signup/api/EmployeeCard/AddEmployeeCard";

export const API_GETEMPCARDSETTING =
  API_BASE_URL + "signup/api/EmployeeCard/GetEmployeeCardSetting?EmployeeId=";

export const API_CARD_CHANGES_GROUP =
  API_BASE_URL + "signup/api/EmployeeCard/CardChangeGroups";

export const API_EMP_ACTIVATE_CARD =
  API_BASE_URL + "signup/api/EmployeeCard/ActivateCard?EmployeeId=";

export const API_CARD_STATUS_CHANGE =
  API_BASE_URL + "signup/api/EmployeeCard/CardStatusChange?EmployeeId=";
export const API_GET_CARD_ACTIVATION =
  API_BASE_URL + "signup/api/EmployeeCard/GetCardActivation";

export const API_SEND_CARD_ACTIVATION_OTP =
  API_BASE_URL + "signup/api/Verify/SendCardActivationOtp";

export const API_VERIFY_CARD_ACTIVATION_OTP =
  API_BASE_URL + "signup/api/Verify/VerifyCardActivationOtp";

export const API_CHANGE_CARD_CONTACT_DETAILS =
  API_BASE_URL + "signup/api/EmployeeCard/ChangeCardContactDetails";

export const API_CHANGE_CARD_LIMIT =
  API_BASE_URL + "signup/api/EmployeeCard/ChangeCardLimit";

export const API_LIMIT_GROUP_LIST =
  API_BASE_URL + "common/api/Types/LimitGroups";

export const API_MCC_GROUP_LIST = API_BASE_URL + "common/api/Types/MccGroups";
export const BULKV2VTRANSFER =
  API_BASE_URL + "signup/api/BulkTransfer/BulkV2VTransfer";

export const BULKCARDUPLOAD =
  API_BASE_URL + "signup/api/BulkTransfer/BulkCards"

export const API_LEGAL_ENTITY_MASTER =
  API_BASE_URL + "common/api/Types/legalEntityMaster";
export const BULKV2V_BUSINESS_NAME =
  API_BASE_URL + "signup/api/Details/getInitialBusinessDetails?businessInfoId=";

export const API_ADD_BENEFICIARY =
  API_BASE_URL + "signup/api/V2V/v2vAddBeneficiary";

export const V2VAUTOPAYMENTLIST =
  API_BASE_URL + "signup/api/V2V/v2vAutoPaymentList";

export const v2vDeleteAutoPayment =
  API_BASE_URL + "signup/api/V2V/v2vDeleteAutoPayment";

// V To B AutoPayment Services
export const API_ADD_AUTOPAYMENT_VToB =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/V2BAddAutoPayment";

export const API_UPDATE_AUTOPAYMENT_VToB =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/V2BUpdateAutoPayment";
export const API_AUTO_V2B_DELETE =
  API_BASE_URL +
  "signup/api/BankTransferBeneficiary/v2bDeleteAutoPayment?autoPaymentId=";

export const GETBANK_AUTOPAYMENT_LIST =
  API_BASE_URL + "signup/api/BankTransferBeneficiary/BankAutoPaymentList";

export const PHYSICAL_CARD_FEE =
  API_BASE_URL + "gps/api/GPS/PhysicalCardFee";
// Change Password Api////

export const CHANGE_PASSWORD_USER =
  API_BASE_URL + "signup/api/Reset/changepassword";

export const ACCOUNT_PURPOSE =
  API_BASE_URL + "common/api/Types/PurposeOfAccounts";

///Reset Password Functionality

export const FORGOT_PASSWORD_USER =
  API_BASE_URL + "signup/api/Reset/forgotpassword";

export const SEND_TOKEN_VERIFICATION =
  API_BASE_URL + "signup/api/Reset/emailPasswordVerified";

export const RESET_PASWORD_USER =
  API_BASE_URL + "signup/api/Reset/resetpassword";

export const GET_MEMBERSHIP_PLANS =
  API_BASE_URL + "signup/api/MembershipPlan/getmembershipplans?businessInfoId=";

// export const SAVE_BUSINESS_MEMBERSHIP_PLAN =
//   API_BASE_URL + "signup/api/MembershipPlan/savebusinessmembershipsubscription";

export const GET_OTHERPOSITIONS =
  API_BASE_URL + "common/api/Types/GetOtherPositionList";

//Employee Card Image

export const GET_EMPLOYEE_CARD_IMAGE =
  API_BASE_URL + "signup/api/EmployeeCard/GetCardImage";
export const CANCALMEMBERSHIP =
  API_BASE_URL +
  "signup/api/MembershipPlan/updatebusinessmembershipsubscriptionCancellation";
export const EMPPLOYEE_CARD_ACTIVATION_EMAIL =
  API_BASE_URL + "signup/api/Verify/ResendCardActivationEmail";
export const EMPLOYEECARDPIN =
  API_BASE_URL +
  //  "gps/api/GPS/GetCardPin";
  "signup/api/Verify/ResendCardPin";

export const EMPLOYEEUPDATEADDRESS =
  API_BASE_URL + "signup/api/EmployeeCard/UpdateEmployeeCardAddress";
export const EMPLOSTSTOLENCARD = API_BASE_URL + "gps/api/GPS/LostCard";
export const EMPCARDREPLACEMENT = API_BASE_URL + "gps/api/GPS/CardReplacement";
export const LOADFUNDDDETAILS =
  API_BASE_URL + "railsbank/api/RBOperation/Business/Account/Details";
export const TERMINATEEMPCARD = API_BASE_URL + "gps/api/GPS/TerminateCard"

export const CURRENTACCOUNTPURPOSEAPI =
  API_BASE_URL + "common/api/Types/PurposeOfCurrentAccount";

export const DOWNLOADFILEBUSINESS =
  API_BASE_URL +
  // "kyc/api/Kyc/images/decrypt?businessInfoId=";
  "kyc/api/Kyc/getDocs?businessInfoId=";
export const DOWNLOADFILECOMPANY =
  API_BASE_URL + "kyc/api/Kyc/getCompanyDocs?companyId=";

export const DOWNLOADFILEINDIVISUAL =
  API_BASE_URL + "kyc/api/Kyc/getIndividualDocs?individualId=";
export const GETVIR_CARD_ACTIVATIONDATA =
  API_BASE_URL + "signup/api/EmployeeCard/GetEmployeeCardActivation?tokenId=";
export const SEND_EMP_CARD_ACTIVE_OTP =
  API_BASE_URL + "signup/api/Verify/SendEmpoyeeCardActivationOtp";
export const VERIFY_EMP_CARD_OTP =
  API_BASE_URL + "signup/api/Verify/VerifyCardActivationOtp";

export const ADD_CURRENCY_IBAN =
  API_BASE_URL + "railsbank/api/RBOperation/LoadFundSecondaryRBAccount";
export const GET_TRANSACTION_RECEIPT =
  API_BASE_URL + "signup/api/Transaction/GetTransactionReceipt";

export const GET_VAULT_RETAILS = API_BASE_URL + "signup/api/V2V/vb2vListing";
export const ADD_VAULT_RETAIL =
  API_BASE_URL + "signup/api/V2V/vb2vSearch?searchTerm=";
export const VB_TO_V_FUND_TRANSFER =
  API_BASE_URL + "signup/api/V2V/vb2vFundTransfer";

export const ONDATOSTATUS =
  API_BASE_URL + "kyc/api/OnDatoOperational/OnDatoCurrentStatus";

export const ONDATOINIT =
  API_BASE_URL + "kyc/api/OnDato/Identification/start";

export const CHECKIBAN =
  API_BASE_URL + "signup/api/Application/GetRBIBANSettingFlag";

export const UpdateStatus =
  API_BASE_URL + "signup/api/Details/updateBusinessInfo";

export const VIRTUALCARDRESENDEMAIL =
  API_BASE_URL + "signup/api/Verify/ResendVirtualCardActivationEmail";

// ** Auto Payment
export const API_AUTOPAYMENT_V2VBENEFICIARY_SEARCH =
  API_BASE_URL + "gps/api/AutoPayment/V2VBeneficiarySearch?"

export const API_ADD_V2VAUTOPAYMENT =
  API_BASE_URL + "gps/api/AutoPayment/AddV2VInstructions"

export const API_UPDATE_V2VAUTOPAYMENT =
  API_BASE_URL + "gps/api/AutoPayment/UpdateV2VInstructions";

export const API_V2VAUTOPAYMENTLIST =
  API_BASE_URL + "gps/api/AutoPayment/V2VInstructionList";

export const API_V2VAUTOPAYMENTDELETE =
  API_BASE_URL + "gps/api/AutoPayment/DeleteV2VInstructions";

export const API_V2VAUTOPAYMENT_FAILEDLIST =
  API_BASE_URL + "gps/api/AutoPayment/GetV2VFailedAutopaymentListToManualRetry";

export const V2VAutopaymentManualRetry =
  API_BASE_URL + "gps/api/AutoPayment/V2VFailedAutopaymentManualRetry";

export const API_V2VAUTOPAYMENT_TRANSACTION_LIST =
  API_BASE_URL + "gps/api/AutoPayment/GetV2VAutopaymentTransactionList";

//Vault Reatil Autopayment

export const API_ADD_VB2VRAUTOPAYMENT =
  API_BASE_URL + "gps/api/VB2VRAutoPayment/AddVB2VRInstructions";

export const API_VB2VRAUTOPAYMENTDELETE =
  API_BASE_URL + "gps/api/VB2VRAutoPayment/DeleteVB2VRInstructions";

export const API_VB2VRAUTOPAYMENTLIST = 
  API_BASE_URL + "gps/api/VB2VRAutoPayment/VB2VRInstructionList"

export const API_UPDATE_VRINSTRUCTION = 
  API_BASE_URL + "gps/api/VB2VRAutoPayment/UpdateVB2VRInstructions"

export const API_VR_TRANSACTION_LIST =
  API_BASE_URL + "gps/api/VB2VRAutoPayment/GetVB2VRAutopaymentTransactionList"

export const API_VR_AUTOPAYMENTMANUALRETRYLIST = 
  API_BASE_URL + "gps/api/VB2VRAutoPayment/GetVB2VRFailedAutoPaymentListToManualRetry"

export const API_VR_FAILEDAUTOPAYMANUALRETRY = 
  API_BASE_URL + "gps/api/VB2VRAutoPayment/VB2VRFailedAutoPaymentManualRetry"

  //Cheking user ApplicationStatus
  
export const API_USERAPPSTATUS = 
  API_BASE_URL + "signup/api/Application/FinalApplicationStatus"

  // Auto Payment Bank Transfer API BASE URL

export const API_ADD_VB2BANKTRANSFERAUTOPAYMENT = 
  API_BASE_URL + "cc/api/BTAutoPayment/AddBankTransferInstruction"

export const API_UPDATEBENIFICIARY_DETAILS = 
  API_BASE_URL + "cc/api/BTAutoPayment/UpdateBankTransferInstruction"

export const API_BANKTRANSFER_FAILTRANSACTION_LIST = 
  API_BASE_URL + "cc/api/BTAutoPayment/GetBTFailedAutoPaymentListForManualRetry"

export const API_BANKTRANSFER_AUTOPAYMENTLIST = 
  API_BASE_URL + "cc/api/BTAutoPayment/BankTransferInstructionList"

export const API_BANKTRANSFER_TRANSACTION_LIST = 
  API_BASE_URL + "cc/api/BTAutoPayment/GetVB2BTAutoPaymentTransactionList"

export const API_BANKTRANSFER_MANUAL_RETRY = 
 API_BASE_URL + "cc/api/BTAutoPaymentWebJob/DoVB2BankTransferFailedAutoPaymentManualRetry"

export const API_DELETE_BT_AUTOPAYMENT = 
 API_BASE_URL + "cc/api/BTAutoPayment/DeleteBankTransferInstructions"
  //Multiple Currency ADD EDIT & REMOVE API
  export const API_ADDMULTIPLECURRENCY = 
  API_BASE_URL + "businessaggregator/api/CurrencyManager/AddCurrency"

  export const API_REMOVEMULTIPLECURRENCY =
  API_BASE_URL + "businessaggregator/api/CurrencyManager/RemoveCurrency"

  export const API_EDITCURRENCYCARD = 
  API_BASE_URL + "businessaggregator/api/CurrencyManager/EditCurrency"

  export const API_OWNER_DETAILS_PERSONEL = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetAccountOwnerDetails"

  export const API_OWNER_DETAILS_LEGAL_ENTITY = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetLegalBusinessDetails"

  export const API_OWNER_DETAILS_SHAREHOLDER_PERSON = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetShareHolderPersonDetails"

  export const API_OWNER_DETAILS_SHAREHOLDER_COMPANY = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetCompanyShareHolderDetail"

  export const API_OWNER_DETAILS_UBO = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetUBODetails"

  export const API_OWNER_DETAILS_EXE_DIR = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetExecutiveDetails"

  export const API_OWNER_DETAILS_BUSINESS_INFO_DOCS = 
  API_BASE_URL + "businessaggregator/api/AccountOwnerDetails/GetAdditionalBusinessDetails"

  export const API_OWNER_DOCUMENT_DETAILS = 
  API_BASE_URL + "kyc/api/Kyc/fetchDocs"

  //Multiple IBAN Creation

  export const API_CREATE_IABNSECONDARY_ACCOUNT = 
  API_BASE_URL + "cc/api/SubAndFundingAccount/generateIBAN"

  export const API_FETCH_MULIBAN_DETAILS = 
  API_BASE_URL + "cc/api/SubAndFundingAccount/accountIBANDetails"

  //Get Application Maintenance status
  export const API_GET_MAINTENANCE = 
  API_BASE_URL + "common/api/Types/ApplicationIsUnderMaintenance?Content=" 
