const initialState = {
  loading: false,
  error: "",
  password: "",
  confirmPassword: "",
  // ressetpasswords: "",
  passwordStatus: "",
  preRegistrationId: "",
  businessName: "",
  crn: "",
  personalCode: "sjdhfkjdshfk",
};

const setPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SetPassword_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SetPassword_SUCCESS":
      return {
        ...state,
        loading: false,
        passwordStatus: action.payload,
      };
    case "SetPassword_FAILURE":
      return {
        ...state,
        loading: false,
        // emailStatus: "",
        error: action.payload,
      };
    case "Add_SetPassword_Data":
      return {
        ...state,
        loading: false,
        preRegistrationId: action.payload,
        businessName: action.payload.businessName,
        countryId: action.payload.countryId,
        crn: action.payload.crn,
        businessActivity: action.payload.businessActivity,
        isProhibitedBussinessCategoryConfirmed:
          action.payload.isProhibitedBussinessCategoryConfirmed,
        positionId: action.payload.positionId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        citizenshipCountryId: action.payload.citizenshipCountryId,
        personalCode: action.payload.personalCode,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2,
        city: action.payload.city,
        State: action.payload.State,
        addressCountryId: action.payload.addressCountryId,
        postalCode: action.payload.postalCode,
        isUSCitizen: action.payload.isUSCitizen,
        isPoliticallyExposed: action.payload.isPoliticallyExposed,

        politicallyExposedCategoryId:
          action.payload.politicallyExposedCategoryId,
        PoliticallyExposedText: action.payload.PoliticallyExposed,

        isMarketingMsgsAllowed: action.payload.isMarketingMsgsAllowed,
        isGroupMarketingMsgsAllowed: action.payload.isGroupMarketingMsgsAllowed,
        isGroupMarketingCallsAllowed:
          action.payload.isGroupMarketingCallsAllowed,
        password: action.payload.password,
        confirmPassword: action.payload.confirmPassword,
      };
    case "INIT_SIGNUP_SETPASS":
      return initialState;
    default:
      return state;
  }
};

export default setPasswordReducer;
