import {
  getAPIcall,
  getAPIcallWithoutAuthorization,
  postAPIcall,
} from "Services/apiController";
import { KEYS_FOR_LOCALSTORAGE } from "../../../../configs/pageConst";
import {
  API_PERSONAL_VERIFY,
  API_STATUSAPPLICATION,
  ONDATOINIT,
  ONDATOSTATUS,
  getlocalstorage,
} from "../../../../helper";

export const personVerifyHellosoda = () => {
  return getAPIcallWithoutAuthorization(
    API_PERSONAL_VERIFY +
      getlocalstorage(KEYS_FOR_LOCALSTORAGE.INDIVIDUALINFOID)
  );
};

export const getOndatoCurrentStatus = () => {
  return getAPIcall(ONDATOSTATUS);
};

export const ondatoInit = async () => {
  return await postAPIcall(ONDATOINIT);
};

export const applicationstatus = async () => {
  return await getAPIcall(API_STATUSAPPLICATION);
};
