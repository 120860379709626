import { KEYS_FOR_LOCALSTORAGE } from "../configs/pageConst";
import { API_BASE_URL, getlocalstorage, voidsetlocalstorage } from "../helper";
import { getAPIcallWithoutAuthorization } from "./apiController";

const VERIFY_API_URL = API_BASE_URL + "signup/api/Verify/";

//PARAMETERS
const MOBNO = "mobileNumber";
const EMAIL = "EmailId";
const UID = "preRegistrationId";
const TOKEN = "token";
const CCODE = "dialInCode";
const OTP = "otp";
const REQUESTID = "requestId";

const ISEMAILVERIFIED = "isEmailVerified?" + EMAIL + "=";
const SENDVERIFICATIONEMAIL = "ResendVerificationEmail?" + EMAIL + "="; //SendVerificationEmail?emailId=ktwsdfeirthesh%40gmail.com&preRegistrationId=1234567890
const VERIFYEMAIL = "Email?" + UID + "="; //&email=ktirthesh%40gmail.com&token=lkdsjflksjgoijgiotrj%3D
const ISEMAILEXISTS = "IsEmailExist?" + EMAIL + "="; //IsEmailExist?emailId=ktirthesh%40gmail.com&preRegistrationId=123456789
const SENDOTP = "SendOtp?" + UID + "="; //SendOtp?preRegistrationId=123456798&dialInCode=123456&mobileNumber=123467899
const VERIFYOTP = "VerifyOtp?" + MOBNO + "=";
/**
 * set Local storage Token
 * @param {String} Token
 *
 */
export function setToken(Token) {
  voidsetlocalstorage(KEYS_FOR_LOCALSTORAGE.TOKEN, JSON.stringify(Token));
}

/**
 *
 * @param {String} useremail
 * @param {String} preRegistrationId
 * @returns {Promise} of axios get VERIFY_API_URL
 */
export const isEmailVerified = (useremail = "", preRegistrationId = "") => {
  return getAPIcallWithoutAuthorization(
    VERIFY_API_URL +
      ISEMAILVERIFIED +
      useremail +
      "&preRegistrationId=" +
      preRegistrationId
  );
};

/**
 * API of Send Verification Email
 * @param {String} useremail
 * @param {String} preRegistrationId
 * @returns axios.get of VERIFY_API_URL
 */
export const SendVerificationEmail = (
  useremail = "",
  preRegistrationId = ""
) => {
  return getAPIcallWithoutAuthorization(
    VERIFY_API_URL +
      SENDVERIFICATIONEMAIL +
      useremail +
      "&" +
      "requestId" +
      "=" +
      preRegistrationId
  );
};

/**
 * API of  verify Email
 * @param {String} email
 * @param {String} preRegistrationId
 * @param {String} token
 * @returns axios.get of VERIFY_API_URL
 */
export const verifyEmail = (
  email = "",
  preRegistrationId = "",
  token = getlocalstorage(KEYS_FOR_LOCALSTORAGE.ACCESS_TOKEN)
) => {
  return getAPIcallWithoutAuthorization(
    VERIFY_API_URL +
      VERIFYEMAIL +
      preRegistrationId +
      "&" +
      EMAIL +
      "=" +
      email +
      "&" +
      TOKEN +
      "=" +
      token
  );
};

/**
 * API of check is Email Exists
 * @param {String} email
 * @param {String} preRegistrationId
 * @returns axios.get of VERIFY_API_URL
 */
export const isEmailExists = (email = "", preRegistrationId = "") => {
  return getAPIcallWithoutAuthorization(
    VERIFY_API_URL + ISEMAILEXISTS + email + "&" + UID + "=" + preRegistrationId
  );
};

/**
 * API of send Otp
 * @param {*} preRegistrationId
 * @param {*} dialInCode
 * @param {*} mobileNumber
 * @returns axios.get VERIFY_API_URL
 */

export const sendOtp = (
  preRegistrationId = "",
  dialInCode = "",
  mobileNumber = ""
) => {
  return getAPIcallWithoutAuthorization(
    VERIFY_API_URL +
      SENDOTP +
      preRegistrationId +
      "&" +
      CCODE +
      "=" +
      dialInCode +
      "&" +
      MOBNO +
      "=" +
      mobileNumber
  );
};

/**
 * API of verify Otp
 * @param {String} mobileNumber
 * @param {String} otp
 * @param {String} requestId
 * @param {String} preId
 * @returns axios.get VERIFY_API_URL
 */
export const verifyOtp = (
  mobileNumber = "",
  otp = "",
  requestId = "",
  preId = ""
) => {
  return getAPIcallWithoutAuthorization(
    VERIFY_API_URL +
      VERIFYOTP +
      mobileNumber +
      "&" +
      OTP +
      "=" +
      otp +
      "&" +
      REQUESTID +
      "=" +
      requestId +
      "&preId=" +
      preId
  );
};
