const initialState = {
  loading: true,
  amount: "",
  buyCurrency: null,
  sellCurrency: null,
  Note: null,
  currencyTwoIndex: null,
};

const funsampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FUNDATA":
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
      };
    default:
      return state;
  }
};

export default funsampleReducer;
