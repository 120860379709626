const initialState = {
  loading: true,
  amount: "",
  buyCurrency: null,
  sellCurrency: null,
  Note: null,
  currencyTwoIndex: null,
};

const ExchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EXACHANGE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "EXACHANGE_SUCCESS":
      return {
        ...state,
        loading: false,
        emailStatus: action.payload,
        error: "",
      };
    case "EXACHANGE_FAILURE":
      return {
        ...state,
        loading: false,
        emailStatus: "",
        error: action.payload,
      };
    case "FETCH_EXACHANGE_DETAILS":
      return {
        ...state,
        loading: false,
        amount: action.payload.amount,
        buyCurrency: action.payload.buyCurrency,
        sellCurrency: action.payload.sellCurrency,
        Note: action.payload.Note,
        currencyOneIndex: action.payload.currencyOneIndex,
        currencyTwoIndex: action.payload.currencyTwoIndex,
      };
    default:
      return state;
  }
};

export default ExchangeReducer;
