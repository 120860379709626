const initialState = {
  IsCountryLoaded: false,
  countryData: "",
};

const CountryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COUNTRYLIST_ACTION":
      return {
        ...state,
        countryData: action.payload,
        IsCountryLoaded: true,
      };

    default:
      return state;
  }
};

export default CountryListReducer;
