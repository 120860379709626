export const EmpTransactionId = (Id) => {
  return {
    type: "EMP_TRANSACTION_ID",
    payload: Id,
  };
};

export const ClearEmpTrans = () => {
  return {
    type: "CLEAR_EMPTRANS_REDUCER",
  };
};
