const initialState = {
  loading: false,
  error: "",
  tandc1: true,
  tandc2: true,
  resTermsandCoditions: "",
};

const termsAndConditionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TERMS_AND_CONDITIONS":
      return {
        ...state,
        loading: false,
        tandc1: action.payload.tandc1,
        tandc2: action.payload.tandc2,
      };
    case "INIT_SIGNUP_TANDC":
      return initialState;
    default:
      return state;
  }
};

export default termsAndConditionReducer;
