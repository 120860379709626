import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Alert } from "reactstrap";
import { AlertCircle } from "react-feather";

export class WarningAlert extends Component {
  
  render() {
    return (
      <React.Fragment>
          <div className="d-flex" style={{marginTop : "5px"}}>
        <Row>
          <Col>
            <Alert
              color="danger"
              isOpen={this.props.visible}
            //   toggle={this.props.errorStatus}
            >          
              <AlertCircle className="" size={15} />
              <span >{this.props.errorMsg}</span>
            </Alert>
          </Col>
        </Row>
          </div>
      </React.Fragment>
    );
  }
}
export default (WarningAlert);