import {
  isEmailVerified,
  SendVerificationEmail,
} from "../../../Services/verifyServices";

//sendemailVerify
const sendEmailverifyRequest = () => {
  return {
    type: "SEND_EMAIL_VERYFY_REQUEST",
  };
};

const sendEmailverifySuccess = (isEmailVerified) => {
  return {
    type: "SEND_EMAIL_VERYFY_SUCCESS",
    payload: isEmailVerified,
  };
};

const sendEmailverifyFailure = (error) => {
  return {
    type: "SEND_EMAIL_VERYFY_FAILURE",
    payload: error,
  };
};

//sendemailLink
const sendEmailLinkRequest = () => {
  return {
    type: "SEND_EMAIL_LINK_REQUEST",
  };
};

const sendEmailLinkSuccess = (isEmailLinkSend) => {
  return {
    type: "SEND_EMAIL_LINK_SUCCESS",
    payload: isEmailLinkSend,
  };
};

export const saveCountries = (countries) => {
  return {
    type: "SAVE_COUNTRIES",
    payload: countries,
  };
};

const sendEmailLinkFailure = (error) => {
  return {
    type: "SEND_EMAIL_LINK_FAILURE",
    payload: error,
  };
};

export const initemailVerification = () => {
  return {
    type: "INIT_SIGNUP_EMAIL_VERIFICATION",
  };
};

export const EmailVerified = (objectdata) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(sendEmailverifyRequest());
      isEmailVerified(objectdata.email, objectdata.preRegistrationId)
        .then((response) => {
          dispatch(sendEmailverifySuccess(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          const errorMsg = error.Message;
          dispatch(sendEmailverifyFailure(errorMsg));
          reject(error);
        });
    });
  };
};

export const EmailLinkSend = (emailObj) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      dispatch(sendEmailLinkRequest());
      SendVerificationEmail(emailObj.email, emailObj.preRegistrationId)
        .then((response) => {
          dispatch(sendEmailLinkSuccess(response.data));
          resolve(response);
        })
        .catch((error) => {
          const errorMsg = error.Message;
          dispatch(sendEmailLinkFailure(errorMsg));
          reject(error);
        });
    });
  };
};

// export const EmailVerified = (emailObj, countriesObj) => {
//   return (dispatch) => {
//     // dispatch(fetchisEmailVerified(emailObj))
//     dispatch(sendEmailverifyRequest(countriesObj));
//     isEmailVerified(emailObj)
//       .then((response) => {
//         dispatch(sendEmailverifySuccess(response.data));
//       })
//       .catch((error) => {
//         const errorMsg = error.Message;
//         dispatch(sendEmailverifyFailure(errorMsg));
//       });
//   };
// };
