//constant declaration ( User status on differnet phases of application)

export const userApplicationStatus = {
   notSubmited : 0, // User will redirect to "/complete-your-application" route
   saved : 1, // User will redirect to "/complete-your-application" route
   pending : 2, // User will redirect to "/complete-your-application" route
   approved : 3, // User will redirect to "/walletmain" route
   rejected : 4, // User will redirect to "/complete-your-application" route
   suspended : 11, // User will redirect to "/walletmain" route (Read only mode)
   appclosed : 15,  // User can not login the application
   membershipExpired : 16, // User will redirect to "/walletmain" route (Read only mode)
   active : 12, //Yet to define
   rejcetdUser : 14, //Yet to define
   cancelAuto : 17, //Yet to define
   cancelManuial : 18, //Yet to define
   suspendedAuto : 13, //Yet to define
}

export const empCardStatus = {
    default : "0", //default status of emp phy/vir card
    stolen : "41", //stolen card 
    terminated : "83", //termination of card
}
