// export const Add_VToVAddBeneficiary = (payload) => {
//   let data = {
//     beneficiaryName: payload.beneficiaryName,
//     isBusiness: payload.isBusiness,
//     userID: payload.userID,
//     email: payload.email,
//   };
//   return {
//     type: "ADD_VTOV_ADDBENEFICIARY_ACTION",
//     data,
//   };
// };
export const Add_VToVAddBeneficiary = (payload) => {
  return {
    type: "ADD_VTOV_ADDBENEFICIARY_ACTION",
    payload,
  };
};

export const Add_VToVAddBeneficiaryFormTable = (payload) => {
  return {
    type: "ADD_VTOV_ADDBENEFICIARY_FROM_TABLE_ACTION",
    payload,
  };
};

export const Add_VToBAddBeneficiaryFormTable = (payload) => {
  return {
    type: "ADD_VTOBANK_ADDBENEFICIARY_FROM_TABLE_ACTION",
    payload,
  };
};
export const Add_VToBankAddBeneficiary = (payload) => {
  return {
    type: "ADD_VTOBANK_ADDBENEFICIARY_ACTION",
    payload,
  };
};

export const Add_VToBankNoBeneficiary = (payload) => {
  return {
    type: "ADD_VTOBANK_NO_ADDBENEFICIARY_ACTION",
    payload,
  };
};
export const VToBAccountTransferAction = (data) => {
  return {
    type: "VTOB_ACCOUNT_TRANSFER_ACTION",
    payload: data,
  };
};

export const AutoVToBAccountTransferAction = (data) => {
  return {
    type: "AUTO_VTOB_ACCOUNT_TRANSFER_ACTION",
    payload: data,
  };
};

export const Add_VToBankAddBeneficiary_Response = (data) => {
  return {
    type: "ADD_VTOBANK_ADDBENEFICIARY_RESPONSE_ACTION",
    payload: data,
  };
};

export const ClearReduxAddBenificiary = () => {
  return {
    type: "CLEAR_REDUX_ADDBENIFICIARY_ACTION",
  };
};

export const ClearReduxAddBenificiaryExceptEntityType = () => {
  return {
    type: "CLEAR_REDUX_ADDBENIFICIARY_ACTION_EXCEPT_TYPE"
  }
}
//is user location on Update bank details page 
export const checkUserNavigationHistory = (data) => {
  return {
    type: "PUT_USER_NAVIGATION_STATUS",
    payload: data
  }
}